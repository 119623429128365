import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import { Checkbox } from "components/ui/checkbox";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectForm, setForm } from "store/formSlice";
import { RootState } from "store";

const formSchema = z.object({
  zelleMailbox: z.string().email().optional(),
  host: z.string(),
  port: z.string(),
  protocol: z.string(),
  username: z.string(),
  password: z.string(),
});

const StepTwoMerchantForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formState = useSelector((state: RootState) => selectForm(state));

  const [showForm, setShowForm] = useState(false);

  const [achAccess, setAchAccess] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      zelleMailbox: undefined,
      host: "",
      port: "",
      protocol: "",
      username: "",
      password: "",
    },
  });

  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
    // add the values to the form in redux
    dispatch(
      setForm({
        ...formState,
        ...values,
        zelleAccess: showForm,
        port: parseInt(values.port),
        achAccess: achAccess,
      })
    );
    navigate("/home/merchants/create?step=3");
  }

  return (
    <div className="m-auto mb-12 mt-12">
      <div className="my-12 flex items-center justify-start gap-4 pl-12">
        <Checkbox
          onCheckedChange={(isChecked: boolean) => {
            setAchAccess(isChecked);
          }}
        />{" "}
        <h2 className="text-xl">Ach Access</h2>
      </div>{" "}
      <div className="my-12 flex items-center justify-start gap-4 pl-12">
        <Checkbox
          onCheckedChange={(isChecked: boolean) => {
            setShowForm(isChecked);
          }}
        />{" "}
        <h2 className="text-xl">Zelle interconnection</h2>
      </div>{" "}
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <div
            className={`m-auto grid w-fit lg:grid-cols-2 gap-x-12 gap-y-4 ${showForm ? "block" : "hidden"}`}
          >
            <FormField
              control={form.control}
              name="zelleMailbox"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Zelle Email</FormLabel>
                  <FormControl>
                    <Input className="w-[280px] lg:w-[350px]" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="host"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Host</FormLabel>
                  <FormControl>
                    <Input className="w-[280px] lg:w-[350px]" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="port"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Port</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      className="w-[280px] lg:w-[350px]"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="protocol"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Protocol</FormLabel>
                  <FormControl>
                    <Input className="w-[280px] lg:w-[350px]" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="username"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Username</FormLabel>
                  <FormControl>
                    <Input className="w-[280px] lg:w-[350px]" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input
                      className="w-[280px] lg:w-[350px]"
                      type="password"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="flex justify-center">
            <Button
              variant={"secondary"}
              className="w-[280px] lg:w-[350px] border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white"
            >
              Next{" "}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default StepTwoMerchantForm;
