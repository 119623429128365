import { ProcessingCompany } from 'types/types'
import { REST } from './axiosConfig'

export async function getProcessingCompanies() {
  try {
    const response = await REST.get('/management/processingCompany/~')

    return response.data
  } catch (error) {
    console.error('Error fetching processing company:', error)
    return null
  }
}

export const getExchangeCompanies = async () => {
  try {
    const response = await REST.get('/management/exchange/~')
    return response.data
  } catch (error) {
    console.error('Error fetching exchange company:', error)
    return null
  }
}
