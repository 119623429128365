"use client";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Input } from "components/ui/input";
import {
  updatePaymentRequest,
  uploadPaymentRecipe,
  uploadTopUpRecipe,
} from "api/cryptoController";
import { useState } from "react";

const formSchema = z.object({
  receivingBank: z.string().min(1).max(50),
  receivingBankFee: z.string().min(1).max(50),
  status: z.string().min(1).max(50),
});

const MasterPaymentRequestForm = ({ transaction }: any) => {
  const navigate = useNavigate();

  const [file, setFile] = useState<File>();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      receivingBank: transaction?.receivingBank || "",
      receivingBankFee: transaction?.receivingBankFee?.toString() || "",
      status: "IN_PROGRESS",
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const payload = {
      ...values,
      receivingBankFee: Number(values.receivingBankFee),
      id: transaction.id,
    };
    try {
      const formData = new FormData();
      if (file) formData.append("file", file);

      const response = await updatePaymentRequest(payload);
      const uploadResponse = await uploadPaymentRecipe(transaction.id, {
        file: formData,
      });
      navigate("/home/crypto-balance");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="max-w-sm">
      <Form {...form}>
        <div className="flex justify-center">
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="mb-12 space-y-2"
          >
            <FormField
              control={form.control}
              name="receivingBank"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Receiving Bank</FormLabel>
                  <FormControl>
                    <Input
                      className="w-[280px] lg:w-[350px]"
                      placeholder="Receiving Bank"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="receivingBankFee"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Bank Fee</FormLabel>
                  <div className="flex items-center gap-3">
                    <FormControl>
                      <Input
                        type="number"
                        className="w-[280px] lg:w-[350px]"
                        placeholder="Bank Fee"
                        {...field}
                      />
                    </FormControl>
                    <span className="text-2xl font-bold text-gray-400">
                      USD
                    </span>
                  </div>
                </FormItem>
              )}
            />

            {/* select for status */}

            <FormField
              control={form.control}
              name="status"
              render={({ field }) => (
                <FormItem className="">
                  <FormLabel>Status</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="w-[280px] lg:w-[350px]">
                        <SelectValue placeholder="Status" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="IN_PROGRESS">In Progress</SelectItem>
                      <SelectItem value="PENDING">Pending</SelectItem>
                      <SelectItem value="COMPLETE">Complete</SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
            <p>Receipt</p>
            <Input
              onChange={(e) => {
                if (e.target.files) setFile(e.target.files[0]);
              }}
              required
              type="file"
              className="w-[280px] lg:w-[350px] text-right text-xl"
            />
            <div className="mb-4"></div>
            <Button
              variant={"secondary"}
              className="w-[280px] lg:w-[350px] border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white"
            >
              Submit{" "}
            </Button>
          </form>
        </div>
      </Form>
    </div>
  );
};

export default MasterPaymentRequestForm;
