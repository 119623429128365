import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import React from "react";

const SelectPaymentAccountType = ({ field }: any) => {
  return (
    <Select onValueChange={field.onChange}>
      <SelectTrigger className="w-full ">
        <SelectValue
          style={{ textDecorationColor: "black" }}
          placeholder={"Account Type"}
        />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={"CHECKING"}>Checking</SelectItem>
        <SelectItem value={"SAVINGS"}>Savings</SelectItem>
      </SelectContent>
    </Select>
  );
};

export default SelectPaymentAccountType;
