import React from 'react'
import { useParams } from 'react-router-dom'
import CreateTopUpForm from 'components/forms/merchantCreateTopUpForm/merchantCreateTopUpForm'
import Layout from 'pages/(root)/home/layout'
import { useSelector } from 'react-redux'
import MerchantCreateTopUpForm from 'components/forms/merchantCreateTopUpForm/merchantCreateTopUpForm'

const CreateTopUpMerchant = () => {
  const user = useSelector((state: any) => state.user.user)
  return (
   
      <div className='flex flex-grow dark:bg-black dark:text-white'>
        <div className='flex flex-grow p-4 pb-0'>
          <div className='flex-grow rounded-lg bg-[#212632]'>
            {/* TOPBAR  */}
            <div className='flex items-center justify-between border-b border-b-slate-500 p-4'>
              <div className='flex items-center gap-4'>
                <h2 className='py-[6px] text-xl'>New Top Up</h2>
                <span className='text-xl font-bold text-yellow-500'></span>
              </div>
            </div>
            {/* Content */}
            <MerchantCreateTopUpForm merchantId={user.merchantId} />
          </div>
        </div>
      </div>
  
  )
}

export default CreateTopUpMerchant
