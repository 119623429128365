import React, { useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../layout";
import { Button } from "components/ui/button";
import { DatePicker } from "components/datePicker/datePicker";
import ReportCardTransactions from "components/cards/reportCard/reportCardTransactions";

import { Separator } from "components/ui/separator";
import SelectMerchantId from "components/selectMerchantId/selectMerchantId";
import { useNavigate } from "react-router-dom";
import { Input } from "components/ui/input";
import { downloadFileFromBlob } from "lib/utils";
import { useHandleAxiosError } from "hooks/useAxiosHandlerError";
import { useToast } from "components/ui/use-toast";
import { REST } from "api/axiosConfig";

const Reports = () => {
  const user = useSelector((state: any) => state.user.user);
  const navigate = useNavigate();
  const { toast } = useToast();
  const { handleAxiosErrors } = useHandleAxiosError();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [transactions, setTransactions] = useState<any[]>([]);
  const [checkedTransactions, setCheckedTransactions] = useState<number[]>([]);
  const [merchantId, setMerchantId] = useState<any>(null);
  const [feesPercentage, setFeesPercentage] = useState<number>(0);
  const [mdr, setMdr] = useState<number>(0);

  const handleCheckboxChange = (transactionId: number) => {
    setCheckedTransactions((prevChecked) =>
      prevChecked.includes(transactionId)
        ? prevChecked.filter((id) => id !== transactionId)
        : [...prevChecked, transactionId]
    );
  };

  const handleSelectAll = () => {
    if (checkedTransactions.length === transactions.length) {
      setCheckedTransactions([]);
    } else {
      setCheckedTransactions(transactions.map((transaction) => transaction.id));
    }
  };

  const generateReport = async () => {
    const dataToSend = {
      taskIds: checkedTransactions,
      feesPercentage,
      mdr,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };

    try {
      const response = await REST.post("/reports/complete", dataToSend, {
        responseType: "arraybuffer",
      });
      const blob = new Blob([response?.data], {
        type: "application/octet-stream",
      });
      downloadFileFromBlob(blob, "zelle-report.pdf");
    } catch (error) {
      handleAxiosErrors({
        toastTitle: "Failed to generate report!",
        error: error,
      });
    }
  };

  const retrieveTransactions = async () => {
    if (startDate <= endDate && merchantId) {
      try {
        const postData = {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          merchantId: Number(merchantId),
        };
        setCheckedTransactions([]);
        const response = await REST.post("/reports/init", postData);
        setTransactions(response.data);
      } catch (error) {
        handleAxiosErrors({
          toastTitle: "Failed to retrieve transactions!",
          error: error,
        });
      }
    } else {
      toast({
        title:
          "Start date cannot be greater than end date, and merchant should be selected!",
      });
    }
  };

  const CheckAllButton = () => (
    <div className="flex flex-row gap-2">
      <div
        onClick={() => handleSelectAll()}
        className={`w-6 h-6 flex items-center justify-center border-2 rounded-full cursor-pointer ${
          checkedTransactions.length === transactions.length
            ? "bg-yellow-500 border-yellow-500"
            : "bg-white border-gray-300"
        }`}
      >
        {checkedTransactions.length === transactions.length && (
          <div className="w-3 h-3 rounded-full bg-yellow-500" />
        )}
      </div>

      <div className="mb-4 flex justify-between">
        <span className="text-white">
          {checkedTransactions?.length} / {transactions?.length} selected
        </span>
      </div>
    </div>
  );

  const reportGenerator = () => {
    return (
      <div className="flex flex-col md:flex-row items-center justify-between border-b border-b-slate-500 p-1 gap-2">
        <SelectMerchantId
          onValueChange={(value: number) => setMerchantId(value)}
          defaultValue={merchantId}
        />

        <DatePicker setDate={setStartDate} date={startDate} maxDate={endDate}/>
        <DatePicker setDate={setEndDate} date={endDate} maxDate={new Date()} minDate={startDate}/>

        <div className="flex flex-col md:flex-row gap-2 w-full">
          <Input
            onChange={(e) => setFeesPercentage(Number(e.target.value))}
            type="number"
            name="feesPercentage"
            placeholder="Fees Percentage"
          />
          <Input
            onChange={(e) => setMdr(Number(e.target.value))}
            type="number"
            name="mdr"
            placeholder="MDR"
          />
        </div>

        <div className="flex flex-col md:flex-row gap-2 w-full md:w-auto">
          <Button
            variant="secondary"
            className="border border-yellow-500 bg-black font-bold text-yellow-500 hover:text-white w-full md:w-auto"
            onClick={() => retrieveTransactions()}
          >
            Retrieve Transactions
          </Button>
          <Button
            variant="secondary"
            className="border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white w-full md:w-auto"
            onClick={() => generateReport()}
          >
            Generate Report
          </Button>
        </div>
      </div>
    );
  };

  const TransactionsContent = () => (
    <div className="mt-4 p-2 pb-8 md:p-4 md:pb-12">
      {!transactions.length ? (
        <h2 className="text-center text-2xl md:text-4xl text-white">
          {transactions.length === 0
            ? "No transactions found"
            : "Start by generating a report"}
        </h2>
      ) : (
        <>
          <CheckAllButton />

          <div className="flex flex-col gap-2 md:gap-4 p-3 md:p-5 md:overflow-y-auto md:max-h-[700px]">
            {transactions.map((transaction: any) => (
              <div
                key={transaction.id}
                className="mb-3 md:mb-5 flex flex-col md:flex-row items-center gap-2 md:gap-4"
              >
                <ReportCardTransactions
                  report={transaction}
                  isChecked={checkedTransactions?.includes(transaction?.id)}
                  onCheckedChange={handleCheckboxChange}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );

  return (
    <div className="flex flex-grow dark:bg-black dark:text-white">
      <div className="flex flex-grow p-4 pb-0">
        <div className="mt-4 flex-grow rounded-lg bg-[#212632] p-2">
          <h2 className="py-[6px] text-xl">
            Manage your
            <span className="font-bold text-yellow-500"> reports</span>
          </h2>
          {reportGenerator()}
          <TransactionsContent />
        </div>
      </div>
    </div>
  );
};

export default Reports;
