export type Entity = "transactions" | "merchants" | "agents";

export type Transaction = {
  id: number;
  merchantEmail: string;
  startData: string;
  endDate: string;
  player: string;
  //   status: TransactionStatus
  amount: string;
  memo: string;
  operation: string;
};
export type ProcessingCompany = {
  id: number;
  name: string;
  companyFee: number;
};
export type Merchant = {
  id: number; // Assuming $int64 maps to number in TypeScript
  name: string;
  registrationNumber: string;
  contactEmail: string;
  address: string;
  zelleAccess: boolean;
  zelleMailbox: string;
  host: string;
  port: number; // Assuming $int64 maps to number in TypeScript
  protocol: string;
  username: string;
  password: string;
  feeDescription: string;
  feePercentage: number; // Assuming $double maps to number in TypeScript
  processCompanyId: number;
  exchangeId: number;
  achAccess: boolean;
  cryptoAccess: boolean;
};

export type Agents = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  phoneNo: string;
  password: string;
  role: string;
  merchantId: number;
  status: TransactionStatus;
};

// NEW, IN_PROGRESS, DONE, CANCELED, PROCESSED
export type TransactionStatus =
  | "NEW"
  | "IN_PROGRESS"
  | "DONE"
  | "CANCELED"
  | "PROCESSED";

export type addressStateType =
  | "AL"
  | "AK"
  | "AZ"
  | "AR"
  | "CA"
  | "CO"
  | "CT"
  | "DE"
  | "DC"
  | "FL"
  | "GA"
  | "GU"
  | "HI"
  | "ID"
  | "IL"
  | "IN"
  | "IA"
  | "KS"
  | "KY"
  | "LA"
  | "ME"
  | "MD"
  | "MA"
  | "MI"
  | "MN"
  | "MS"
  | "MO"
  | "MT"
  | "NE"
  | "NV"
  | "NH"
  | "NJ"
  | "NM"
  | "NY"
  | "NC"
  | "ND"
  | "OH"
  | "OK"
  | "OR"
  | "PA"
  | "PR"
  | "RI"
  | "SC"
  | "SD"
  | "TN"
  | "TX"
  | "UT"
  | "VT"
  | "VI"
  | "VA"
  | "WA"
  | "WV"
  | "WI"
  | "WY";

export const addressStateTypeArray: addressStateType[] = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export type PaymentStatus =
  | "NEW"
  | "PENDING"
  | "PROCESSED"
  | "FAILED"
  | "CANCELED";

export type Payment = {
  id: number;
  merchantId: number;
  name: string;
  recipientEmail: string;
  paymentReference: string;
  amount: number;
  abartn: string;
  accountNumber: string;
  accountType: string;
  addressCity: string;
  addressFirstLine: string;
  addressState: addressStateType;
  addressPostCode: string;
  dob: string;
  status: PaymentStatus;
  createdAt: string;
  updatedAt: string;
};

export type CryptoStatus =
  | "NEW"
  | "PENDING"
  | "PROCESSED"
  | "FAILED"
  | "CANCELED"
  | "COMPLETED";
