import { useEffect, useState } from "react";
import { getAgents } from "api/agents";
import { IconLoader } from "@tabler/icons-react";
import TablePagination from "components/shared/tablePagination/tablePagination";
import UpdateEntityButton from "components/shared/buttons/updateEntityButton/updateEntityButton";
import { getAllMerchants, getMerchantById } from "api/merchant";
import PrimaryButton from "components/shared/buttons/primaryButton/primaryButton";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ResponsiveTableWithPagination from "components/shared/customTableComponent/ResponsiveTableWithPagination";

const Agents = () => {
  const [agentsData, setAgentsData] = useState<any>([]);
  const [merchantsData, setMerchantsData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.user);

  const columns: any = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Last Name",
      accessor: "lastName",
    },
    {
      Header: "First Name",
      accessor: "firstName",
    },
    {
      Header: "Phone Number",
      accessor: "phoneNo",
    },
    {
      Header: "Role",
      accessor: "role",
    },
    {
      Header: "Client ID",
      accessor: "merchantId",
      Cell: ({ value }: any) => findTheMerchant(value, merchantsData.content),
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }: any) => (
        <UpdateEntityButton item={row.original} entity={"agents"} />
      ),
    },
  ];

  const findTheMerchant = (merchantId: number, merchants: any) => {
    if (!merchantId || !merchants) return;
    const merchant = merchants.find(
      (merchant: any) => merchant.id === merchantId
    );
    return merchant?.name;
  };

  useEffect(() => {
    const fetchMerchants = async () => {
      try {
        let response;
        if (user.role === "MASTER_ADMIN") response = await getAllMerchants();
        else response = getMerchantById(user.merchantId);
        setMerchantsData(response);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchAgents = async () => {
      try {
        setIsLoading(true);
        const response = await getAgents(pageSize, currentPage);
        setAgentsData(response);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchMerchants();
    fetchAgents();
  }, [currentPage, pageSize]);

  const handlePageChange = async (newPage: number) => {
    setCurrentPage(newPage);
  };

  const AgentsTable = () => (
    <div>
      {isLoading ? (
        <IconLoader size={72} className="m-auto mt-14 animate-spin" />
      ) : (
        <ResponsiveTableWithPagination
          columns={columns}
          data={agentsData}
          handlePageChange={setCurrentPage}
        />
      )}
    </div>
  );

  return (
    <div className="flex flex-grow dark:bg-black dark:text-white">
      <div className="flex flex-grow p-4 pb-0">
        <div className="flex flex-col flex-grow rounded-lg bg-[#212632]">
          {/* TOPBAR  */}
          <div className="flex flex-col lg:flex-row items-center justify-between border-b border-b-slate-500 p-4">
            <div className="flex items-center gap-4">
              <h2 className="text-xl">
                View, edit, or delete your
                <span className="font-bold text-yellow-500"> agents</span>
              </h2>
            </div>

            <PrimaryButton onClick={() => navigate("/home/agents/create")}>
              Create
            </PrimaryButton>
          </div>

          {/* Content */}
          <AgentsTable />
        </div>
      </div>
    </div>
  );
};

export default Agents;
