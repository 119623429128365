import React from "react";
import Layout from "../../layout";
import { useLocation } from "react-router-dom";
import CreateAgentForm from "components/forms/createEntityForm/createAgentForm";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CreateAgent = () => {
  const query = useQuery();
  const step = query.get("step");
  return (
   
      <div className="flex flex-grow dark:bg-black dark:text-white">
        <div className="flex flex-grow p-4 pb-0">
          <div className=" flex-grow rounded-lg bg-[#212632]">
            {/* TOPBAR  */}
            <div className="flex items-center justify-between border-b border-b-slate-500 p-4">
              <div className="flex items-center gap-4">
                <h2 className="py-[6px] text-xl">Create a new Agent</h2>
              </div>
            </div>

            {/* Content */}
            <CreateAgentForm />
          </div>
        </div>
      </div>
  
  );
};

export default CreateAgent;
