import ProcessTopUpForm from "components/forms/processTopUpForm/processTopUpForm";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ProcessTopUpConversion from "components/forms/processTopUpForm/processTopUpConversionForm";
import { useState } from "react";

const ProcessTopUp = () => {
  const location = useLocation();
  const { transaction } = location.state;
  const user = useSelector((state: any) => state.user.user);
  const [allowToFinishTopUp, setAllowToFinishTopUp] = useState<boolean>(false);

  return (
    <div className="flex flex-col min-h-screen dark:bg-black dark:text-white">
      <div className="flex flex-col flex-grow p-4 pb-0">
        <div className="flex-grow rounded-lg bg-[#212632]">
          {/* TOPBAR */}
          <div className="flex items-center justify-between border-b border-b-slate-500 p-4">
            <div className="flex items-center gap-4">
              <h2 className="text-xl font-semibold">Process Top Up</h2>
              <span className="text-xl font-bold text-yellow-500"></span>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex flex-col md:flex-row md:justify-between m-3 space-y-4 md:space-y-0 md:space-x-4">
            <div className="flex-grow">
              <ProcessTopUpForm
                transaction={transaction}
                merchantId={transaction.merchantId}
                allowToFinishTopUp={allowToFinishTopUp}
              />
            </div>
            <div className="flex-grow">
              <ProcessTopUpConversion
                transactionId={transaction?.id}
                setAllowToFinishTopUp={setAllowToFinishTopUp}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessTopUp;
