import { addressStateType } from "types/types";
import { REST, RESTLinks, RESTMultipart } from "./axiosConfig";
import { downloadFileFromBlob } from "lib/utils";

// [ AL, AK, AZ, AR, CA, CZ, CO, CT, DE, DC, FL, GA, GU, HI, ID, IL, IN, IA, KS, KY, LA, ME, MD, MA, MI, MN, MS, MO, MT, NE, NV, NH, NJ, NM, NY, NC, ND, OH, OK, OR, PA, PR, RI, SC, SD, TN, TX, UT, VT, VI, VA, WA, WV, WI, WY ]

export const getPayments = async (status: string | null, pageNumber?: number) => {
  try {
    if (pageNumber) {
      const response = await REST.get(
        `/payments/${status}/~?page=${pageNumber}&size=10`
      );
      return response.data;
    } else {
      const response = await REST.get(`/payments/${status}/~?size=10`);
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const createPayment = async (payload: {
  merchantId: number;
  name: string;
  recipientEmail: string;
  paymentReference: string;
  amount: number;
  abartn: string;
  accountNumber: string;
  accountType: string;
  addressCity: string;
  addressFirstLine: string;
  addressState: string;
  addressPostCode: string;
  dob: string;
}) => {
  try {
    const response = await REST.post("/payments/create", payload);
    return response
  } catch (error) {
    throw error
  }
};

export const validatePayment = async (paymentId: number) => {
  try {
    const response = await REST.put(`/payments/${paymentId}/validate`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updatePaymentStatus = async (
  paymentId: number,
  newStatus: string
) => {
  try {
    const response = await REST.put(
      `/payments/${paymentId}/update/${newStatus}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const postPaymentsUploadReceipt = async (
  paymentId: number,
  file: any
) => {
  try {
    const response = await RESTMultipart.post(
      `payments/uploadReceipt/${paymentId}`,
      file
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getPaymentsDownloadReceipt = async (paymentId: number) => {
  try {
    const response = await REST.get(`/payments/download/${paymentId}`, {
      responseType: "arraybuffer",
    });
    const blob = new Blob([response.data], {
      type: "application/octet-stream",
    });
    downloadFileFromBlob(blob, "receipt.pdf");
  } catch (error) {
    throw error
  }
};

export const postPaymentsCreateBulk = async (file: any) => {
  try {
    console.log(file)
    const response = await RESTMultipart.post(`payments/create/bulk`, file);
    console.log(response)
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// GET /withdrawal/{status}
export const getWithdrawals = async (status: string | null, pageNumber = 0) => {
  try {
    const response = await REST.get(
      `/withdrawal/${status}?page=${pageNumber}&size=10`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// {
//   "beneficiaryName": "string",
//   "beneficiaryAddress": "string",
//   "amount": 0,
//   "currency": "BTC",
//   "bankName": "string",
//   "bankAddress": "string",
//   "accountNumberOrIban": "string",
//   "swiftOrBic": "string",
//   "bankReferenceOrMemo": "string",
//   "internalReferenceOrMemo": "string",
//   "others": "string",
//   "status": "NEW"
// }
// POST /withdrawal/create
export const createWithdrawal = async (payload: {
  beneficiaryName: string;
  beneficiaryAddress: string;
  amount: number;
  currency: string;
  bankName: string;
  bankAddress: string;
  accountNumberOrIban: string;
  swiftOrBic: string;
  bankReferenceOrMemo: string;
  internalReferenceOrMemo: string;
  others: string;
  status: string;
}) => {
  try {
    const response = await REST.post("/withdrawal/create", payload);
    return response
  } catch (error) {
    throw error
  }
};

// PUT /withdrawal/{withdrawalId}/update/{status}
export const putWithdrawalStatus = async (
  withdrawalId: number,
  status: string
) => {
  try {
    const response = await REST.put(
      `/withdrawal/${withdrawalId}/update/${status}`
    );
  } catch (error) {
    console.error(error);
  }
};
