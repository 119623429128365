import vaultLogo from 'images/vaultLogo.png'
import LandingPageButtons from 'components/landingPageButtons/landingPageButtons'

const LandingPage = () => {
  
  return (
    <div className='flex min-h-screen items-center justify-between bg-[#212632]'>
      <div className='container flex flex-col items-center'>
        <img src={vaultLogo} alt='Merchant Logo' />

        <div className='flex justify-between gap-12'>
          <LandingPageButtons />
        </div>
      </div>
    </div>
  )
}

export default LandingPage
