import { initTopUp } from "api/cryptoController";
import { Button } from "components/ui/button";
import React from "react";

const Api = () => {
  // const doInitTopUp = async () => {
  //   try {
  //     const response = await initTopUp({ merchantId: 1, amount: 0.01 });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  return (
    <div className="flex min-h-screen items-center justify-center">
      {/* <Button
        className="bg-yellow-500 text-black hover:bg-white"
        onClick={doInitTopUp}
      >
        InitTopup
      </Button> */}

      Testing page...
    </div>
  );
};

export default Api;
