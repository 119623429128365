import React from "react";
import { Separator } from "components/ui/separator";
import { beautifyNumber, formatDateToDateAndHour } from "lib/utils";
import { Checkbox } from "components/ui/checkbox";

interface ReportProps {
  report: any;
  isChecked: boolean;
  onCheckedChange: (id: number) => void;
}

const ReportCardTransactions: React.FC<ReportProps> = ({
  report,
  isChecked,
  onCheckedChange,
}) => {
  return (
    <div className="bg-lightgray rounded-lg shadow-lg p-4 md:p-6 border border-gray-200 w-full flex flex-col md:flex-row gap-4 md:gap-10">
      <div className="flex mb-4 md:mb-0 md:mr-6">
        <Checkbox
          checked={isChecked}
          onCheckedChange={() => onCheckedChange(report?.id)}
          className="mr-4"
        />
      </div>

      <ul className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 w-full">
        <li className="flex gap-2 md:gap-6">
          <span className="font-semibold text-yellow-500 text-sm md:text-base">Merchant Email:</span>
          <span className="text-gray-300 text-sm md:text-base truncate">{report?.merchantEmail}</span>
        </li>
        <li className="flex gap-2 md:gap-6">
          <span className="font-semibold text-yellow-500 text-sm md:text-base">Start Date:</span>
          <span className="text-gray-300 text-sm md:text-base truncate">{formatDateToDateAndHour(report?.startDate)}</span>
        </li>

        <li className="flex gap-2 md:gap-6">
          <span className="font-semibold text-yellow-500 text-sm md:text-base">Client:</span>
          <span className="text-gray-300 text-sm md:text-base truncate">{report?.client}</span>
        </li>
        <li className="flex gap-2 md:gap-6">
          <span className="font-semibold text-yellow-500 text-sm md:text-base">Status:</span>
          <span className="text-gray-300 text-sm md:text-base truncate">{report?.status}</span>
        </li>
        <li className="flex gap-2 md:gap-6">
          <span className="font-semibold text-yellow-500 text-sm md:text-base">Amount:</span>
          <span className="text-gray-300 text-sm md:text-base truncate">{beautifyNumber(report?.amount)}</span>
        </li>
        <li className="flex gap-2 md:gap-6">
          <span className="font-semibold text-yellow-500 text-sm md:text-base">Memo:</span>
          <span className="text-gray-300 text-sm md:text-base truncate">{report?.memo}</span>
        </li>
        <li className="flex gap-2 md:gap-6">
          <span className="font-semibold text-yellow-500 text-sm md:text-base">Operation:</span>
          <span className="text-gray-300 text-sm md:text-base truncate">{report?.operation}</span>
        </li>
      </ul>
    </div>
  );
};

export default ReportCardTransactions;
