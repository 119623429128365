import React from 'react'
import Layout from '../../layout'
import { useLocation } from 'react-router-dom'
import StepOneMerchantForm from 'components/forms/merchantForms/stepOneMerchantForm'
import StepTwoMerchantForm from 'components/forms/merchantForms/stepTwoMerchantForm'
import StepThreeMerchantForm from 'components/forms/merchantForms/stepThreeMerchantForm'
import StepFourMerchantForm from 'components/forms/merchantForms/stepFourMerchantForm'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const CreateNewMerchant = () => {
  const query = useQuery()
  const step = query.get('step')
  return (
  
      <div className='flex flex-grow dark:bg-black dark:text-white'>
        <div className='flex flex-grow p-4 pb-0'>
          <div className='flex-grow rounded-lg bg-[#212632]'>
            {/* TOPBAR  */}
            <div className='flex items-center justify-between border-b border-b-slate-500 p-4'>
              <div className='flex items-center gap-4'>
                <h2 className='py-[6px] text-xl'>Create a new merchant</h2>
                <span className='text-xl font-bold text-yellow-500'>
                  Step {step}
                </span>
              </div>
            </div>

            {/* Content */}
            {returnMerchantForm(step as string)}
          </div>
        </div>
      </div>
   
  )
}
const returnMerchantForm = (step: string) => {
  switch (step) {
    case '1':
      return <StepOneMerchantForm />
    case '2':
      return <StepTwoMerchantForm />
    case '3':
      return <StepThreeMerchantForm />
    case '4':
      return <StepFourMerchantForm />
    default:
      return <StepOneMerchantForm />
  }
}

export default CreateNewMerchant
