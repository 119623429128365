import {
  IconCoinBitcoin,
  IconDashboard,
  IconDoorExit,
  IconPigMoney,
  IconTransfer,
  IconTransferOut,
} from "@tabler/icons-react";
import PrimaryButton from "components/shared/buttons/primaryButton/primaryButton";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Roles } from "roles/roles";

const LandingPageButtons = () => {
  const user = useSelector((state: any) => state.user.user);
  const navigate = useNavigate();

  const buttons = [
    {
      to: "/home/dashboard",
      icon: <IconDashboard size={36} />,
      label: "Dashboard",
      allowed: [Roles?.MASTER_ADMIN, Roles?.MERCHANT_ADMIN].includes(
        user?.role
      ),
    },
    {
      to: "/home/transactions",
      icon: <IconPigMoney size={36} />,
      label: "Zelle",
      allowed: [
        Roles?.MASTER_ADMIN,
        Roles?.MERCHANT_ADMIN,
        Roles?.MERCHANT_USER,
      ].includes(user?.role),
    },
    {
      to:
        user?.role === Roles?.MASTER_ADMIN
          ? "/home/crypto-balance"
          : "/home/crypto-balance-merchant",
      icon: <IconCoinBitcoin size={36} />,
      label: "Crypto",
      allowed: [Roles?.MASTER_ADMIN, Roles?.MERCHANT_ADMIN].includes(
        user?.role
      ),
    },
    {
      to:
        user?.role === Roles?.MASTER_ADMIN
          ? "/home/payments"
          : "/home/payments-merchant/",
      icon: <IconTransfer size={36} />,
      label: "Ach",
      allowed: [Roles?.MASTER_ADMIN, Roles?.MERCHANT_ADMIN].includes(
        user?.role
      ),
    },
    {
      to: "/home/withdrawals",
      icon: <IconTransferOut size={36} />,
      label: "Withdrawals",
      allowed: [Roles?.MASTER_ADMIN, Roles?.MERCHANT_ADMIN].includes(
        user?.role
      ),
    },
  ];

  const ButtonList = () => (
    <div className="flex flex-wrap gap-4 md:gap-6">
      {buttons.map(({ to, icon, label, allowed }, index) => (
        <div key={index} className="flex-1 min-w-[150px]">
          {allowed && (
            <Link to={to}>
              <PrimaryButton className="h-fit w-full gap-2 duration-300 hover:text-black">
                <div className="text-center w-full">
                  <div className="flex justify-center items-center">{icon}</div>
                  <p className="text-sm md:text-base">{label}</p>
                </div>
              </PrimaryButton>
            </Link>
          )}
        </div>
      ))}
    </div>
  );

  return (
    <div className="flex flex-col items-center gap-8 px-4 md:px-0">
      {!user?.role ? (
        <PrimaryButton
          className="gap-4 h-14 w-full md:w-auto"
          onClick={() => navigate("/login")}
        >
          <IconDoorExit size={24} />
          Login
        </PrimaryButton>
      ) : (
        <ButtonList />
      )}
    </div>
  );
};

export default LandingPageButtons;
