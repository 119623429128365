import React from 'react';
import { Roles } from 'roles/roles';
import { RoutesInterface } from 'interfaces/routeInterface';

// Direct imports instead of lazy loading
import LandingPage from 'pages/(root)/(landingPage)/page';
import Api from 'pages/(root)/api/page';
import CryptoBalanceMerchant from 'pages/(root)/home/(cryptoBalance)/cryptoBalanceMerchant/page';
import CreateAgent from 'pages/(root)/home/agents/create/page';
import Agents from 'pages/(root)/home/agents/page';
import CryptoReports from 'pages/(root)/home/crypto/reports/page';
import CreatePaymentRequestMerchant from 'pages/(root)/home/cryptoBalance/(paymentRequest)/paymentRequestMerchant/create/page';
import ViewPaymentRequestMerchant from 'pages/(root)/home/cryptoBalance/(paymentRequest)/paymentRequestMerchant/view/page';
import CryptoBalance from 'pages/(root)/home/cryptoBalance/page';
import CreatePaymentRequest from 'pages/(root)/home/cryptoBalance/paymentRequest/create/page';
import ViewPaymentRequest from 'pages/(root)/home/cryptoBalance/paymentRequest/view/page';
import CreateTopUpMerchant from 'pages/(root)/home/cryptoBalance/top-up-merchant/page';
import TopUpMerchantView from 'pages/(root)/home/cryptoBalance/top-up-merchant/view/page';
import CreateTopUp from 'pages/(root)/home/cryptoBalance/topUp/[slug]/createTopUp';
import TopUp from 'pages/(root)/home/cryptoBalance/topUp/page';
import ProcessTopUp from 'pages/(root)/home/cryptoBalance/topUp/process/page';
import TopUpView from 'pages/(root)/home/cryptoBalance/topUp/view/page';
import DashBoard from 'pages/(root)/home/dashboard/page';
import CreateExchangeCompany from 'pages/(root)/home/exchangeCompanies/create/page';
import ExchangeCompanies from 'pages/(root)/home/exchangeCompanies/page';
import CreateNewMerchant from 'pages/(root)/home/merchants/create/page';
import EditMerchant from 'pages/(root)/home/merchants/edit/[slug]/page';
import Merchants from 'pages/(root)/home/merchants/page';
import Notifications from 'pages/(root)/home/notifications/page';
import CreatePayment from 'pages/(root)/home/payments/create/page';
import Payments from 'pages/(root)/home/payments/page';
import PaymentsMerchant from 'pages/(root)/home/payments/payments-merchant/page';
import ViewPaymentMerchant from 'pages/(root)/home/payments/payments-merchant/view/page';
import CreateProcessingCompany from 'pages/(root)/home/processingCompanies/create/page';
import ProcessingCompanies from 'pages/(root)/home/processingCompanies/page';
import Reports from 'pages/(root)/home/reports/page';
import Transactions from 'pages/(root)/home/transactions/page';
import CreateWithdrawal from 'pages/(root)/home/withdrawals/create/page';
import EditWithdrawal from 'pages/(root)/home/withdrawals/edit/page';
import Withdrawals from 'pages/(root)/home/withdrawals/page';
import AchReports from 'pages/(root)/home/payments/reports/page';
import WithdrawalReports from 'pages/(root)/home/withdrawals/reports/page';
import Balance from 'pages/(root)/home/balance/page';
import CreateBalanceTransaction from 'pages/(root)/home/balance/create/page';

export const routes: RoutesInterface = [
    { path: '/merchants', element: Merchants, allowedRoles: [Roles.MASTER_ADMIN] },
    { path: '/merchants/create', element: CreateNewMerchant, allowedRoles: [Roles.MASTER_ADMIN] },
    { path: '/merchants/edit', element: EditMerchant, allowedRoles: [Roles.MASTER_ADMIN] },
    { path: '/agents', element: Agents, allowedRoles: [Roles.MASTER_ADMIN, Roles.MERCHANT_ADMIN] },
    { path: '/agents/create', element: CreateAgent, allowedRoles: [Roles.MASTER_ADMIN, Roles.MERCHANT_ADMIN] },
    { path: '/transactions', element: Transactions, allowedRoles: [Roles.MASTER_ADMIN, Roles.MERCHANT_ADMIN, Roles.MERCHANT_USER] },
    { path: '/reports', element: Reports, allowedRoles: [Roles.MASTER_ADMIN] },
    { path: '/dashboard', element: DashBoard, allowedRoles: [Roles.MASTER_ADMIN, Roles.MERCHANT_ADMIN] },
    { path: '/balance', element: Balance, allowedRoles: [Roles.MASTER_ADMIN, Roles.MERCHANT_ADMIN] },
    { path: '/balance/create-transfer', element: CreateBalanceTransaction, allowedRoles: [Roles.MASTER_ADMIN, Roles.MERCHANT_ADMIN] },
    { path: '/processing-companies', element: ProcessingCompanies, allowedRoles: [Roles.MASTER_ADMIN] },
    { path: '/processing-companies/create', element: CreateProcessingCompany, allowedRoles: [Roles.MASTER_ADMIN] },
    { path: '/exchange-companies', element: ExchangeCompanies, allowedRoles: [Roles.MASTER_ADMIN] },
    { path: '/exchange-companies/create', element: CreateExchangeCompany, allowedRoles: [Roles.MASTER_ADMIN] },
    { path: '/crypto-balance', element: CryptoBalance, allowedRoles: [Roles.MASTER_ADMIN] },
    { path: '/crypto-balance-merchant', element: CryptoBalanceMerchant, allowedRoles: [Roles.MERCHANT_ADMIN] },
    { path: '/crypto-balance/top-up', element: TopUp, allowedRoles: [Roles.MASTER_ADMIN, Roles.MERCHANT_ADMIN] },
    { path: '/crypto-balance/top-up/create/:slug', element: CreateTopUp, allowedRoles: [Roles.MASTER_ADMIN, Roles.MERCHANT_ADMIN] },
    { path: '/crypto-balance/top-up/process', element: ProcessTopUp, allowedRoles: [Roles.MASTER_ADMIN, Roles.MERCHANT_ADMIN] },
    { path: '/crypto-balance/top-up-merchant/create', element: CreateTopUpMerchant, allowedRoles: [Roles.MASTER_ADMIN, Roles.MERCHANT_ADMIN] },
    { path: '/crypto-balance/top-up-merchant/view', element: TopUpMerchantView, allowedRoles: [Roles.MASTER_ADMIN, Roles.MERCHANT_ADMIN] },
    { path: '/crypto-balance/top-up/view', element: TopUpView, allowedRoles: [Roles.MASTER_ADMIN, Roles.MERCHANT_ADMIN] },
    // { path: '/crypto-balance/payment-request/create/:slug', element: CreatePaymentRequest, allowedRoles: [Roles.MASTER_ADMIN, Roles.MERCHANT_ADMIN] },
    { path: '/crypto-balance/payment-request-merchant/create', element: CreatePaymentRequestMerchant, allowedRoles: [Roles.MASTER_ADMIN, Roles.MERCHANT_ADMIN] },
    { path: '/crypto-balance/payment-request/view', element: ViewPaymentRequest, allowedRoles: [Roles.MASTER_ADMIN, Roles.MERCHANT_ADMIN] },
    { path: '/crypto-balance/payment-request-merchant/view', element: ViewPaymentRequestMerchant, allowedRoles: [Roles.MASTER_ADMIN, Roles.MERCHANT_ADMIN] },
    { path: '/crypto/reports', element: CryptoReports, allowedRoles: [Roles.MASTER_ADMIN] },
    { path: '/payments-merchant', element: PaymentsMerchant, allowedRoles: [Roles.MERCHANT_ADMIN] },
    { path: '/payments', element: Payments, allowedRoles: [Roles.MASTER_ADMIN] },
    { path: '/payments-reports', element: AchReports, allowedRoles: [Roles.MASTER_ADMIN] },
    { path: '/payments-merchant/view', element: ViewPaymentMerchant, allowedRoles: [Roles.MASTER_ADMIN] },
    { path: '/payments/create', element: CreatePayment, allowedRoles: [Roles.MERCHANT_ADMIN] },
    { path: '/withdrawals', element: Withdrawals, allowedRoles: [Roles.MASTER_ADMIN, Roles.MERCHANT_ADMIN] },
    { path: '/withdrawals/create', element: CreateWithdrawal, allowedRoles: [Roles.MERCHANT_ADMIN] },
    { path: '/withdrawals/edit', element: EditWithdrawal, allowedRoles: [Roles.MASTER_ADMIN] },
    { path: '/withdrawals-reports', element: WithdrawalReports, allowedRoles: [Roles.MASTER_ADMIN] },
    { path: '/notifications', element: Notifications, allowedRoles: [Roles.MASTER_ADMIN, Roles.MERCHANT_ADMIN, Roles.MERCHANT_USER] },
    { path: '/api', element: Api, allowedRoles: [Roles.MASTER_ADMIN, Roles.MERCHANT_ADMIN] }
];
