"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import {
  completeTopUp,
  createTopUp,
  getConvert,
  getDownloadTopUpReceipt,
} from "api/cryptoController";
import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import { beautifyNumber } from "lib/utils";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import {
  getTopUpById,
  updateCryptoTransactionStatus,
} from "api/cryptoController";
import { getExchangeCompanies } from "api/management";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { useToast } from "components/ui/use-toast";
import { formatNumberAsDigit } from "lib/utils";
import { NumericFormat } from "react-number-format";
import { formatStringDigitToNumber } from "lib/utils";

const formSchema = z.object({
  exchangeRate: z.string().min(1).max(50),
  fiatAmount: z.string().min(1).max(50),
  merchantAmountReceive: z.string().min(1).max(50),
  providerFee: z.string().min(1).max(50),
});

const ProcessTopUpForm = ({
  merchantId,
  transaction,
  allowToFinishTopUp,
}: {
  merchantId: number;
  transaction: any;
  allowToFinishTopUp: boolean;
}) => {
  const [topUpData, setTopUpData] = useState<any>();
  const navigate = useNavigate();
  const { toast } = useToast();
  const user = useSelector((state: any) => state.user.user);
  const [exchangeProviders, setExchangeProviders] = useState<any[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      exchangeRate: "",
      fiatAmount: "",
      merchantAmountReceive: "",
      providerFee: "",
    },
  });

  const changeStatus = async (status: any) => {
    try {
      await updateCryptoTransactionStatus(+transaction?.id, status).then(
        (res) => {
          status === "COMPLETE"
            ? navigate("/home/crypto-balance")
            : setRefresh((prev) => !prev);
          toast({
            title: `Top up updated!`,
          });
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getTopUp = async () => {
    try {
      await getTopUpById(transaction?.id).then((res) => {
        setTopUpData(res?.data);
        form.reset({
          exchangeRate: res?.data?.exchangeRate?.toString() || "",
          fiatAmount: res?.data?.fiatAmount?.toString() || "",
          merchantAmountReceive:
            res?.data?.merchantAmountReceive.toString() || "",
          providerFee: res?.data?.providerFees?.toString() || "",
        });
      });
    } catch (Err) {
      console.log(Err);
    }
  };

  const getExchanceProviders = async () => {
    try {
      await getExchangeCompanies().then((res) => {
        setExchangeProviders(res);
      });
    } catch (Err) {
      console.log(Err);
    }
  };

  useEffect(() => {
    getTopUp();
    getExchanceProviders();
  }, [refresh]);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const topUpDatas = {
      exchangeRate: +formatStringDigitToNumber(values?.exchangeRate),
      fiatAmount: +formatStringDigitToNumber(values?.fiatAmount),
      merchantAmountReceive: +formatStringDigitToNumber(
        values?.merchantAmountReceive
      ),
      providerFee: +formatStringDigitToNumber(values?.providerFee),
      cryptoTransactionId: +transaction?.id,
    };

    try {
      await completeTopUp(topUpDatas).then((res) => {
        if (res?.status === 200) {
          if (topUpData?.status !== "NEW") {
            changeStatus(topUpData?.status);
            toast({
              title: `Top up updated!`,
            });
          } else {
            changeStatus("IN_PROGRESS");
          }
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorDetails = error.response?.data?.details;
        toast({
          title: `ERROR: Failed to complete Top up!`,
          description: `REASON: ${errorDetails || error.message}`,
        });
      } else {
        toast({
          title: "An unexpected error occurred. Please try again later.",
        });
      }
    }
  }

  const handleDownload = async () => {
    try {
      const response = await getDownloadTopUpReceipt(transaction?.id);
    } catch (error) {
      toast({
        title: `ERROR: Failed to download document!`,
      });
    }
  };

  return (
    <div className="m-auto mt-12 max-w-sm">
      <Form {...form}>
        <div className="flex justify-center">
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
            <div className="flex flex-col justify-between gap-6">
              <FormItem>
                <FormLabel className="flex justify-between">
                  Amount <strong>{topUpData?.currency}</strong>
                </FormLabel>
                <div className="flex flex-col lg:flex-row items-center gap-4">
                  <Input
                    className="w-[280px] lg:w-[350px] text-right text-xl"
                    value={formatNumberAsDigit(topUpData?.amount)}
                    readOnly
                  />
                </div>
              </FormItem>

              {topUpData?.transactionHash ? (
                <FormItem>
                  <FormLabel>Transaction Hash</FormLabel>
                  <div className="flex flex-col lg:flex-row items-center gap-4">
                    <Input
                      className="w-[280px] lg:w-[350px] text-right text-xl"
                      value={topUpData?.transactionHash}
                      readOnly
                    />
                  </div>
                </FormItem>
              ) : (
                <FormItem>
                  <FormLabel>Top Up Receipt</FormLabel>
                  <div className="flex items-center gap-4">
                    <Button
                      type="button"
                      variant="outline"
                      onClick={handleDownload}
                      className="w-[280px] lg:w-[350px] text-right text-xl"
                    >
                      Download
                    </Button>
                  </div>
                </FormItem>
              )}

              <FormField
                control={form.control}
                name="fiatAmount"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="flex items-center gap-4">
                      Amount in Fiat
                    </FormLabel>
                    <FormControl>
                      <NumericFormat
                        {...field}
                        customInput={Input}
                        thousandSeparator
                        decimalScale={3}
                        allowNegative={false}
                        className="w-[280px] lg:w-[350px] text-right text-xl"
                        placeholder="Amount in Fiat"
                        onValueChange={(values) => {
                          field.onChange(+values.value);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="exchangeRate"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="flex items-center gap-4">
                      Exchange Rate
                    </FormLabel>
                    <FormControl>
                      <NumericFormat
                        {...field}
                        customInput={Input}
                        thousandSeparator
                        decimalScale={3}
                        allowNegative={false}
                        className="w-[280px] lg:w-[350px] text-right text-xl"
                        placeholder="Exchange Rate"
                        onValueChange={(values) => {
                          field.onChange(+values.value);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="merchantAmountReceive"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="flex items-center gap-4">
                      Merchant Amount Receive
                    </FormLabel>
                    <FormControl>
                      <NumericFormat
                        {...field}
                        customInput={Input}
                        thousandSeparator
                        decimalScale={3}
                        allowNegative={false}
                        className="w-[280px] lg:w-[350px] text-right text-xl"
                        placeholder="Merchant Amount Receive"
                        onValueChange={(values) => {
                          field.onChange(+values.value);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="providerFee"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="flex items-center gap-4">
                      Provider Fee (%)
                    </FormLabel>
                    <FormControl>
                      <NumericFormat
                        {...field}
                        customInput={Input}
                        thousandSeparator
                        decimalScale={3}
                        allowNegative={false}
                        className="w-[280px] lg:w-[350px] text-right text-xl"
                        placeholder="Provider Fee"
                        onValueChange={(values) => {
                          field.onChange(+values.value);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {topUpData?.status !== "COMPLETE" && (
              <div className={`flex pb-2`}>
                <Button
                  variant={"secondary"}
                  className="w-[280px] lg:w-[350px] border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white"
                >
                  {topUpData?.status === "NEW"
                    ? "Complete Top Up"
                    : "Update top up"}
                </Button>
              </div>
            )}

            {topUpData?.status !== "NEW" && (
              <>
                <div className="flex flex-row items-center justify-between">
                  OR
                </div>
                <FormItem>
                  <FormLabel>Change status</FormLabel>
                  <Select
                    onValueChange={(e) => changeStatus(e)}
                    value={topUpData?.status}
                    disabled={topUpData?.status === "COMPLETE"}
                  >
                    <SelectTrigger className="w-full bg-slate-500 text-white">
                      <SelectValue
                        style={{ textDecorationColor: "white" }}
                        placeholder={"Change status"}
                      />
                    </SelectTrigger>

                    <SelectContent>
                      <SelectItem value="IN_PROGRESS">In progress</SelectItem>
                      <SelectItem value="PENDING">Pending</SelectItem>
                      <SelectItem value="COMPLETE">Complete</SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              </>
            )}
          </form>
        </div>
      </Form>
    </div>
  );
};

export default ProcessTopUpForm;
