import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import React from "react";
import { addressStateTypeArray } from "types/types";
const SelectRecipientAddressState = ({ field }: any) => {
  return (
    <Select onValueChange={field.onChange}>
      <SelectTrigger className="w-full ">
        <SelectValue
          style={{ textDecorationColor: "black" }}
          placeholder={"State"}
        />
      </SelectTrigger>
      <SelectContent>
        {addressStateTypeArray.map((state) => (
          <SelectItem key={state} value={state}>
            {state}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default SelectRecipientAddressState;
