import { selectUser } from "store/userSlice";
import { REST } from "./axiosConfig";
import store from "store";

const API_URL = process.env.REACT_APP_API_URL;
export type TaskStatus =
  | "NEW"
  | "IN_PROGRESS"
  | "DONE"
  | "CANCELED"
  | "CONFIRMED"
  | "PROCESSED"
  | "PENDING";

export type Transaction = {
  id: number;
  name: string;
  description: string;
  merchantId: number;
  status: TaskStatus;
  startDate: string;
  endDate: string;
  client: string;
  amount: number;
  memo: string;
  operation: string;
};

export type TransactionType = "new" | "processed" | "canceled" | "pending";

export const getTransactions = async (
  pageSize: number,
  currentPage: number,
  transactionType: TransactionType,
  merchantId: any
) => {
  const state = store.getState();
  const user = selectUser(state);
  let url = "";
  if (user?.role === "MASTER_ADMIN") {
    merchantId
      ? (url = `/task/${merchantId}/${transactionType}?page=${currentPage}&size=${pageSize}`)
      : (url = `/task/${transactionType}?page=${currentPage}&size=${pageSize}`);
  }
  if (user?.role === "MERCHANT_USER" || user?.role === "MERCHANT_ADMIN") {
    url = `/task/${user?.merchantId}/${transactionType}?page=${currentPage}&size=${pageSize}`;
  }

  try {
    const response = await REST.get(`${url}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTransactionsByMerchantId = async (merchantId: number) => {
  try {
    const response = await REST.get(`/task/${merchantId}/~`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateTransactionStatus = async (
  taskId: number,
  status: TaskStatus
): Promise<any> => {
  try {
    const response = await REST.put(
      `/task/${taskId}/update/${status}`,
      {} // PUT request body empty since no additional data is needed
    );
    return response;
  } catch (error) {
    throw error;
  }
};
