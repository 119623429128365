import { IconUpload } from "@tabler/icons-react";
import {
  postPaymentsCreateBulk,
  postPaymentsUploadReceipt,
} from "api/paymentsController"; // Ensure this function is properly defined
import PrimaryButton from "components/shared/buttons/primaryButton/primaryButton";
import { Toaster } from "components/ui/toaster";
import { useToast } from "components/ui/use-toast";
import React, { useRef, useState } from "react";

const UploadBulkInput = ({ payment }: any) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { toast } = useToast();
  const [isUploading, setIsUploading] = useState(false);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input click
    }
  };

  const handleFileSelection = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      setIsUploading(true);
      const fileToUpload = e.target.files;
      if (!fileToUpload) return;
  
      const formData = new FormData();
      formData.append("excelFile", fileToUpload[0]);

      await postPaymentsCreateBulk(formData);

      toastSuccessfulUpload();
      window.location.reload();
    } catch (error) {
      toastFailedUpload(error);
    } finally {
      resetFileInput();
      setIsUploading(false); 
    }
  };

  const resetFileInput = () => {
    if (fileInputRef.current) fileInputRef.current.value = "";
  };


  const toastSuccessfulUpload = () => {
    toast({
      title: "Upload successful!",
      description: "Your file has been successfully uploaded.",
    });
  };

  const toastFailedUpload = (error: any) => {
    toast({
      title: "Upload failed",
      description: `There was an error uploading the file. ${error?.message || "Please try again."}`,
    });
  };

  return (
    <div>
      {/* Hidden file input */}
      <input
        ref={fileInputRef}
        type="file"
        className="hidden" // Hide the default file input
        onChange={(e) => handleFileSelection(e)}
        accept=".xlsx,.xls" // Optionally restrict file types to Excel files
      />

      {/* Upload button */}
      <PrimaryButton onClick={handleClick} disabled={isUploading}>
        {isUploading ? (
          "Uploading..."
        ) : (
          <>
            <IconUpload /> Upload Bulk
          </>
        )}
      </PrimaryButton>
    </div>
  );
};

export default UploadBulkInput;
