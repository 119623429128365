
import PrimaryButton from 'components/shared/buttons/primaryButton/primaryButton'
import { useNavigate } from 'react-router-dom'

const NotFoundPage = () => {
  const navigate = useNavigate()

 
  return (
    <div className='flex min-h-screen items-center justify-center bg-[#212632] text-white'>
      <div className='text-center'>
        <h1 className='text-4xl font-bold mb-2'>404: Page Not Found</h1>
        <p className='mb-6'>
          The page you're looking for doesn't exist.
        </p>
        <PrimaryButton onClick={()=>navigate(-1)}>
          Go Back
        </PrimaryButton>
      </div>
    </div>
  )
}

export default NotFoundPage
