"use client";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import { createPaymentRequest } from "api/cryptoController";
import { useSelector } from "react-redux";

const formSchema = z.object({
  amount: z.string().min(1).max(50),
  iban: z.string().min(1).max(50),
  bank: z.string().min(1).max(50),
  swift: z.string().min(1).max(50),
});

const CreatePaymentRequestForm = ({ merchantId }: { merchantId: string }) => {
  const user = useSelector((state: any) => state.user.user);
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      amount: "",
      iban: "",
      bank: "",
      swift: "",
    },
  });

  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      const response = await createPaymentRequest({
        ...values,
        amount: Number(values.amount),
        currency: "USD",
        status: "NEW",
        merchantId: user.merchantId,
      });
      navigate("/home/crypto-balance-merchant?status=processed");
    } catch (error) {}
    console.log(values);
  }

  return (
    <div className="m-auto mt-12 max-w-sm">
      <Form {...form}>
        <div className="flex justify-center">
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Amount</FormLabel>
                  <FormControl>
                    <div className="flex flex-col lg:flex-row items-center gap-4">
                      <Input
                        type="number"
                        className="w-[280px] lg:w-[350px]"
                        placeholder="Amount"
                        {...field}
                      />
                      <span className="text-2xl font-bold text-gray-400">
                        USD
                      </span>
                    </div>
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="iban"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>IBAN</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      className="w-[280px] lg:w-[350px]"
                      placeholder="IBAN"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="bank"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Bank</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      className="w-[280px] lg:w-[350px]"
                      placeholder="Bank"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="swift"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Swift</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      className="w-[280px] lg:w-[350px]"
                      placeholder="Swift"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              variant={"secondary"}
              className="w-[280px] lg:w-[350px] border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white"
            >
              Submit{" "}
            </Button>
          </form>
        </div>
      </Form>
    </div>
  );
};

export default CreatePaymentRequestForm;
