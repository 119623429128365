import React from 'react'
import { useParams } from 'react-router-dom'
import Layout from 'pages/(root)/home/layout'
import CreateTopUpForm from 'components/forms/createTopUpForm/createTopUpForm'

const CreateTopUp = () => {
  const { slug } = useParams()
  return (
  
      <div className='flex flex-grow dark:bg-black dark:text-white'>
        <div className='flex flex-grow p-4 pb-0'>
          <div className='flex-grow rounded-lg bg-[#212632]'>
            {/* TOPBAR  */}
            <div className='flex items-center justify-between border-b border-b-slate-500 p-4'>
              <div className='flex items-center gap-4'>
                <h2 className='py-[6px] text-xl'>New Top Up for merchant</h2>
                <span className='text-xl font-bold text-yellow-500'>
                  #{slug}
                </span>
              </div>
            </div>
            {/* Content */}
            <CreateTopUpForm merchantId={Number(slug)} />
          </div>
        </div>
      </div>
  
  )
}

export default CreateTopUp
