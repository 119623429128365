import { error } from "console";
import { REST, RESTLinks, RESTMultipart } from "./axiosConfig";
import { downloadFileFromBlob } from "lib/utils";

export const createTopUp = async (payload: {
  merchantId: number;
  amount: number;
}) => {
  try {
    const response = await REST.post("/crypto/topUp", payload);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const createPaymentRequest = async (payload: {
  amount: number;
  iban: string;
  bank: string;
  swift: string;
  currency: string;
  status: "NEW";
  merchantId: number;
}) => {
  try {
    const response = await REST.post("/crypto/paymentRequest", payload);
  } catch (error) {}
};

export const getConvert = async (payload: {
  merchantId: number;
  amount: number;
  exchangeRate: number;
  exchangeFee: number;
  bankFee: number;
}) => {
  try {
    const response = await REST.post("crypto/convert", payload);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updatePaymentRequest = async (payload: {
  id: number;
  receivingBank: string;
  status: string;
}) => {
  try {
    const response = await REST.post("crypto/updatePaymentRequest", payload);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const initTopUp = async (payload: {
  merchantId: number;
  amount: number;
  currency: string;
  transactionHash: string | undefined;
}) => {
  try {
    const response = await REST.post("/crypto/topUp/init", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTopUpById = async (topUpId: number) => {
  try {
    const response = await REST.get("/crypto/topUp/" + topUpId);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const uploadTopUpRecipe = async (topUpId: string, file: any) => {
  try {
    const response = await RESTMultipart.post(
      `/crypto/topUp/uploadRecipe/${topUpId}`,
      file
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getCryptoByStatus = async (
  status: string | null,
  pageNumber = 0
) => {
  try {
    const response = await REST.get(
      `/crypto/${status}/~?page=${pageNumber}&size=10`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const completeTopUp = async (payload: {
  exchangeRate: number;
  fiatAmount: number;
  merchantAmountReceive: number;
  providerFee: number;
  cryptoTransactionId: number;
}) => {
  try {
    const response = await REST.post("/crypto/topUp/complete", payload);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const uploadPaymentRecipe = async (
  paymentRequestId: string,
  payload: {
    file: any;
  }
) => {
  const { file } = payload;
  try {
    const response = await RESTMultipart.post(
      `/crypto/paymentRequest/uploadRecipe/${paymentRequestId}`,
      file
    );
  } catch (error) {
    console.error(error);
  }
};

export const getDownloadTopUpReceipt = async (topUpId: number) => {
  try {
    const response = await REST.get(
      `/crypto/download/topUpReceipt/${topUpId}`,
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([response.data], {
      type: "application/octet-stream",
    });
    downloadFileFromBlob(blob, "topUpReceipt.pdf");

    return;
  } catch (error) {
    throw error;
  }
};

export const getSettlementsByCryptoTransactionId = async (
  transactionId: number
) => {
  try {
    const response = await REST.get(
      `/crypto/trade/${transactionId}/settlements`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getCryptoConversionDetailsById = async (transactionId: number) => {
  try {
    const response = await REST.post(
      `/crypto/getTradeTransaction/` + transactionId
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const createCryptoConversion = async (payload: {
  cryptoTransactionId: number;
  exchangeRate: number;
  providerFee: number;
  fiatAmount: number;
  providerId: number;
  merchantAmountReceive: number;
  adjustment: number;
}) => {
  try {
    const response = await REST.post("/crypto/convertCryptoToFiat", payload);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const createCryptoConversionSettlement = async (payload: {
  tradeTransactionId: number;
  amount: number;
  description: string;
}) => {
  try {
    const response = await REST.post("/crypto/trade/addSettlement", payload);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updateCryptoTransactionStatus = async (
  transactionID: number,
  status: "NEW" | "IN_PROGRESS" | "PENDING" | "COMPLETE"
) => {
  try {
    const response = await REST.put(
      `/crypto/transaction/${transactionID}/${status}`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
