import BarChartComponent from "components/charts/barChartComponent/barChartComponent";
import LineChartComponent from "components/lineChart/lineChart";
import Footer from "components/shared/footer/footer";
import Sidebar from "components/shared/sidebar/sidebar";
import Topbar from "components/shared/topbar/topbar";
import Layout from "../layout";
import PieChart from "components/pieChart/pieChart";
import AmountBarChart from "components/charts/amountBarChart";
import AmountLineChart from "components/charts/amountLineChart";
import { ChartConfig } from "components/ui/chart";
import { DatePicker } from "components/datePicker/datePicker";
import { useEffect, useState } from "react";
import { Button } from "components/ui/button";
import { getBalance, getPayIn, getPayOut } from "api/dashboardController";
import { beautifyNumber, dateToString } from "lib/utils";
import { useSelector } from "react-redux";
import { Roles } from "roles/roles";
import {
  getWireBalance,
  getAchBalance,
  getCryptoBalance,
  getZelleBalance,
} from "api/dashboardController";
import { useHandleAxiosError } from "hooks/useAxiosHandlerError";
import { formatNumberAsDigit } from "lib/utils";

const periods = { daily: "DAILY", monthly: "MONTHLY" };

const DashBoard = () => {
  const [dailyBalanceZelle, setDailyBalanceZelle] = useState<any>();
  const [monthlyBalanceZelle, setMonthlyBalanceZelle] = useState<any>();
  const [dailyBalanceWire, setDailyBalanceWire] = useState<any>();
  const [monthlyBalanceWire, setMonthlyBalanceWire] = useState<any>();
  const [dailyBalanceCrypto, setDailyBalanceCrypto] = useState<any>();
  const [monthlyBalanceCrypto, setMonthlyBalanceCrypto] = useState<any>();
  const [dailyBalanceAch, setDailyBalanceAch] = useState<any>();
  const [monthlyBalanceAch, setMonthlyBalanceAch] = useState<any>();

  const { handleAxiosErrors } = useHandleAxiosError();

  const fetchAllData = async () => {
    try {
      const responses = await Promise.all([
        getZelleBalance(periods.daily),
        getAchBalance(periods.daily),
        getCryptoBalance(periods.daily),
        getWireBalance(periods.daily),
        getZelleBalance(periods.monthly),
        getAchBalance(periods.monthly),
        getCryptoBalance(periods.monthly),
        getWireBalance(periods.monthly),
      ]);

      setDailyBalanceZelle(responses[0]?.data?.balance);
      setDailyBalanceAch(responses[1]?.data?.balance);
      setDailyBalanceCrypto(responses[2]?.data?.balance);
      setDailyBalanceWire(responses[3]?.data?.balance);
      setMonthlyBalanceZelle(responses[4]?.data?.balance);
      setMonthlyBalanceAch(responses[5]?.data?.balance);
      setMonthlyBalanceCrypto(responses[6]?.data?.balance);
      setMonthlyBalanceWire(responses[7]?.data?.balance);

      
    } catch (err) {
      handleAxiosErrors({
        toastTitle: "Failed to get data about balance!",
        error: err,
      });
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const cardList = [
    { title: "Daily Zelle balance", value: dailyBalanceZelle },
    { title: "Monthly Zelle balance", value: monthlyBalanceZelle },
    { title: "Daily Crypto balance", value: dailyBalanceCrypto },
    { title: "Monthly Crypto balance", value: monthlyBalanceCrypto },
    { title: "Daily Ach balance", value: dailyBalanceAch },
    { title: "Monthly Ach balance", value: monthlyBalanceAch },
    { title: "Daily Wire Transfers balance", value: dailyBalanceWire },
    { title: "Monthly Wire Transfers balance", value: monthlyBalanceWire },
  ];

  const Card: React.FC<{ title: any; value: any }> = ({ title, value }) => {
    return (
      <div className="bg-gray-700 rounded-lg p-4 m-2 shadow-lg">
        <h3 className="text-lg font-semibold text-yellow-500">{title}</h3>
        <p className="text-xl text-white mt-2">
          {value !== undefined ? `$ ${formatNumberAsDigit(value)}` : "Loading..."}
        </p>
      </div>
    );
  };

  return (
    <div className="flex flex-grow dark:bg-black dark:text-white">
    <div className="flex flex-grow p-4 pb-0">
      <div className="flex-grow rounded-lg bg-[#212632]">
        <div className="flex items-center justify-between border-b border-b-slate-500 p-4">
          <h2 className="py-[6px] text-xl">
            Keep track of your
            <span className="font-bold text-yellow-500"> stats</span>
          </h2>
        </div>
  
        <div className="grid grid-cols-1 gap-5 p-7 md:grid-cols-2">
          {cardList.map((card, index) => (
            <Card key={index} title={card.title} value={card.value} />
          ))}
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default DashBoard;
