import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import React from "react";

const SelectWithdrawalCurrency = ({ field }: any) => {
  return (
    <Select onValueChange={field.onChange} defaultValue={field.value}>
      <SelectTrigger className="w-full ">
        <SelectValue
          style={{ textDecorationColor: "black" }}
          placeholder="currency"
        />
      </SelectTrigger>
      <SelectContent>
        {/* <SelectItem value={"BTC"}>BTC</SelectItem> */}
        <SelectItem value={"USD"}>USD</SelectItem>
        <SelectItem value={"EUR"}>EUR</SelectItem>
        <SelectItem value={"GBP"}>GBP</SelectItem>
      </SelectContent>
    </Select>
  );
};

export default SelectWithdrawalCurrency;
