import React, { useEffect, useState } from 'react'
import Layout from '../../layout'
import { getAllMerchants } from 'api/merchant'
import PrimaryButton from 'components/shared/buttons/primaryButton/primaryButton'
import { Link } from 'react-router-dom'
const TopUp = () => {
  const [merchants, setMerchants] = useState([])
  useEffect(() => {
    const fetchMerchants = async () => {
      try {
        const response = await getAllMerchants()
        setMerchants(response)
        console.log(response)
      } catch (error) {
        console.log(error)
      }
    }
    fetchMerchants()
  }, [])
  return (

      <div className='flex flex-grow dark:bg-black dark:text-white'>
        <div className='flex flex-grow p-4 pb-0'>
          <div className='flex-grow rounded-lg bg-[#212632]'>
            {/* TOPBAR  */}
            <div className='flex items-center justify-between border-b border-b-slate-500 p-4'>
              <div className='flex items-center gap-4'>
                <h2 className='py-[6px] text-xl'>
                  Create a new top up for any of your merchants
                </h2>
              </div>
            </div>
            {/* //? what if i top up on the merchant page? */}
            {/* Content */}
            <table className='mx-4 my-12'>
              <thead>
                <tr>
                  <th className='border border-slate-50 border-opacity-15 p-2'>
                    Merchant ID
                  </th>
                  <th className='border border-slate-50 border-opacity-15 p-2'>
                    Merchant Name
                  </th>
                  <th className='border border-slate-50 border-opacity-15 p-2'>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {merchants.map((merchant: any) => (
                  <tr>
                    <td className='border border-slate-50 border-opacity-15 p-2'>
                      {merchant?.id}
                    </td>
                    <td className='border border-slate-50 border-opacity-15 p-2'>
                      {merchant?.name}
                    </td>
                    <td className='border border-slate-50 border-opacity-15 p-2'>
                      <Link to={`create/${merchant?.id}`}>
                        <PrimaryButton>Top Up</PrimaryButton>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
  )
}

export default TopUp
