import MasterAdminSidebar from "./masterAdminSidebar";

import {
  IconCashBanknote,
  IconCashRegister,
  IconCoinEuro,
  IconCurrencyBitcoin,
  IconCurrencyDollar,
  IconDashboard,
  IconNumbers,
  IconReport,
  IconShoppingBag,
  IconTransfer,
  IconUser,
  IconWallet,
  IconReportMoney,
  IconArrowsTransferUp,
  IconTransferOut
} from "@tabler/icons-react";
import { Roles } from "roles/roles";
import { SidebarData } from "interfaces/sidebarInterface";


const Sidebar = () => {
  
  const sidebarData: SidebarData = [
    {
      value: "dashboard",
      label: "Dashboard",
      icon: <IconDashboard />,
      singleLink:"/home/dashboard",
      links: [],
      allowedRoles:[Roles.MASTER_ADMIN,Roles.MERCHANT_ADMIN]
    },
    {
      value: "balance",
      label: "Balance",
      icon: <IconReportMoney />,
      singleLink:"/home/balance",
      links: [],
      allowedRoles:[Roles.MASTER_ADMIN,Roles.MERCHANT_ADMIN]
    },
    {
      value: "zelle",
      label: "Zelle",
      icon: <IconCurrencyDollar />,
      singleLink:null,
      allowedRoles:[Roles.MASTER_ADMIN,Roles.MERCHANT_ADMIN,Roles.MERCHANT_USER],
      links: [
        {
          path: "/home/transactions",
          label: "Transactions",
          subIcon: <IconCashRegister />,
          allowedRoles:[Roles.MASTER_ADMIN,Roles.MERCHANT_ADMIN,Roles.MERCHANT_USER]
        },
        { path: "/home/reports", 
          label: "Reports",
           subIcon: <IconReport />,
           allowedRoles:[Roles.MASTER_ADMIN]
          },
      ],
    },
    {
      value: "crypto",
      label: "Crypto",
      icon: <IconCurrencyBitcoin />,
      singleLink:null,
      allowedRoles:[Roles.MASTER_ADMIN,Roles.MERCHANT_ADMIN],
      links: [
        {
          path: "/home/processing-companies",
          label: "Processing LTD",
          subIcon: <IconNumbers />,
          allowedRoles:[Roles.MASTER_ADMIN]
        },
        {
          path: "/home/exchange-companies",
          label: "Exchange LTD",
          subIcon: <IconCoinEuro />,
          allowedRoles:[Roles.MASTER_ADMIN]
        },
        {
          path: "/home/crypto-balance",
          label: "Transactions",
          subIcon: <IconCurrencyBitcoin />,
          allowedRoles:[Roles.MASTER_ADMIN]
        },
        {
          path: "/home/crypto-balance-merchant",
          label: "Transactions",
          subIcon: <IconCurrencyBitcoin />,
          allowedRoles:[Roles.MERCHANT_ADMIN]
        },
        { path: "/home/crypto/reports",
           label: "Reports", 
           subIcon: <IconReport /> ,
           allowedRoles:[Roles.MASTER_ADMIN]
          },
      ],
    },
    {
      value: "admin",
      label: "Admin",
      icon: <IconUser />,
      singleLink:null,
      allowedRoles:[Roles.MASTER_ADMIN,Roles.MERCHANT_ADMIN],
      links: [
        { path: "/home/merchants", 
          label: "Merchants", 
          subIcon: <IconWallet />,
          allowedRoles:[Roles.MASTER_ADMIN]
         },
        { path: "/home/agents", 
          label: "Agents",
           subIcon: <IconUser />,
           allowedRoles:[Roles.MASTER_ADMIN,Roles.MERCHANT_ADMIN] },
      ],
    },
    {
      value: "ach",
      label: "Ach",
      icon: <IconTransfer />,
      singleLink:null,
      allowedRoles:[Roles.MASTER_ADMIN,Roles.MERCHANT_ADMIN],
      links: [
        { path: "/home/payments", 
          label: "Payments", 
          subIcon: <IconShoppingBag />,
          allowedRoles:[Roles.MASTER_ADMIN] 
        },
        { path: "/home/payments-merchant", 
          label: "Payments", 
          subIcon: <IconShoppingBag />,
          allowedRoles:[Roles.MERCHANT_ADMIN] 
        },
        {
          path: "/home/payments-reports",
          label: "Reports",
          subIcon: <IconReport />,
          allowedRoles:[Roles.MASTER_ADMIN]
        },
      ],
    },
    {
      value: "withdrawals",
      label: "Withdrawals",
      icon: <IconTransferOut />,
      singleLink:null,
      allowedRoles:[Roles.MASTER_ADMIN,Roles.MERCHANT_ADMIN],
      links: [
        {
          path: "/home/withdrawals",
          label: "Withdrawals",
          subIcon: <IconTransferOut />,
          allowedRoles:[Roles.MASTER_ADMIN,Roles.MERCHANT_ADMIN]
        },
        {
          path: "/home/withdrawals-reports",
          label: "Reports",
          subIcon: <IconReport />,
          allowedRoles:[Roles.MASTER_ADMIN]
        },
      ],
    },
  ];

      return   <MasterAdminSidebar sidebarElements={sidebarData}/>;
};

export default Sidebar;
