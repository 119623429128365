import { REST } from "./axiosConfig";


export const createMerchant = async (data: any) => {
  try {
    const dataToSend = {
      ...data,
      id: 0,
    };
    const response = await REST.post("/merchant/create", dataToSend);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMerchants = async (pageSize: number, currentPage: number) => {
  const url = `/merchant/~?page=${currentPage}&size=${pageSize}`;

  try {
    const response = await REST.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllMerchants = async () => {
  try {
    const response = await REST.get("/merchant/~?page=0&size=99999");
    const merchants = response?.data;

    return merchants;
  } catch (error) {
    console.log(error);
  }
};


export const getAllMerchantsPerPage = async (currentPage: number) => {
  try {
    const response = await REST.get(`/merchant/~?page=${currentPage}&size=10`);
    const merchants = response?.data;

    return merchants;
  } catch (error) {
    console.log(error);
  }
};

export const getMerchantById = async (merchantId: number) => {
  try {
    const response = await REST.get(
      `/merchant/merchantById?merchantId=${merchantId}`
    );
    return response.data;
  } catch (error) {
    throw error
  }
};

export const updateMerchant = async (data: any) => {
  try {
    const response = await REST.put("/merchant/update", data);
    return response;
  } catch (error) {
    throw error
  }
};
