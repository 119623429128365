import CreateWithdrawalForm from "components/forms/createWithdrawalForm/createWithdrawalForm";

const CreateWithdrawal = () => {
  return (
   
      <div className="flex flex-grow dark:bg-black dark:text-white">
        <div className="flex flex-grow p-4 pb-0">
          <div className="flex-grow rounded-lg bg-[#212632]">
            {/* TOPBAR  */}
            <div className="flex items-center justify-between border-b border-b-slate-500 p-4">
              <div className="flex items-center gap-4">
                <h2 className="py-[6px] text-xl">Create a new Withdrawal</h2>
              </div>
            </div>

            {/* Content */}
            <CreateWithdrawalForm />
          </div>
        </div>
      </div>
  
  );
};

export default CreateWithdrawal;
