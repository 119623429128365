import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";


const SelectWithdrawalStatus = ({ onValueChange, defaultValue }: any) => {

  return (
    <Select onValueChange={onValueChange} value={defaultValue}>
      <SelectTrigger className="w-full ">
        <SelectValue
          style={{ textDecorationColor: "black" }}
          placeholder={defaultValue}
        />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={"IN_PROGRESS"}>In Progress</SelectItem>
        <SelectItem value={"PENDING"}>Pending</SelectItem>
        <SelectItem value={"COMPLETE"}>Complete</SelectItem>
      </SelectContent>
    </Select>
  );
};

export default SelectWithdrawalStatus;
