import { REST } from "./axiosConfig";

export const generateCryptoReport = async (
  startDate: string,
  endDate: string
) => {
  let response: any;
  try {
    response = await REST.get(
      `/reports/crypto?startDate=${startDate}&endDate=${endDate}`,
      {
        responseType: "arraybuffer",
      }
    );

    const blob = new Blob([response.data], {
      type: "application/octet-stream",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `crypto-report-${startDate}-${endDate}.xlsx`; // Change the file name as needed

    // Append the link to the body (necessary for Firefox)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  } catch (error) {
    throw error;
  }
};
export const generateAchReport = async (
  startDate: string,
  endDate: string,
  merchantId: number
) => {
  let response: any;
  try {
    response = await REST.post(
      `/payments/exportExcel`,
      { merchantId, startDate, endDate },
      {
        responseType: "arraybuffer",
      }
    );

    const blob = new Blob([response.data], {
      type: "application/octet-stream",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `ach-report-${startDate}-${endDate}.xlsx`; // Change the file name as needed

    // Append the link to the body (necessary for Firefox)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error("There was an error downloading the file!", error);
  }
};
export const generateWithdrawalsReport = async (
  startDate: string,
  endDate: string,
  merchantId: number
) => {
  let response: any;
  try {
    response = await REST.post(
      `/withdrawal/exportExcel`,
      { merchantId, startDate, endDate },
      {
        responseType: "arraybuffer",
      }
    );

    const blob = new Blob([response.data], {
      type: "application/octet-stream",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `withdrawal-report-${startDate}-${endDate}.xlsx`; // Change the file name as needed

    // Append the link to the body (necessary for Firefox)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error("There was an error downloading the file!", error);
  }
};

export const generateDefaultReport = async (payload: {
  startDate: string;
  endDate: string;
  merchantId: number;
  accountType: string;
}) => {
  let response: any;
  try {
    response = await REST.get(
      `/reports/${payload?.accountType}/${payload?.merchantId}?startDate=${payload?.startDate}&endDate=${payload?.endDate}`, {
      responseType: "arraybuffer",
    }
    );

    const blob = new Blob([response.data], {
      type: "application/octet-stream",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${payload?.accountType}-report-${payload?.startDate}-${payload?.endDate}.xlsx`; // Change the file name as needed

    // Append the link to the body (necessary for Firefox)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error("There was an error downloading the file!", error);
    throw error;
  }
};
