import React from "react";
import { useTable } from "react-table";

interface TableProps {
  data: any[];
  columns: any[];
}

const CustomTable: React.FC<TableProps> = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <div className="table-container min-h-[500px] max-w-[400px] md:min-h-[auto] md:max-w-full">
      {/* Web version */}
      <div
        className="hidden md:block overflow-x-auto overflow-y-scroll"
        style={{ height: "60vh", overflowY: "scroll" }}
      >
        <table
          {...getTableProps()}
          className="w-full table-auto text-xs sm:text-sm md:text-base dark:text-slate-300"
        >
          <thead>
            {headerGroups.map((headerGroup, headerGroupIndex) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={`header-group-${headerGroupIndex}`}
              >
                {headerGroup.headers.map((column, columnIndex) => (
                  <th
                    {...column.getHeaderProps()}
                    key={`header-${headerGroupIndex}-${columnIndex}`}
                    className="border border-slate-50 border-opacity-15 p-1 sm:p-2 md:p-4 text-left bg-white dark:bg-slate-800"
                    style={{
                      position: "sticky",
                      top: 0, 
                      zIndex: 1, 
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={`row-${rowIndex}`}>
                  {row.cells.map((cell, cellIndex) => (
                    <td
                      {...cell.getCellProps()}
                      key={`cell-${rowIndex}-${cellIndex}`}
                      className="border border-slate-50 border-opacity-15 p-1 sm:p-2 md:p-4 truncate"
                      style={{
                        maxWidth: "150px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Mobile version */}
      <div className="md:hidden flex flex-col gap-4 overflow-x-auto overflow-y-auto max-h-[450px] max-w-full">
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          return (
            <div
              key={`card-row-${rowIndex}`}
              className="flex flex-wrap border rounded-lg p-4 shadow-lg dark:bg-slate-800 dark:text-slate-300 bg-white"
              style={{
                minWidth: "280px",
                maxWidth: "100%",
              }}
            >
              {row.cells.map((cell, cellIndex) => {
                const column = columns[cellIndex];
                return (
                  <div
                    key={`card-cell-${rowIndex}-${cellIndex}`}
                    className="flex-1 min-w-[150px] mb-2"
                  >
                    <div className="font-semibold text-xs sm:text-sm text-slate-600 dark:text-slate-400">
                      {column.Header}
                    </div>
                    <div className="text-sm sm:text-base">
                      {cell.render("Cell")}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomTable;
