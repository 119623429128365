import { REST } from './axiosConfig'


export const getBalanceByMerchantAndType= async ( 
    merchantId : number,
    accountType : "ZELLE" | "CRYPTO" | "ACH" | "WIRE_TRANSFER",
    pageSize: number,
    currentPage: number,
  ) => {
    try {
      const response = await REST.get(`/balance/${merchantId}/${accountType}?page=${currentPage}&size=${pageSize}`);
      return response;
    } catch (error) {
      throw error
    }
  };

  export const createInternalTransfer=async ( payload:{ merchantId: number,
    amount:number,
    description:string,
    sourceAccount:string,
    destinationAccount:string}
  ) => {
    try {
      const response = await REST.post(`/internalTransfer/create`,payload);
      return response;
    } catch (error) {
      throw error
    }
  };
