import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "../routes";
import { IconLoader } from "@tabler/icons-react";
import RequireRole from "../requireRole";
import Login from "pages/(root)/(login)/page";
import ForbiddenPage from "pages/(root)/(403page)/page";
import NotFoundPage from "pages/(root)/(404page)/page";
import LandingPage from "pages/(root)/(landingPage)/page";
import Layout from "pages/(root)/home/layout";

const ContentRoutes = () => {
  return (
    <Layout>
      <Routes>
        {routes.map((route, index) => (
          <Route
            key={index}
            element={<RequireRole allowedRoles={route?.allowedRoles} />}
          >
            <Route
              path={route.path}
              element={React.createElement(route.element)}
            />
          </Route>
        ))}
      </Routes>
    </Layout>
  );
};


const AuthenticatedRoutes = () => (
  <Suspense
    fallback={<IconLoader size={72} className="m-auto mt-14 animate-spin" />}
  >
    <Routes>
      <Route path={"/home/*"} element={<ContentRoutes />} />
      <Route path={"/home"} element={<LandingPage />} />
      <Route path={"/*"} element={<NotFoundPage />} />
      <Route path={"/403"} element={<ForbiddenPage />} />
      <Route path={"/login"} element={<Login />} />
      <Route path={"/"} element={<LandingPage />} />
    </Routes>
  </Suspense>
);

export default AuthenticatedRoutes;
