import { getExchangeCompanies } from "api/management";
import PrimaryButton from "components/shared/buttons/primaryButton/primaryButton";
import { Button } from "components/ui/button";
import Layout from "pages/(root)/home/layout";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatNumberAsDigit } from "lib/utils";

const ExchangeCompanies = () => {
  const [exchangeCompanies, setExchangeCompanies] = useState([]);

  useEffect(() => {
    const fetchExchangeCompanies = async () => {
      try {
        const response = await getExchangeCompanies();
        setExchangeCompanies(response);
      } catch (error) {
        console.log(error);
      }
    };
    fetchExchangeCompanies();
  });

  const ExchangeCompaniesCards = () => (
    <div className="max-h-screen overflow-y-scroll p-5 flex gap-4 flex-wrap ">
      {/* Content */}
      {exchangeCompanies?.map((exchangeCompany: any) => (
        <div
          key={exchangeCompany.id}
          className="flex-none w-72 min-w-[200px] flex-shrink-0 items-center justify-between rounded-lg border border-yellow-500 bg-gray-700 px-4 py-2 shadow-sm"
        >
          <h2 className="py-[6px] text-xl">Name: {exchangeCompany?.name}</h2>
          <h2 className="text-xl">
            {" "}
            Fee: {formatNumberAsDigit(exchangeCompany?.exchangeFee)}%
          </h2>
        </div>
      ))}
    </div>
  );

  return (
    <div className="flex flex-grow dark:bg-black dark:text-white">
      <div className="flex flex-grow p-4 pb-0">
        <div className="flex-grow rounded-lg bg-[#212632]">
          {/* TOPBAR  */}
          <div className="flex items-center justify-between border-b border-b-slate-500 p-4">
            <div className="flex items-center gap-4">
              <h2 className="py-[6px] text-xl">Exchange Companies</h2>
            </div>
            <Link to="/home/exchange-companies/create?step=1">
              <PrimaryButton>Create</PrimaryButton>
            </Link>
          </div>
          <ExchangeCompaniesCards />
        </div>
      </div>
    </div>
  );
};

export default ExchangeCompanies;
