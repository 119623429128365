import { REST } from "./axiosConfig";


export const getCryptoBalanceForMerchant = async (merchantId: number) => {
  try {
    const response = await REST.get(`/dashboard/crypto/balance/${merchantId}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getBalance = async () => {
  try {
    const response = await REST.get(`/dashboard/balance`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getPayIn = async (startDate: string, endDate: string) => {
  try {
    const response = await REST.get(
      `/dashboard/pay-in?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }

};
export const getPayOut = async (startDate: string, endDate: string) => {
  try {
    const response = await REST.get(
      `/dashboard/pay-out?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};


export const getZelleBalance = async (type: string) => {
  try {
    const response = await REST.get(
      `/dashboard/zelle/${type}`
    );
    return response;
  } catch (error) {
    throw error
  }
};

export const getCryptoBalance = async (type: string) => {
  try {
    const response = await REST.get(
      `/dashboard/crypto/${type}`
    );
    return response;
  } catch (error) {
    throw error
  }
};

export const getAchBalance = async (type: string) => {
  try {
    const response = await REST.get(
      `/dashboard/ach/${type}`
    );
    return response;
  } catch (error) {
    throw error
  }
};

export const getWireBalance = async (type: string) => {
  try {
    const response = await REST.get(
      `/dashboard/wireTransfers/${type}`
    );
    return response;
  } catch (error) {
    throw error
  }
};




