import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "components/ui/pagination";

interface ExternalTablePaginationProps {
  totalPages: number;
  pageNumber: number;
  handlePageChange: (page: number) => void;
}

const ExternalTablePagination = ({
  totalPages,
  pageNumber,
  handlePageChange,
}: ExternalTablePaginationProps) => {
  const renderPageLink = (page: number) => (
    <PaginationItem key={page}>
      <PaginationLink
        size="sm"
        className="cursor-pointer border text-xs sm:text-sm md:text-base p-1 sm:p-2"
        onClick={() => handlePageChange(page)}
      >
        {page + 1}
      </PaginationLink>
    </PaginationItem>
  );

  

  return (
    <Pagination className="p-1 sm:p-2">
      <PaginationContent>
        {pageNumber > 0 && (
          <PaginationItem>
            <PaginationPrevious
              className="cursor-pointer text-xs sm:text-sm md:text-base p-1 sm:p-2"
              onClick={() => handlePageChange(pageNumber - 1)}
            />
          </PaginationItem>
        )}

        {pageNumber > 0 && renderPageLink(0)}
        {pageNumber > 2 && (
          <PaginationItem>
            <PaginationEllipsis className="text-xs sm:text-sm md:text-base" />
          </PaginationItem>
        )}
        {pageNumber > 1 && renderPageLink(pageNumber - 1)}

        <PaginationItem>
          <PaginationLink className="cursor-pointer border-yellow-500 border text-xs sm:text-sm md:text-base p-1 sm:p-2">
            {pageNumber + 1}
          </PaginationLink>
        </PaginationItem>

        {pageNumber < totalPages - 1 && renderPageLink(pageNumber + 1)}
        {pageNumber < totalPages - 3 && (
          <PaginationItem>
            <PaginationEllipsis className="text-xs sm:text-sm md:text-base" />
          </PaginationItem>
        )}
        {pageNumber < totalPages - 2 && renderPageLink(totalPages - 1)}

        {pageNumber + 1 < totalPages && (
          <PaginationItem>
            <PaginationNext
              className="cursor-pointer text-xs sm:text-sm md:text-base p-1 sm:p-2"
              onClick={() => handlePageChange(pageNumber + 1)}
            />
          </PaginationItem>
        )}
      </PaginationContent>
    </Pagination>
  );
};

export default ExternalTablePagination;
