'use client'
import React from 'react'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { Button } from '../../ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '../../ui/form'
import { Input } from '../../ui/input'
import { register } from 'api/auth'
import { useMutation } from '@tanstack/react-query'

const CreateTransactionForm = ({ item = null }: { item?: any }) => {
  const formSchema = z.object({
    id: z.preprocess(a => parseInt(z.string().parse(a), 10), z.number()),
    startDate: z.string().min(1).max(50),
    endDate: z.string().min(1).max(50),
    player: z.string().min(1).max(50),
    status: z.string().min(1).max(50),
    amount: z.preprocess(a => parseInt(z.string().parse(a), 10), z.number()),
    memo: z.string().min(1).max(50),
    operation: z.string().min(1).max(50)
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      id: item?.id || NaN,
      startDate: item?.startDate || '',
      endDate: item?.endDate || '',
      player: item?.player || '',
      status: item?.status || '',
      amount: item?.amount || NaN,
      memo: item?.memo || '',
      operation: item?.operation || ''
    }
  })

  function onSubmit(values: any) {
    console.log(values)
  }
  return (
    <Form {...form}>
      <form onSubmit={form?.handleSubmit(onSubmit)} className='space-y-8'>
        <FormField
          control={form?.control}
          name='id'
          render={({ field }) => (
            <FormItem>
              <FormLabel className='dark:text-white'>Id</FormLabel>
              <FormControl>
                <Input
                  type='number'
                  className='dark:text-white'
                  placeholder='Id'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form?.control}
          name='startDate'
          render={({ field }) => (
            <FormItem>
              <FormLabel className='dark:text-white'>Start Date</FormLabel>
              <FormControl>
                <Input
                  className='dark:text-white'
                  placeholder='Start Date'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form?.control}
          name='endDate'
          render={({ field }) => (
            <FormItem>
              <FormLabel className='dark:text-white'>End Date</FormLabel>
              <FormControl>
                <Input
                  className='dark:text-white'
                  placeholder='End Date'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form?.control}
          name='player'
          render={({ field }) => (
            <FormItem>
              <FormLabel className='dark:text-white'>Player</FormLabel>
              <FormControl>
                <Input
                  className='dark:text-white'
                  placeholder='Player'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form?.control}
          name='status'
          render={({ field }) => (
            <FormItem>
              <FormLabel className='dark:text-white'>Status</FormLabel>
              <FormControl>
                <Input
                  className='dark:text-white'
                  placeholder='Status'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form?.control}
          name='amount'
          render={({ field }) => (
            <FormItem>
              <FormLabel className='dark:text-white'>Amount</FormLabel>
              <FormControl>
                <Input
                  type='number'
                  className='dark:text-white'
                  placeholder='Amount'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form?.control}
          name='memo'
          render={({ field }) => (
            <FormItem>
              <FormLabel className='dark:text-white'>Memo</FormLabel>
              <FormControl>
                <Input
                  className='dark:text-white'
                  placeholder='Memo'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form?.control}
          name='operation'
          render={({ field }) => (
            <FormItem>
              <FormLabel className='dark:text-white'>Operation</FormLabel>
              <FormControl>
                <Input
                  className='dark:text-white'
                  placeholder='Operation'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type='submit'>Submit</Button>
      </form>
    </Form>
  )
}

export default CreateTransactionForm
