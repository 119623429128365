import { REST } from './axiosConfig'

export const createProcessingCompany = async (data: {
  name: string
  companyFee: number
}) => {
  try {
    const response = await REST.post('management/processingCompany/create', {
      ...data
    })
    return response
  } catch (error) {
    console.error('Error creating processing company:', error)
  }
}

export const getProcessingCompanies = async () => {
  try {
    const response = await REST.get('management/processingCompany/~')
    return response.data
  } catch (error) {
    console.error('Error fetching processing company:', error)
  }
}

export const createExchange = async (data: {
  name: string
  exchangeFee: number
}) => {
  try {
    const response = await REST.post('/management/exchange/create', {
      ...data,
      id: 0
    })
    return response
  } catch (error) {
    console.error('Error creating exchange:', error)
  }
}
