"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { IconCopy, IconWallet } from "@tabler/icons-react";
import { initTopUp, uploadTopUpRecipe } from "api/cryptoController";
import { getMerchantById } from "api/merchant";
import { Button } from "components/ui/button";
import { Checkbox } from "components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import { toast } from "components/ui/use-toast";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import {
  Select,
  SelectItem,
  SelectContent,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import axios, { AxiosError } from "axios";
import { NumericFormat } from "react-number-format";
import { formatStringDigitToNumber } from "lib/utils";

interface TopUpData {
  merchantId: number;
  amount: number;
  currency: string;
  transactionHash: string | undefined;
}

const MerchantCreateTopUpForm = ({ merchantId }: { merchantId: number }) => {
  const [wallet, setWallet] = useState<any>();
  const [file, setFile] = useState<File>();
  const [hasHash, setHasHash] = useState<boolean>(false);

  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user.user);

  const formSchema = z.object({
    amount: z.string().min(1).max(50),
    currency: z.enum(["BTC", "USDT"]),
    transactionHash: hasHash ? z.string().min(1).max(150) : z.string(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      amount: "",
      currency: "BTC",
      transactionHash: "",
    },
  });

  const { setValue } = form;

  const fetchMerchantById = async () => {
    try {
      const response = await getMerchantById(user?.merchantId);
      setWallet(response?.wallet);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMerchantById();
  }, []);

  useEffect(() => {
    if (hasHash) {
      setFile(undefined);
    } else {
      setValue("transactionHash", "");
    }
  }, [hasHash]);

  const previewDocument = () => {
    if (file) {
      const url = URL.createObjectURL(file);
      window.open(url, "_blank");
    } else {
      alert("No file selected for preview.");
    }
  };

  const uploadTopUpFile = async (topUpId: string) => {
    const formData = new FormData();
    if (file) formData.append("file", file);

    try {
      await uploadTopUpRecipe(topUpId, formData).then((res) => {
        if (res?.status === 200) {
          navigate("/home/crypto-balance-merchant?status=NEW");
        }
      });
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        const errorDetails = error.response?.data?.details;
        toast({
          title: `ERROR: Failed to upload top up file!`,
          description: `REASON: ${errorDetails || error.message}`,
        });
      } else {
        toast({
          title: "An unexpected error occurred. Please try again later.",
        });
      }
    }
  };

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const topUpData: TopUpData = {
      merchantId,
      amount: +formatStringDigitToNumber(values?.amount),
      currency: values?.currency,
      transactionHash: hasHash ? values?.transactionHash : "",
    };

    try {
      await initTopUp(topUpData).then((res) => {
        if (res) {
          hasHash
            ? navigate("/home/crypto-balance-merchant?status=NEW")
            : uploadTopUpFile(res);
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorDetails = error.response?.data?.details;
        toast({
          title: `ERROR: Failed to create top up!`,
          description: `REASON: ${errorDetails || error.message}`,
        });
      } else {
        toast({
          title: "An unexpected error occurred. Please try again later.",
        });
      }
    }
  }

  const copyWalletToClipboard = () => {
    if (wallet) {
      navigator.clipboard.writeText(wallet).then(() => {
        toast({
          title: "Wallet Copied!",
          description: "The wallet address has been copied to the clipboard.",
        });
      });
    }
  };

  return (
    <div className="m-auto mt-12 max-w-sm">
      <Form {...form}>
        <div className="flex justify-center">
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <div className="flex justify-between gap-16">
              <div className="space-y-4">
                <h2 className="flex items-center flex-row gap-2 justify-between">
                  <div className="flex items-center gap-2">
                    <IconWallet />
                    Wallet
                  </div>
                  <IconCopy size={20} onClick={() => copyWalletToClipboard()} className="cursor-pointer"/>
                </h2>

                <div className="flex gap-4">
                  <Input
                    value={wallet}
                    readOnly
                    className="w-[280px] lg:w-[350px] text-right text-xl"
                  />
                </div>

                <FormField
                  control={form.control}
                  name="amount"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="flex flex-col lg:flex-row items-center gap-4">
                        Sending BTC
                      </FormLabel>
                      <FormControl>
                        <NumericFormat
                          {...field}
                          customInput={Input}
                          thousandSeparator
                          decimalScale={3}
                          allowNegative={false}
                          className="w-[280px] lg:w-[350px] text-right text-xl"
                          placeholder="Amount"
                          onValueChange={(values) => {
                            field.onChange(+values.value);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="currency"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Currency</FormLabel>
                      <FormControl>
                        <Select
                          onValueChange={(value) => field.onChange(value)}
                          value={field?.value}
                        >
                          <SelectTrigger className="w-full bg-slate-500 text-white">
                            <SelectValue
                              style={{ textDecorationColor: "black" }}
                              placeholder="Currency"
                            />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="BTC">BTC</SelectItem>
                            <SelectItem value="USDT">USDT</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="my-12 flex items-center justify-start gap-4 pl-12">
                  <Checkbox
                    onCheckedChange={() => setHasHash((prev) => !prev)}
                  />
                  <h2 className="text-xl">Has Hash</h2>
                </div>

                {hasHash ? (
                  <FormField
                    control={form.control}
                    name="transactionHash"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Hash</FormLabel>
                        <FormControl>
                          <div className="flex flex-col lg:flex-row items-center gap-4">
                            <Input
                              type="text"
                              className="w-[280px] lg:w-[350px] text-right text-xl"
                              placeholder="Hash code"
                              {...field}
                            />
                          </div>
                        </FormControl>

                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ) : (
                  <>
                    <FormItem>
                      <FormLabel>Top Up Receipt (PDF format)</FormLabel>
                      <div className="flex items-center gap-4">
                        <Input
                          onChange={(e) => {
                            if (e.target.files) setFile(e.target.files[0]);
                          }}
                          required
                          type="file"
                          accept=".pdf"
                          className="w-[280px] lg:w-[350px] text-right text-xl"
                        />
                      </div>
                    </FormItem>

                    {file ? (
                      <a
                        className="mt-4 p-2 text-white cursor-pointer"
                        onClick={() => previewDocument()}
                      >
                        Preview document
                      </a>
                    ) : null}
                  </>
                )}
              </div>
            </div>
            <div className="flex pb-12">
              <Button
                variant={"secondary"}
                className="w-[280px] lg:w-[350px] border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white"
              >
                Top Up{" "}
              </Button>
            </div>
          </form>
        </div>
      </Form>
    </div>
  );
};

export default MerchantCreateTopUpForm;
