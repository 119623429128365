import { getNotifications, readNotification } from "api/notificationController";
import { useEffect, useState } from "react";
import Layout from "../layout";
import PrimaryButton from "components/shared/buttons/primaryButton/primaryButton";
import { IconWindowMaximize } from "@tabler/icons-react";
import TablePagination from "components/shared/tablePagination/tablePagination";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { openChat } from "store/utilsSlice";

const Notifications = () => {
  const [notificationsData, setNotificationsData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);

  const user = useSelector((state: any) => state.user.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePageChange = async (newPage: number) => {
    setCurrentPage(newPage);
  };

  const markNotificationAsRead = async (id: number) => {
    try {
      const response = await readNotification(id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      console.log(currentPage);
      try {
        const response = await getNotifications(currentPage);
        setNotificationsData(response);
      } catch (error) {
        console.log(error);
      }
    };
    fetchNotifications();
  }, [currentPage]);

  const handleOnClick = (notification: any) => {
    // mark it as read in the database
    markNotificationAsRead(notification?.id);

    markNotificationAsReadInFrontend(notification?.id, notification);
console.log(notification)
    if (notification?.type === "CHAT") {
      dispatch(openChat());
    } else navigate(redirectToNotificationsByType(notification?.type, user));
  };

  const markNotificationAsReadInFrontend = async (
    id: number,
    notification: any
  ) => {
    const newNotificationsContent = notificationsData?.content?.map(
      (n: any) => {
        if (n?.id === notification?.id) {
          return {
            ...n,
            isRead: true,
          };
        }
        return n;
      }
    );

    setNotificationsData({
      ...notificationsData,
      content: newNotificationsContent,
    });
  };

  //   {
  //     "id": 5,
  //     "createdAt": "2024-07-12T09:13:42.895941",
  //     "message": "You have a new crypto notification",
  //     "type": null,
  //     "receiverRole": "MASTER_ADMIN",
  //     "receiverId": null,
  //     "isRead": null
  //   },

  return (
   
      <div className="flex flex-grow dark:bg-black dark:text-white">
        <div className="flex flex-grow p-4 pb-0">
          <div className="flex flex-grow flex-col rounded-lg bg-[#212632]">
            {/* TOPBAR  */}
            <div className="flex items-center justify-between border-b border-b-slate-500 p-4">
              <div className="flex items-center gap-4">
                <h2 className="py-[6px] text-xl">
                  See all your notifications listed here
                </h2>
              </div>
            </div>
            {/* Content */}
            <div className="container mt-12 flex flex-grow flex-col justify-between">
              <table className="hidden lg:table w-full text-center">
                <thead>
                  <tr>
                    <th className="border border-gray-400">Created at</th>
                    <th className="border border-gray-400">Message</th>
                    <th className="border border-gray-400">Type</th>
                    <th className="border border-gray-400">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {notificationsData?.content?.map((notification: any) => (
                    <tr key={notification?.id}>
                      <td className="border border-gray-400">
                        {formatDateToShow(notification?.createdAt)}
                      </td>
                      <td className="border border-gray-400">
                        {notification?.message}
                      </td>
                      <td className="border border-gray-400">
                        {notification?.type || "no type"}
                      </td>
                      <td className="border border-gray-400 py-2">
                        <PrimaryButton
                          className={`${notification?.isRead ? "bg-gray-400" : ""}`}
                          onClick={() => handleOnClick(notification)}
                        >
                          {" "}
                          <IconWindowMaximize />{" "}
                          {notification?.isRead ? "Read" : "Mark as read"}
                        </PrimaryButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="mx-4 mb-12">
                <div className="lg:hidden space-y-4">
                  {notificationsData?.content?.map((notification: any) => (
                    <div
                      key={notification?.id}
                      className="border border-gray-400 p-4 rounded-lg shadow-sm"
                    >
                      <div className="flex justify-between items-center mb-2">
                        <span className="font-semibold">Created at:</span>
                        <span>{formatDateToShow(notification?.createdAt)}</span>
                      </div>
                      <div className="mb-2">
                        <span className="font-semibold">Message:</span>
                        <span>{notification?.message}</span>
                      </div>
                      <div className="flex justify-between items-center mb-2">
                        <span className="font-semibold">Type:</span>
                        <span>{notification?.type || "no type"}</span>
                      </div>
                      <div className="flex justify-end">
                        <PrimaryButton
                          className={`px-4 py-2 ${notification?.isRead ? "bg-gray-400" : ""}`}
                          onClick={() => handleOnClick(notification)}
                        >
                          <IconWindowMaximize className="mr-2" />
                          {notification?.isRead ? "Read" : "Mark as read"}
                        </PrimaryButton>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {notificationsData?.content && (
                <div className="my-12">
                  <TablePagination
                    paginationData={notificationsData}
                    handlePageChange={handlePageChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
   
  );
};

// [ ZELLE, CRYPTO, ACH, CHAT
const redirectToNotificationsByType = (type: string, user: any) => {
  switch (type) {
    case "ZELLE":
      return "/home/transactions";

    case "CRYPTO":
      if (user?.role === "MASTER_ADMIN") return "/home/crypto-balance";
      else return "/home/crypto-balance-merchant";

    case "ACH":
      if (user?.role === "MASTER_ADMIN") return "/home/payments";
      else return "/home/payments-merchant";

    default:
      return "/home/transactions";
  }
};

export const formatDateToShow = (dateString: string) => {
  const date = new Date(dateString);

  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate();
  const year = date.getFullYear();

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  // format it to show
  return `${day} ${month} ${year} at ${hours}:${minutes}`;
};

export default Notifications;
