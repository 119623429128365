import React from 'react'
import Layout from '../../../../layout'
import { useParams } from 'react-router-dom'
import CreateTopUpForm from 'components/forms/merchantCreateTopUpForm/merchantCreateTopUpForm'
import CreatePaymentRequestForm from 'components/forms/paymentRequestForms/createPaymentRequestForm/createPaymentRequestForm'
import { useSelector } from 'react-redux'

const CreatePaymentRequestMerchant = () => {
  const user = useSelector((state: any) => state.user.user)
  return (
    
      <div className='flex flex-grow dark:bg-black dark:text-white'>
        <div className='flex flex-grow p-4 pb-0'>
          <div className='flex-grow rounded-lg bg-[#212632] pb-12'>
            {/* TOPBAR  */}
            <div className='flex items-center justify-between border-b border-b-slate-500 p-4'>
              <div className='flex items-center gap-4'>
                <h2 className='py-[6px] text-xl'>New Payment Request</h2>
              </div>
            </div>
            {/* Content */}
            <CreatePaymentRequestForm merchantId={user.merchantId} />
          </div>
        </div>
      </div>
 
  )
}

export default CreatePaymentRequestMerchant
