import { IconUpload,IconRotateClockwise } from "@tabler/icons-react";
import { postPaymentsUploadReceipt } from "api/paymentsController";
import PrimaryButton from "components/shared/buttons/primaryButton/primaryButton";
import { Toaster } from "components/ui/toaster";
import { useToast } from "components/ui/use-toast";
import React, { useRef, useState } from "react";

const UploadAchReceiptInput = ({ payment }: any) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { toast } = useToast();
  const [isUploading, setIsUploading] = useState(false);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); 
    }
  };

  const handleFileSelection = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        toast({
          title: "No file selected. Please choose a file.",
        });
        return;
      }

      const fileToUpload = e.target.files[0];

      const formData = new FormData();
      formData.append("file", fileToUpload);

      setIsUploading(true); 

      await postPaymentsUploadReceipt(payment.id, formData);

      toast({
        title: "Receipt uploaded successfully",
      });

    } catch (error) {
      console.error("Upload error:", error);
      toast({
        title: "Upload failed. Please try again.",
      });
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        className="hidden" // Hide the default file input
        accept=".pdf"
        onChange={handleFileSelection}
      />
      <PrimaryButton onClick={handleClick} disabled={isUploading}>
        {isUploading ? (
          "Uploading..."
        ) : (
          <>
            {payment?.receiptFileName ? (
              <>
                <IconRotateClockwise className="mr-2" /> Update Receipt
              </>
            ) : (
              <>
                <IconUpload className="mr-2" /> Upload Receipt
              </>
            )}
          </>
        )}
      </PrimaryButton>
      <Toaster />
    </div>
  );
};

export default UploadAchReceiptInput;
