import { Checkbox } from "@radix-ui/react-checkbox";
import ReportCard from "components/cards/reportCard/reportCard";
import { DatePicker } from "components/datePicker/datePicker";
import { Button } from "components/ui/button";
import React, { useState } from "react";
import Layout from "../../layout";
import { generateAchReport } from "api/reportscontroller";
import SelectMerchantId from "components/selectMerchantId/selectMerchantId";

const AchReports = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [transactions, setTransactions] = useState<any>(null);
  const [checkedTransactions, setCheckedTransactions] = useState<any>();
  const [merchantId, setMerchantId] = useState<any>();

  const baseUrl = process.env.REACT_APP_API_URL;

  const generateReport = async () => {
    const starteDateString = formatDate(startDate.toISOString());
    const endDateString = formatDate(endDate.toISOString());

    try {
      const response = await generateAchReport(
        starteDateString,
        endDateString,
        merchantId
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = (transactionId: number) => {
    if (checkedTransactions?.includes(transactionId)) {
      setCheckedTransactions(
        checkedTransactions?.filter((id: number) => id !== transactionId)
      );
    } else {
      setCheckedTransactions([...(checkedTransactions || []), transactionId]);
    }
  };

  return (
    <div className="flex flex-col flex-grow dark:bg-black dark:text-white p-3">
      <div className="flex-grow rounded-lg bg-[#212632]">
        <div className="flex flex-col lg:flex-row items-center justify-between border-b border-b-slate-500 p-4">
          <h2 className="py-[6px] text-xl text-center lg:text-left">
            Manage your
            <span className="font-bold text-yellow-500"> Ach Reports</span>
          </h2>
          <div className="flex flex-wrap mt-2 lg:mt-0 items-center gap-4 justify-center lg:justify-end">
            <SelectMerchantId
              onValueChange={(value: number) => {
                console.log(value);
                setMerchantId(value);
              }}
            />
            <DatePicker setDate={setStartDate} date={startDate} />
            <DatePicker setDate={setEndDate} date={endDate} />
            <Button
              variant={"secondary"}
              className="border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white"
              onClick={generateReport}
            >
              Generate Report
            </Button>
          </div>
        </div>

        {/* Display transactions */}
        <div className="mt-4 p-4 pb-12">
          {!transactions ? (
            <h2 className="text-center text-2xl lg:text-4xl text-white">
              Start by generating a report
            </h2>
          ) : transactions.length === 0 ? (
            <h2 className="text-center text-2xl lg:text-4xl text-white">
              No transactions found
            </h2>
          ) : (
            <>
              {transactions?.map((transaction: any) => (
                <div
                  key={transaction.id}
                  className="mb-5 flex flex-col md:flex-row items-start md:items-center gap-4"
                >
                  <Checkbox
                    onCheckedChange={() => handleCheckboxChange(transaction.id)}
                  />
                  <ReportCard report={transaction} />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const formatDate = (date: string) => {
  return new Date(date).toISOString().slice(0, 10);
};

export default AchReports;
