import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { TaskStatus } from "api/transactions";
import { useSelector } from "react-redux";
import { UserState } from "store/userStore";
import { Roles } from "roles/roles";
interface StatusSelectProps {
  currentStatus: TaskStatus;
  onChange: (value: TaskStatus) => void;
  disabled?: boolean;
}

const StatusSelect: React.FC<StatusSelectProps> = ({
  currentStatus,
  onChange,
  disabled = false,
}) => {
  const user = useSelector((state: UserState) => state.user.user);

  let statuses: TaskStatus[] = [];

  if (user?.role === Roles.MASTER_ADMIN) {
    statuses = ["PROCESSED", "CANCELED", "PENDING"];
  } else {
    statuses = ["PROCESSED"];
  }

  const filteredStatuses = statuses.filter(
    (status) => status !== currentStatus
  );

  return (
    <Select
      disabled={currentStatus === "PROCESSED" || disabled}
      onValueChange={onChange}
    >
      <SelectTrigger className="w-full bg-yellow-500 text-black">
        <SelectValue placeholder={mapStatusToString(currentStatus)} />
      </SelectTrigger>
      <SelectContent>
        {filteredStatuses?.map((status) => (
          <SelectItem key={status} value={status}>
            {mapStatusToString(status)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

const mapStatusToString = (status: TaskStatus) => {
  switch (status) {
    case "NEW":
      return "New";
    case "IN_PROGRESS":
      return "In Progress";
    case "DONE":
      return "Done";
    case "CANCELED":
      return "Canceled";
    case "PROCESSED":
      return "Confirmed";
    case "PENDING":
      return "Pending";
    default:
      return "Unknown";
  }
};

export default StatusSelect;
