
import CreatePaymentForm from "components/forms/createPaymentForm/createPaymentForm";

const CreatePayment = () => {
  return (
    <div className="flex flex-grow dark:bg-black dark:text-white">
      <div className="flex flex-grow p-4 pb-0">
        <div className=" flex-grow rounded-lg bg-[#212632]">
          {/* TOPBAR  */}
          <div className="flex items-center justify-between border-b border-b-slate-500 p-4">
            <div className="flex items-center gap-2">
              <h2 className="py-[6px] text-xl">Create a new payment</h2>
            </div>
          </div>
          {/* Content */}
          <div className="m-auto mt-6">
            <CreatePaymentForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePayment;
