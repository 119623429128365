import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { beautifyNumber } from "lib/utils";
import { getTopUpById, getDownloadTopUpReceipt } from "api/cryptoController";
import { useToast } from "components/ui/use-toast";
import { Button } from "components/ui/button";
import { formatNumberAsDigit } from "lib/utils";

const TopUpDetails = ({ transaction }: any) => {
  const [topUpData, setTopUpData] = useState<any>();
  const navigate = useNavigate();
  const { toast } = useToast();
  const user = useSelector((state: any) => state.user.user);

  const getTopUp = async () => {
    try {
      await getTopUpById(transaction?.id).then((res) => {
        setTopUpData(res?.data);
      });
    } catch (Err) {
      console.log(Err);
    }
  };

  const handleDownload = async () => {
    try {
      await getDownloadTopUpReceipt(transaction?.id);
      toast({
        title: `Receipt downloaded successfully!`,
      });
    } catch (error) {
      toast({
        title: `ERROR: Failed to download document!`,
      });
    }
  };

  useEffect(() => {
    getTopUp();
  }, []);

  return (
    <div className="m-auto mt-12 max-w-sm">
      <div className="space-y-8">
        <div className="flex flex-col justify-between gap-6">
          {/* Display Top-up Amount */}
          <div>
            <span>Amount ({topUpData?.currency})</span>
            <div className="flex h-10 w-[280px] lg:w-[350px] rounded-md border border-input bg-background px-3 py-2 text-right text-sm text-xl ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
              {formatNumberAsDigit(topUpData?.amount)}{" "}
            </div>
          </div>

          {/* Display Transaction Hash or Download Button */}
          {topUpData?.transactionHash ? (
            <div>
              <span>Transaction Hash</span>
              <div className="flex h-10 w-[280px] lg:w-[350px] rounded-md border border-input bg-background px-3 py-2 text-right text-sm text-xl ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
                {topUpData?.transactionHash}
              </div>
            </div>
          ) : (
            <div>
              <span>Top Up Receipt</span>
              <Button
                type="button"
                variant="outline"
                onClick={handleDownload}
                className="w-[280px] lg:w-[350px] text-right text-xl"
              >
                Download
              </Button>
            </div>
          )}

    
          <div>
            <span>Amount in Fiat</span>
            <div className="flex h-10 w-[280px] lg:w-[350px] rounded-md border border-input bg-background px-3 py-2 text-right text-sm text-xl ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
              {formatNumberAsDigit(topUpData?.fiatAmount)}
            </div>
          </div>

        
          <div>
            <span>Exchange Rate</span>
            <div className="flex h-10 w-[280px] lg:w-[350px] rounded-md border border-input bg-background px-3 py-2 text-right text-sm text-xl ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
              {formatNumberAsDigit(topUpData?.exchangeRate)}
            </div>
          </div>

     
          <div>
            <span>Merchant Amount Received</span>
            <div className="flex h-10 w-[280px] lg:w-[350px] rounded-md border border-input bg-background px-3 py-2 text-right text-sm text-xl ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
              {formatNumberAsDigit(topUpData?.merchantAmountReceive)}
            </div>
          </div>

       
          <div>
            <span>Provider Fee (%)</span>
            <div className="flex h-10 w-[280px] lg:w-[350px] rounded-md border border-input bg-background px-3 py-2 text-right text-sm text-xl ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
              {formatNumberAsDigit(topUpData?.providerFees)}
            </div>
          </div>

          
          <div>
            <span>Status</span>
            <div className="flex h-10 w-[280px] lg:w-[350px] rounded-md border border-input bg-background px-3 py-2 text-right text-sm text-xl ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
              {topUpData?.status}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopUpDetails;
