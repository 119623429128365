import { Button } from "components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "components/ui/dialog";
import React, { Dispatch, SetStateAction, useState } from "react";
import { createCryptoConversionSettlement } from "api/cryptoController";
import { useForm } from "react-hook-form";
import { z } from "zod";
import axios from "axios";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "components/ui/input";
import { Textarea } from "components/ui/textarea";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { NumericFormat } from "react-number-format";
import { formatStringDigitToNumber } from "lib/utils";
interface CreateNewCryptoConversionDialogProps {
  conversionId: string;
  refreshSettlements: Dispatch<SetStateAction<boolean>>;
}

const CreateNewSettlementDialog: React.FC<
  CreateNewCryptoConversionDialogProps
> = ({ conversionId, refreshSettlements }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const closeModal = () => {
    setIsOpen(false);
    reset();
    setErrorMessage("");
  };

  const formSchema = z.object({
    amount: z.string().min(1).max(50),
    description: z.string(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      amount: "",
      description: "",
    },
  });
  const { reset } = form;

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const cryptoSettlement = {
      tradeTransactionId: +conversionId,
      amount: +formatStringDigitToNumber(values?.amount),
      description: values?.description,
    };
    try {
      await createCryptoConversionSettlement(cryptoSettlement).then((res) => {
        if (res) {
          closeModal();
          refreshSettlements((prev) => !prev);
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorDetails = error.response?.data?.details;
        setErrorMessage(
          `ERROR: Failed to create settlement!  ${errorDetails || error.message}`
        );
      } else {
        setErrorMessage(
          "An unexpected error occurred. Please try again later."
        );
      }
    }
  }

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(isOpen) => {
        setIsOpen(isOpen);
        if (!isOpen) {
          closeModal();
        }
      }}
    >
      <DialogTrigger>
        <Button
          variant={"secondary"}
          className="h-fit w-fit bg-yellow-500 p-2 font-bold text-black hover:border-yellow-500 hover:text-white"
          onClick={() => setIsOpen(true)}
        >
          Add settlement
        </Button>
      </DialogTrigger>
      <DialogContent className="dark max-h-[80vh] w-full overflow-auto dark:text-white ">
        <Form {...form}>
          <div className="flex justify-center">
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <div className="flex flex-col justify-between gap-6">
                <FormField
                  control={form.control}
                  name="amount"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="flex items-center gap-4">
                        Amount in Fiat
                      </FormLabel>
                      <FormControl>
                        <NumericFormat
                          {...field}
                          customInput={Input}
                          thousandSeparator
                          decimalScale={3}
                          allowNegative={false}
                          className="w-[280px] lg:w-[350px] text-right text-xl"
                          placeholder="Amount in Fiat"
                          onValueChange={(values) => {
                            field.onChange(+values.value);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex flex-col justify-between gap-6">
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Description</FormLabel>
                      <FormControl>
                        <div className="flex items-center gap-4">
                          <Textarea
                            className="w-[280px] lg:w-[350px] text-xl"
                            {...field}
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="text-red-500">{errorMessage}</div>
              <div className={`flex pb-12`}>
                <Button
                  variant={"secondary"}
                  className="w-[280px] lg:w-[350px] border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white"
                >
                  Save settlement{" "}
                </Button>
              </div>
            </form>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateNewSettlementDialog;
