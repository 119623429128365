import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";

const SelectPaymentStatus = ({ currentStatus, onChange }: any) => {

  return (
    <Select onValueChange={onChange}>
      <SelectTrigger className="w-full bg-yellow-500 text-black">
        <SelectValue placeholder={mapStatusToString(currentStatus)} />
      </SelectTrigger>
      <SelectContent>
        {/* <SelectItem value="NEW">New</SelectItem> */}
        <SelectItem value="IN_PROGRESS">In Progress</SelectItem>
        <SelectItem value="PENDING">Pending</SelectItem>
        <SelectItem value="COMPLETE">Complete</SelectItem>
      </SelectContent>
    </Select>
  );
};

const mapStatusToString = (status: string) => {
  switch (status) {
    case "NEW":
      return "New";
    case "PENDING":
      return "Pending";
    case "IN_PROGRESS":
      return "In Progress";
    case "COMPLETE":
      return "Complete";
    default:
      return "N/A";
  }
};

export default SelectPaymentStatus;
