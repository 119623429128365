import React, { useState } from "react";
import { Button } from "components/ui/button";
import { NavigationButtonsData } from "interfaces/navigationButtonsListInterface";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";

// Define the BalanceType
type BalanceType = "ZELLE" | "CRYPTO" | "ACH" | "WIRE_TRANSFER";

// Define the interface for props
interface NavigationButtonsProps {
  setValue: (value: any) => void;
  buttonsList: NavigationButtonsData;
  value: any;
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  setValue,
  buttonsList,
  value,
}) => {
  const [buttonValue, setButtonValue] = useState<any>(
    value as any
  );

  const handleButtonValue = (newValue: any) => {
    setButtonValue(newValue);
    setValue(newValue);
  };

  return (
    <div className="flex gap-2">
      <div className="hidden md:flex gap-2 p-1">
        {buttonsList?.map((item, index) => (
          <Button
            key={index}
            variant="secondary"
            onClick={() => handleButtonValue(item?.buttonValue)}
            className={
              item?.buttonValue === buttonValue
                ? "bg-yellow-500 hover:bg-yellow-500"
                : "bg-slate-500"
            }
          >
            {item?.buttonName}
          </Button>
        ))}
      </div>

      <div className="block md:hidden w-full">
        <Select onValueChange={handleButtonValue}>
          <SelectTrigger className="w-[150px]">
            <SelectValue
              style={{ textDecorationColor: "black" }}
              placeholder={value}
            />
          </SelectTrigger>
          <SelectContent>
            {buttonsList?.map((item, index) => (
              <SelectItem key={index} value={item?.buttonValue}>
                {item?.buttonName}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};

export default NavigationButtons;
