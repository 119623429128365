"use client";
import { zodResolver } from "@hookform/resolvers/zod";
import { login } from "api/auth";
import { useForm } from "react-hook-form";
// @ts-ignore
import { useToast } from "components/ui/use-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setAccessToken,
  setIsLoggedIn,
  setMerchant,
  setUser,
} from "store/userSlice";
import useUserStore from "store/userStore";
import { z } from "zod";
import { Button } from "../../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { Toaster } from "components/ui/toaster";
import { getUserProfile } from "api/agents";
import { getMerchantById } from "api/merchant";

const formSchema = z.object({
  usernameOrEmail: z.string().min(1).max(50),
  password: z.string().min(1).max(50),
});

const LoginForm = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setIsLoggedIn2 = useUserStore((state: any) => state.setIsLoggedIn);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      usernameOrEmail: "",
      password: "",
    },
  });

  const fetchAndSetUser = async () => {
    try {
      const response = await getUserProfile();
      dispatch(setUser(response));
      if (response?.role !== "MASTER_ADMIN") {
        const merchantResponse = await getMerchantById(response?.merchantId);
        dispatch(setMerchant(merchantResponse));
      }
    } catch (error) {
      // Handle errors
    }
  };
  fetchAndSetUser();

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      const response = await login(values);
      dispatch(setAccessToken(response));
      dispatch(setIsLoggedIn(true));
      setIsLoggedIn2(true);

      fetchAndSetUser();
      navigate("/home");
    } catch (error) {
      console.log(error);
      toast({
        title: "Login failed. Please try again.",
      });
    }
  };
  return (
    <div className="dark container max-w-96">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <FormField
            control={form.control}
            name="usernameOrEmail"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="dark:text-white">
                  Username or Email
                </FormLabel>
                <FormControl>
                  <Input
                    className="dark:text-white"
                    placeholder="Username or Email"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="dark:text-white">Password</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    className="dark:text-white"
                    placeholder="•••••••••"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex justify-center">
            <Button
              className="w-[200px] bg-yellow-500 font-bold duration-300"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </form>
      </Form>
     
    </div>
  );
};

export default LoginForm;
