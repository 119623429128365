import Footer from "components/shared/footer/footer";
import MainContainer from "components/shared/entityContainer/entityContainer";
import Sidebar from "components/shared/sidebar/sidebar";
import Topbar from "components/shared/topbar/topbar";
import React from "react";
import { useToast } from "components/ui/use-toast";
import { Toaster } from "components/ui/toaster";
import ChatBubble from "components/shared/chatBubble/chatBubble";

interface LayoutProps {
  children: React.ReactNode; // Define the type for children prop
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="dark flex min-h-screen flex-col">
      <div className="dark flex flex-grow">
        <div className="hidden lg:flex">
          <Sidebar />
        </div>
        <div className="flex w-full flex-grow flex-col mb-2">
          <Topbar />
          <>
          {children}
          </>
          <ChatBubble />
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
