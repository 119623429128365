import { REST } from "./axiosConfig";

export const getNotifications = async (pageNumber?: number) => {
  console.log(pageNumber);
  try {
    if (pageNumber !== undefined && pageNumber !== null) {
      const response = await REST.get(
        `/notifications/~?page=${pageNumber}&size=10`
      );
      return response.data;
    } else {
      const response = await REST.get(`/notifications/~`);
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const readNotification = async (id: number) => {
  try {
    const response = await REST.put(`/notifications/${id}/read`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
