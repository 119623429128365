"use client";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Input } from "components/ui/input";
import { useEffect, useState } from "react";
import { getExchangeCompanies, getProcessingCompanies } from "api/management";
import { useDispatch, useSelector } from "react-redux";
import { resetForm, selectForm, setForm } from "store/formSlice";
import { RootState } from "store";
import { REST } from "api/axiosConfig";
import { createMerchant } from "api/merchant";

const formSchema = z.object({
  processCompanyId: z.string(),
  exchangeId: z.string(),
});

const StepOneMerchantForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [processingCompanies, setProcessingCompanies] = useState([]);
  const [exchangeCompanies, setExchangeCompanies] = useState([]);

  const formState = useSelector((state: RootState) => selectForm(state));

  useEffect(() => {
    const fetchProcessingCompanies = async () => {
      try {
        const response = await getProcessingCompanies();
        setProcessingCompanies(response);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchExchangeCompanies = async () => {
      try {
        const response = await getExchangeCompanies();
        setExchangeCompanies(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchProcessingCompanies();
    fetchExchangeCompanies();
  }, []);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      processCompanyId: "",
      exchangeId: "",
    },
  });

  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof formSchema>) {
    const newValues = {
      ...formState,
      processCompanyId: parseInt(values.processCompanyId),
      exchangeId: parseInt(values.exchangeId),
    };
    try {
      const response = await createMerchant(newValues);
      dispatch(resetForm());
      navigate("/home/merchants");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="m-auto mt-12 max-w-sm">
      <Form {...form}>
        <div className="flex justify-center">
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="processCompanyId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Company Process</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Processing company" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {processingCompanies.map((company: any) => (
                        <SelectItem
                          key={company.id}
                          value={company.id.toString()}
                        >
                          {company.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>

                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="exchangeId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Exchange</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Exchange company" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {exchangeCompanies.map((company: any) => (
                        <SelectItem
                          key={company.id}
                          value={company.id.toString()}
                        >
                          {company.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>

                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              variant={"secondary"}
              className="w-[280px] lg:w-[350px] border bg-red-700 font-bold text-white hover:border-yellow-500 hover:text-white"
            >
              Save{" "}
            </Button>
          </form>
        </div>
      </Form>
    </div>
  );
};

export default StepOneMerchantForm;
