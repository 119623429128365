import store from "store";
import { REST } from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_URL;

export const getAgents = async (pageSize: number, currentPage: number) => {
  const state = store.getState() as any;
  const user = state.user?.user;
  let url = "";

  if (user?.role === "MERCHANT_ADMIN" || user?.role === "MERCHANT_USER") {
    url = `/user/${user?.merchantId}/~?page=${currentPage}&size=${pageSize}`;
  } else {
    url = `/user/~?page=${currentPage}&size=${pageSize}`;
  }

  try {
    const response = await REST.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAgentsPerMerchantId = async (merchantId: number) => {
  try {
    const response = await REST.get(
      `/user/{merchantId}/~?merchantId=${merchantId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserProfile = async () => {
  try {
    const response = await REST.get(`${API_URL}/user/getUserProfile`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (data: any) => {
  try {
    const response = await REST.put(`user/update`, data);
    return response
  } catch (error) {
    throw error;
  }
};
