import { Link } from 'react-router-dom'
import { Button } from '../../../../src/components/ui/button'
import LoginForm from '../../../../src/components/forms/loginForm/loginForm'
import vaultLogo from 'images/vaultLogo.png'

export default function Login() {
  return (
    <div className='dark flex h-screen w-full flex-col items-center justify-center'>
      <img src={vaultLogo} alt='Merchant Logo' />

      <LoginForm />
    </div>
  )
}
