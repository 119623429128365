import { Client, IMessage } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

interface Subscriber {
  destination: string;
  callback: (message: IMessage) => void;
}

class StompManager {
  private url: string;
  private client: Client | null = null;
  private subscribers: Subscriber[] = [];

  constructor(url: string) {
    this.url = url;
  }

  connect() {
    const socket = new SockJS(this.url);

    this.client = new Client({
      webSocketFactory: () => socket as WebSocket,
      onConnect: () => {
        console.log('STOMP connection established');
        this.subscribers.forEach(({ destination, callback }) => {
          this.client?.subscribe(destination, callback);
        });
      },
      onDisconnect: () => {
        console.log('STOMP connection closed');
      },
      onStompError: (frame) => {
        console.error('STOMP error:', frame);
      },
    });

    this.client.activate();
  }

  subscribe(destination: string, callback: (message: IMessage) => void) {
    if (this.client && this.client.connected) {
      this.client.subscribe(destination, callback);
    } else {
      this.subscribers.push({ destination, callback });
    }
  }

//   unsubscribe(destination: string, callback: (message: IMessage) => void) {
//     if (this.client && this.client.connected) {
//       // Find and unsubscribe the specific subscription
//       const subscription = this.client.subscriptions[destination];
//       if (subscription) {
//         subscription.unsubscribe();
//       }
//     } else {
//       this.subscribers = this.subscribers.filter(
//         sub => sub.destination !== destination || sub.callback !== callback
//       );
//     }
//   }
}

const stompManager = new StompManager('https://payments.realgrowsoft.com/websocket');
export default stompManager;