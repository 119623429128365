import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

interface RequiureRoleProps {
  allowedRoles: string[];
}

const RequireRole: React.FC<RequiureRoleProps> = ({ allowedRoles }) => {
  const location = useLocation();
  const user = useSelector((state: any) => state?.user?.user);

  if (user?.role) {
    return (
      <>
        {allowedRoles?.includes(user?.role) ? (
          <Outlet />
        ) : (
          <Navigate to={"/403"} state={{ from: location }} replace />
        )}
      </>
    );
  } else {
    return <Navigate to={"/login"} state={{ from: location }} replace />;
  }
};

export default RequireRole;
