'use client'
import React from 'react'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { Button } from '../../ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '../../ui/form'
import { useMutation } from '@tanstack/react-query'
import { Input } from 'components/ui/input'
import { createMerchant } from 'api/merchant'

const CreateMerchantForm = ({ item = null }: { item: any }) => {
  const formSchema = z.object({
    name: z.string().min(1).max(50),
    email: z.string().email(),
    username: z.string().min(1).max(50),
    password: z.string().min(1).max(50),
    host: z.string().min(1).max(50),
    port: z.preprocess(a => parseInt(z.string().parse(a), 10), z.number()),
    protocol: z.string().min(1).max(50)
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: item?.name || '',
      username: item?.username || '',
      password: item?.password || '',
      email: item?.email || '',
      host: item?.host || '',
      port: item?.port || NaN,
      protocol: item?.protocol || ''
    }
  })

  const createMerchantMutation = useMutation({
    mutationFn: async (values: z.infer<typeof formSchema>) => {
      const response = await createMerchant(values)
    },
    onSuccess: data => {
      form.reset()
    },
    onError: () => {
      form.setError('protocol', { message: 'Error, try again' })
    }
  })

  const onSubmit = async (values: any) => {
    try {

      
      await createMerchant(values)
      form.reset()
    } catch (error) {



    }
  }


  return (
    <Form {...form}>
      <form onSubmit={form?.handleSubmit(onSubmit)}>
        <FormField
          control={form?.control}
          name='name'
          render={({ field }) => (
            <FormItem>
              <FormLabel className='dark:text-white'>Name</FormLabel>
              <FormControl>
                <Input
                  className='dark:text-white'
                  placeholder='Name'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form?.control}
          name='username'
          render={({ field }) => (
            <FormItem>
              <FormLabel className='dark:text-white'>Username</FormLabel>
              <FormControl>
                <Input
                  className='dark:text-white'
                  placeholder='Username'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form?.control}
          name='password'
          render={({ field }) => (
            <FormItem>
              <FormLabel className='dark:text-white'>Password</FormLabel>
              <FormControl>
                <Input
                  className='dark:text-white'
                  placeholder='Password'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form?.control}
          name='email'
          render={({ field }) => (
            <FormItem>
              <FormLabel className='dark:text-white'>Email</FormLabel>
              <FormControl>
                <Input
                  className='dark:text-white'
                  placeholder='Email'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form?.control}
          name='host'
          render={({ field }) => (
            <FormItem>
              <FormLabel className='dark:text-white'>Host</FormLabel>
              <FormControl>
                <Input
                  className='dark:text-white'
                  placeholder='Host'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form?.control}
          name='port'
          render={({ field }) => (
            <FormItem>
              <FormLabel className='dark:text-white'>Port</FormLabel>
              <FormControl>
                <Input
                  type='number'
                  className='dark:text-white'
                  placeholder='Port'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form?.control}
          name='protocol'
          render={({ field }) => (
            <FormItem>
              <FormLabel className='dark:text-white'>Protocol</FormLabel>
              <FormControl>
                <Input
                  className='dark:text-white'
                  placeholder='Protocol'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div>
          <Button className='mt-4' type='submit'>
            {createMerchantMutation.isError
              ? 'Try Again'
              : createMerchantMutation.isSuccess
                ? 'Created'
                : 'Submit'}
          </Button>
        </div>
      </form>
    </Form>
  )
}

export default CreateMerchantForm
