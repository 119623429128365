import {
  IconLoader,
  IconPackageImport,
  IconPigMoney,
  IconWindowMaximize,
} from "@tabler/icons-react";
import { getCryptoByStatus } from "api/cryptoController";
import { Button } from "components/ui/button";
import {
  beautifyNumber,
  returnMerchantNameFromMerchantId,
  returnTopUpOrRequest,
} from "lib/utils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "components/shared/buttons/primaryButton/primaryButton";
import TablePagination from "components/shared/tablePagination/tablePagination";
import { Separator } from "components/ui/separator";
import { getAllMerchants } from "api/merchant";
import { formatNumberAsDigit } from "lib/utils";
import { NavigationButtonsData } from "interfaces/navigationButtonsListInterface";
import NavigationButtons from "components/shared/buttons/buttonsList/navigationButtonsList";
import ResponsiveTableWithPagination from "components/shared/customTableComponent/ResponsiveTableWithPagination";
import { Roles } from "roles/roles";
import ExcelReport from "components/excelReport/excelReport";
import SelectMerchantId from "components/selectMerchantId/selectMerchantId";
const CryptoBalance = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const user = useSelector((state: any) => state.user.user);
  const [merchantId, setMerchantId] = useState<number>(
    user?.user?.role === Roles?.MASTER_ADMIN
      ? queryParams.get("merchantId")
      : user?.merchant?.id
  );
  const [merchants, setMerchants] = useState<any>([]);
  const [transactions, setTransactions] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState<any>(
    queryParams.get("status") ? queryParams.get("status") : "NEW"
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const buttonsList: NavigationButtonsData = [
    { buttonName: "New", buttonType: "secondary", buttonValue: "NEW" },
    {
      buttonName: "In Progress",
      buttonType: "secondary",
      buttonValue: "IN_PROGRESS",
    },
    {
      buttonName: "Pending",
      buttonType: "secondary",
      buttonValue: "PENDING",
    },
    {
      buttonName: "Complete",
      buttonType: "secondary",
      buttonValue: "COMPLETE",
    },
  ];

  const columns: any[] = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Transaction Type",
      accessor: "transactionType",
      Cell: ({ value }: { value: string }) => returnTopUpOrRequest(value),
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ value }: { value: number }) => formatNumberAsDigit(value),
    },
    {
      Header: "Currency",
      accessor: "currency",
    },
    {
      Header: "Exchange Rate",
      accessor: "exchangeRate",
      Cell: ({ value }: { value: number }) => formatNumberAsDigit(value),
    },
    {
      Header: "Fiat Amount",
      accessor: "fiatAmount",
      Cell: ({ value }: { value: number }) => formatNumberAsDigit(value),
    },
    {
      Header: "Merchant",
      accessor: "merchantId",
      Cell: ({ value }: { value: number }) =>
        returnMerchantNameFromMerchantId(value, merchants?.content),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }: { value: string }) => returnStatusAsString(value),
    },
    {
      Header: "Actions",
      accessor: "action",
      Cell: ({ row }: any) => returnViewButtonForTransaction(row?.original),
    },
  ];

  useEffect(() => {
    const fetchAllTransactionsByStatus = async (status: string) => {
      setIsLoading(true);
      try {
        const response = await getCryptoByStatus(status, currentPage);
        setTransactions(response);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchAllMertchants = async () => {
      try {
        await getAllMerchants().then((res) => {
          setMerchants(res);
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchAllMertchants();
    fetchAllTransactionsByStatus(selectedStatus);
  }, [selectedStatus, currentPage]);

  const returnStatusAsString = (status: string) => {
    switch (status) {
      case "NEW":
        return "New";
      case "PENDING":
        return "Pending";
      case "IN_PROGRESS":
        return "In Progress";
      case "COMPLETE":
        return "Complete";
      default:
        return "N/A";
    }
  };

  const handleStatusTypeChange = (type: any) => {
    setSelectedStatus(type);
    navigate(`/home/crypto-balance?status=${type}&merchantId=${merchantId}`);
    setCurrentPage(0);
  };

  const handleMerchantId = (value: number) => {
    setMerchantId(value);
    navigate(
      `/home/crypto-balance?status=${selectedStatus}&merchantId=${value}`
    );
  };
  

  const returnViewButtonForTransaction = (transaction: any) => {
    const navigateTo =
      transaction.status !== "COMPLETE"
        ? "/home/crypto-balance/top-up/process"
        : "/home/crypto-balance/top-up/view";

    const IconComponent =
      transaction.status === "NEW" ? IconPackageImport : IconWindowMaximize;

    return (
      <div
        onClick={() =>
          navigate(navigateTo, {
            state: { transaction },
          })
        }
      >
        <PrimaryButton>
          <IconComponent /> View
        </PrimaryButton>
      </div>
    );
  };

  const TransactionSection = () => (
    <div className="mt-4 flex flex-grow flex-col justify-between pb-8">
      {isLoading ? (
        <IconLoader size={72} className="m-auto mt-14 animate-spin" />
      ) : (
        <ResponsiveTableWithPagination
          columns={columns}
          data={transactions}
          handlePageChange={setCurrentPage}
        />
      )}
    </div>
  );

  return (
    <div className="flex flex-grow dark:bg-black dark:text-white">
      <div className="flex flex-grow p-4 pb-0">
        <div className=" flex flex-grow flex-col rounded-lg bg-[#212632]">
          {/* TOPBAR  */}
          <div className="flex items-center justify-between border-b border-b-slate-500 p-4 hidden md:flex">
            <div className="flex items-center gap-4">
              <h2 className="py-[6px] text-xl">
                See all your crypto transactions listed here
              </h2>
            </div>
          </div>

          <div className="flex items-center flex-col justify-between p-3 md:flex-row justify-center gap-2">
            <ExcelReport reportType="CRYPTO" handleMerchantId={handleMerchantId} />

            <div className="flex flex-row items-center md:flex-row">
              <div className="flex justify-center md:justify-end p-2">
                <NavigationButtons
                  buttonsList={buttonsList}
                  value={selectedStatus}
                  setValue={handleStatusTypeChange}
                />
              </div>
            </div>
          </div>

          <TransactionSection />
        </div>
      </div>
    </div>
  );
};

export default CryptoBalance;
