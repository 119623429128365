import { getAllMerchants, getMerchantById, getMerchants } from "api/merchant";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useUserStore, { UserState } from "store/userStore";
import { Roles } from "roles/roles";

const SelectMerchantId = ({ onValueChange, defaultValue }: any) => {
  const [merchantList, setMerchantList] = useState<any>(null);
  const user = useSelector((state: UserState) => state?.user?.user);

  useEffect(() => {
    const retrieveMerchantList = async () => {
      let response: any;
      if (user?.role === Roles.MASTER_ADMIN) {
        response = await getAllMerchants();
      } else {
        let apiResp = await getMerchantById(user?.merchantId);
        response = new Array(apiResp);
      }
      setMerchantList(response?.content);
    };

    retrieveMerchantList();
  }, []);
  return (
    <Select
      onValueChange={(value) => onValueChange(value === "null" ? null : value)}
      defaultValue={defaultValue?.toString()}
    >
      <SelectTrigger className="w-[150px]">
        <SelectValue placeholder="Select Merchant" />
      </SelectTrigger>
      <SelectContent>
      <SelectItem value="null"><i>None</i></SelectItem>
        {merchantList &&
          merchantList.map((merchant: any) => (
            <SelectItem key={merchant.id} value={merchant?.id?.toString()}>
              {merchant?.name}
            </SelectItem>
          ))}
      </SelectContent>
    </Select>
  );
};

export default SelectMerchantId;
