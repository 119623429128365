import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage

const initialState = {
  user: null,
  accessToken: null,
  isLoggedIn: false,
  merchant: null
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload
    },
    setMerchant: (state, action) => {
      state.merchant = action.payload
    },
    logout: state => {
      state.user = null
      state.accessToken = null
      state.isLoggedIn = false
    }
  }
})

export const { setUser, setAccessToken, setIsLoggedIn, logout, setMerchant } =
  userSlice.actions

const persistConfig = {
  key: 'user', // The key for the persisted data in localStorage
  storage // The storage to use (localStorage in this case)
}

export const selectUser = (state: any) => state.user.user

const persistedReducer = persistReducer(persistConfig, userSlice.reducer)

export default persistedReducer
