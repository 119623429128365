import EntityContainer from "components/shared/entityContainer/entityContainer";
import Layout from "../layout";
import PrimaryButton from "components/shared/buttons/primaryButton/primaryButton";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getMerchants } from "api/merchant";
import {
  IconEdit,
  IconEye,
  IconLoader,
  IconMessage,
  IconTrash,
} from "@tabler/icons-react";
import EntityTable from "components/entityTable/entityTable";
import SearchForm from "components/forms/searchForm/searchForm";
import EntityCardsContainer from "components/shared/entityCardsContainer/entityCardsContainer";
import TablePagination from "components/shared/tablePagination/tablePagination";
import { Entity } from "types/types";
import { Button } from "components/ui/button";
import ResponsiveTableWithPagination from "components/shared/customTableComponent/ResponsiveTableWithPagination";
const Merchants = () => {
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>();

  const navigate = useNavigate();

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
  };

  const handlePageChange = async (newPage: number) => {
    setCurrentPage(newPage);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await getMerchants(pageSize, currentPage);
        setData(response);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [currentPage, pageSize]);

  const columns: any[] = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }: any) => (
        <Button
          onClick={() =>
            navigate(`/home/merchants/edit?step=1`, {
              state: { merchant: row.original },
            })
          }
          className="bg-yellow-500 px-4 py-2 rounded-md"
        >
          <IconEdit className="mr-2" size={14} /> Edit
        </Button>
      ),
    },
  ];

  const MerchantsTable = () => (
    <div>
      {isLoading ? (
        <IconLoader size={72} className="m-auto mt-14 animate-spin" />
      ) : (
        <ResponsiveTableWithPagination
          columns={columns}
          data={data}
          handlePageChange={setCurrentPage}
        />
      )}
    </div>
  );

  return (
    <div className="flex flex-grow dark:bg-black dark:text-white">
      <div className="flex flex-grow p-4 pb-0">
        <div className="flex flex-col flex-grow rounded-lg bg-[#212632]">
          {/* TOPBAR  */}
          <div className="flex flex-col lg:flex-row items-center justify-between border-b border-b-slate-500 p-4">
            <div className="flex items-center gap-4">
              <h2 className="py-[6px] text-xl mb-4 lg:mb-0">
                View, edit, or delete your{" "}
                <span className="font-bold text-yellow-500">Merchants</span>
              </h2>
            </div>

            <PrimaryButton
              onClick={() => navigate("/home/merchants/create?step=1")}
            >
              Create New
            </PrimaryButton>
          </div>

          {/* Content */}
          <MerchantsTable />
        </div>
      </div>
    </div>
  );
};

export default Merchants;
