import { useEffect, useState } from "react";
import Layout from "../../layout";
import {
  getPayments,
  getPaymentsDownloadReceipt,
} from "api/paymentsController";
import PrimaryButton from "components/shared/buttons/primaryButton/primaryButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IconDownload, IconLoader, IconUpload } from "@tabler/icons-react";
import { Button } from "components/ui/button";
import { beautifyNumber, formatDate } from "lib/utils";
import TablePagination from "components/shared/tablePagination/tablePagination";
import UploadBulkInput from "components/customInputs/uploadBulkInput";
import { useHandleAxiosError } from "hooks/useAxiosHandlerError";
import { formatNumberAsDigit } from "lib/utils";
import { url } from "inspector";
import DownloadByPath from "components/utilComponents/downloadByPath";
import { NavigationButtonsData } from "interfaces/navigationButtonsListInterface";
import NavigationButtons from "components/shared/buttons/buttonsList/navigationButtonsList";
import ResponsiveTableWithPagination from "components/shared/customTableComponent/ResponsiveTableWithPagination";
import ExcelReport from "components/excelReport/excelReport";
const PaymentsMerchant = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentsData, setPaymentsData] = useState<any>([]);
  const [status, setStatus] = useState(
    queryParams?.get("status") ? queryParams?.get("status") : "NEW"
  );
  const [currentPage, setCurrentPage] = useState(0);
  const { handleAxiosErrors } = useHandleAxiosError();

  const columns: any[] = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Date",
      accessor: "createdAt",
      Cell: ({ value }: any) => formatDate(value),
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ value, row }: any) =>
        `${formatNumberAsDigit(value)} ${row.original?.amountCurrency}`,
    },
    {
      Header: "Actions",
      accessor: "receiptFileName",
      Cell: ({ value, row }: any) => (
        <>
          {value ? (
            <PrimaryButton onClick={() => handleDownload(row?.original)}>
              <IconDownload className="mr-2" /> Download Receipt
            </PrimaryButton>
          ) : null}
        </>
      ),
    },
  ];

  const buttonsList: NavigationButtonsData = [
    { buttonName: "New", buttonType: "secondary", buttonValue: "NEW" },
    {
      buttonName: "In Progress",
      buttonType: "secondary",
      buttonValue: "IN_PROGRESS",
    },
    {
      buttonName: "Pending",
      buttonType: "secondary",
      buttonValue: "PENDING",
    },
    {
      buttonName: "Complete",
      buttonType: "secondary",
      buttonValue: "COMPLETE",
    },
  ];

  useEffect(() => {
    fetchPayments();
  }, [status, currentPage]);

  const fetchPayments = async () => {
    try {
      setIsLoading(true);
      const response = await getPayments(status, currentPage);
      setPaymentsData(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusTypeChange = (type: any) => {
    setStatus(type);
    setCurrentPage(0);
    navigate(`/home/payments-merchant?status=${type}`);
  };

  const handleDownload = async (payment: any) => {
    try {
      await getPaymentsDownloadReceipt(payment?.id);
    } catch (error) {
      handleAxiosErrors({
        toastTitle: "Faild to download receipt!",
        error: error,
      });
    }
  };

  const PaymentsSection = () => (
    <div className="mt-4 flex flex-grow flex-col justify-between pb-8">
      {isLoading ? (
        <IconLoader size={72} className="m-auto mt-14 animate-spin" />
      ) : (
        <ResponsiveTableWithPagination
          columns={columns}
          data={paymentsData}
          handlePageChange={setCurrentPage}
        />
      )}
    </div>
  );

  

  return (
    <div className="flex flex-grow dark:bg-black dark:text-white">
      <div className="flex flex-grow p-4 pb-0">
        <div className="flex-grow flex flex-col rounded-lg bg-[#212632]">
          {/* TOPBAR  */}
          <div className="flex flex-col md:flex-row items-center justify-between border-b border-b-slate-500 p-4 ">
            <div className="flex items-center gap-4 mb-4 md:mb-0 hidden md:flex">
              <h2 className="py-[6px] text-xl text-center md:text-left">
                See all your payments listed here
              </h2>
            </div>
            <div className="flex flex-col md:flex-row gap-4 w-full md:w-auto">
              <PrimaryButton
                onClick={() => navigate("/home/payments/create")}
                className="w-full md:w-auto"
              >
                Create Payment Request
              </PrimaryButton>

              <DownloadByPath
                path="/assets/Batch_Template.xlsx"
                documentName="Batch_Template.xlsx"
                buttonName="Download template"
              />
              <div className="flex justify-center">
                <UploadBulkInput />
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-between p-2 gap-2 md:flex-row">
          <ExcelReport reportType="ACH"/>
          <div className="flex flex-col md:flex-row justify-center items-center md:justify-end p-2 gap-2">
            <NavigationButtons
              buttonsList={buttonsList}
              value={status}
              setValue={handleStatusTypeChange}
            />
          </div>

          </div>
        

          {/* Content */}
          <div className="flex flex-grow flex-col">
            <PaymentsSection />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentsMerchant;
