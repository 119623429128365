import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export interface UserState {
  user: any | null
  accessToken: string | null
  isLoggedIn: boolean | null

  setUser: (user: any) => void
  setAccessToken: (token: string) => void
  setIsLoggedIn: (isLoggedIn: boolean) => void
  logout: () => void
}

const useUserStore = create(
  persist(
    set => ({
      user: null,
      accessToken: null,
      isLoggedIn: false,

      setUser: (user: any) => set({ user: user }),
      setAccessToken: (token: string) => set({ accessToken: token }),
      setIsLoggedIn: (isLoggedIn: boolean) => set({ isLoggedIn: isLoggedIn }),

      logout: () => {
        set({ user: null, accessToken: null, isLoggedIn: false })
      }
    }),
    {
      name: 'user-storage',
      getStorage: () => sessionStorage
    }
  )
)

export default useUserStore
