import React, { useEffect, useState } from "react";
import Layout from "../layout";
import { getWithdrawals, putWithdrawalStatus } from "api/paymentsController";
import TablePagination from "components/shared/tablePagination/tablePagination";
import PrimaryButton from "components/shared/buttons/primaryButton/primaryButton";
import { Button } from "components/ui/button";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useSelector } from "react-redux";
import SelectWithdrawalStatus from "components/selectFields/selectWithdrawalStatus/selectWithdrawalStatus";
import { IconDownload, IconEdit, IconLoader } from "@tabler/icons-react";
import { getWithdrawalReceipt } from "api/withdrawalController";
import { useHandleAxiosError } from "hooks/useAxiosHandlerError";
import { formatNumberAsDigit } from "lib/utils";
import { Roles } from "roles/roles";
import { NavigationButtonsData } from "interfaces/navigationButtonsListInterface";
import NavigationButtons from "components/shared/buttons/buttonsList/navigationButtonsList";
import ResponsiveTableWithPagination from "components/shared/customTableComponent/ResponsiveTableWithPagination";
import ExcelReport from "components/excelReport/excelReport";
import SelectMerchantId from "components/selectMerchantId/selectMerchantId";
const Withdrawals = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user.user);
  const [isLoading, setIsLoading] = useState(true);
  const { handleAxiosErrors } = useHandleAxiosError();
  const [withdrawalsData, setWithdrawalsData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [withdrawalType, setWithdrawalType] = useState(
    queryParams.get("status") ? queryParams.get("status") : "NEW"
  );
  const [merchantId, setMerchantId] = useState<number>(
    user?.user?.role === Roles?.MASTER_ADMIN
      ? queryParams.get("merchantId")
      : user?.merchant?.id
  );

  const buttonsList: NavigationButtonsData = [
    { buttonName: "New", buttonType: "secondary", buttonValue: "NEW" },
    {
      buttonName: "In Progress",
      buttonType: "secondary",
      buttonValue: "IN_PROGRESS",
    },
    {
      buttonName: "Pending",
      buttonType: "secondary",
      buttonValue: "PENDING",
    },
    {
      buttonName: "Complete",
      buttonType: "secondary",
      buttonValue: "COMPLETE",
    },
  ];

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Beneficiary Name",
      accessor: "beneficiaryName",
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ value }: any) => formatNumberAsDigit(value),
    },
    {
      Header: "Currency",
      accessor: "currency",
    },
    {
      Header: "Bank Name",
      accessor: "bankName",
    },
    {
      Header: "Account Number/IBAN",
      accessor: "accountNumberOrIban",
    },
    {
      Header: "Bank Reference/Memo",
      accessor: "bankReferenceOrMemo",
    },
    {
      Header: "Internal Reference/Memo",
      accessor: "internalReferenceOrMemo",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Actions",
      Cell: ({ row }: any) =>
        user?.role === Roles.MASTER_ADMIN
          ? renderNavButton(row.original)
          : renderDownloadButtonIfHasReceipt(row?.original),
    },
  ];

  const handleWithdrawalTypeChange = async (newWithdrawalType: string) => {
    setWithdrawalType(newWithdrawalType);
    navigate(
      `/home/withdrawals?status=${withdrawalType}&merchantId=${merchantId}`
    );
    setCurrentPage(0);
  };

  const fetchWithdrawals = async () => {
    try {
      setIsLoading(true);
      const response = await getWithdrawals(withdrawalType, currentPage);
      setWithdrawalsData(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchWithdrawals();
  }, [withdrawalType, currentPage]);

  const handleDownload = async (withdrawal: any) => {
    try {
      await getWithdrawalReceipt(withdrawal);
    } catch (error) {
      handleAxiosErrors({
        toastTitle: "Faild to download receipt!",
        error: error,
      });
    }
  };

  const renderDownloadButtonIfHasReceipt = (withdrawal: any) => {
    if (!withdrawal?.receiptFilename) return null;
    else
      return (
        <PrimaryButton onClick={() => handleDownload(withdrawal)}>
          <IconDownload />
          Receipt
        </PrimaryButton>
      );
  };

  const renderNavButton = (withdrawal: any) => {
    return (
      <PrimaryButton
        onClick={() => {
          navigate(`/home/withdrawals/edit`, {
            state: { withdrawal },
          });
        }}
        className="gap-2"
      >
        <IconEdit /> View
      </PrimaryButton>
    );
  };

  const renderCreateWithdrawalButtonIfMerchantAdmin = () => {
    if (user?.role === Roles.MERCHANT_ADMIN) {
      return (
        <PrimaryButton onClick={() => navigate("/home/withdrawals/create")}>
          Create Withdrawal
        </PrimaryButton>
      );
    }
    return null;
  };

  const WithdrwawalsSection = () => (
    <div className="mt-4 flex flex-grow flex-col justify-between pb-8">
      {isLoading ? (
        <IconLoader size={72} className="m-auto mt-14 animate-spin" />
      ) : (
        <ResponsiveTableWithPagination
          columns={columns}
          data={withdrawalsData}
          handlePageChange={setCurrentPage}
        />
      )}
    </div>
  );

  const handleMerchantId = (value: number) => {
    setMerchantId(value);
    navigate(`/home/withdrawals?status=${withdrawalType}&merchantId=${value}`);
  };
  return (
    <div className="flex flex-grow dark:bg-black dark:text-white">
      <div className="flex flex-grow p-4 pb-0">
        <div className="flex flex-col flex-grow rounded-lg bg-[#212632] pb-12">
          <div className="flex items-center justify-between border-b border-b-slate-500 p-2 ">
            <h2 className="text-xl">
              See all your
              <span className="font-bold text-yellow-500"> withdrawals</span>
            </h2>

            {renderCreateWithdrawalButtonIfMerchantAdmin()}
          </div>

          <div className="flex items-center flex-col justify-between p-3 md:flex-row justify-center gap-2">
            <ExcelReport
              reportType="WIRE_TRANSFER"
              handleMerchantId={handleMerchantId}
            />
            <div className="flex flex-row items-center md:flex-row">
              <div className="flex justify-center md:justify-end p-1">
                <NavigationButtons
                  buttonsList={buttonsList}
                  value={withdrawalType}
                  setValue={handleWithdrawalTypeChange}
                />
              </div>
            </div>
          </div>

          <WithdrwawalsSection />
        </div>
      </div>
    </div>
  );
};

export default Withdrawals;
