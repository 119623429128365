import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { number } from "zod";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function beautifyNumber(input: string | number): string {
  let number;

  // Check if the input (has commas)
  if (typeof input === "string" && input.includes(",")) {
    console.log(input);
    // Remove commas and convert to a number
    number = parseFloat(input.replace(/,/g, ""));
  }

  // Convert the input to a number if it's a string
  number = typeof input === "string" ? parseFloat(input) : input;

  // Handle invalid number cases
  if (isNaN(number)) {
    return "N/A";
  }

  const isNegative = number < 0;
  number = Math.abs(number);

  const rounded = Math.round(number * 10000) / 10000;

  const parts = rounded.toString().split(".");
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas every 3 digits

  const formattedNumber = `${isNegative ? "-" : ""}${integerPart}${parts.length > 1 ? `.${parts[1]}` : ""}`;

  return formattedNumber;
}

export function formatDateToDateAndHour(dateString: string) {
  if (dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  } else {
    return "N/A";
  }
}

export function formatNumberAsDigit(num: string | number) {
  const asNumber = +num;
  try {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(asNumber);
  } catch (err) {
    console.log(err);
    return num;
  }
}

export function formatStringDigitToNumber(digitString: string) {
  try {
    const cleanedStr = digitString.replace(/,/g, "");
    const num = parseFloat(cleanedStr);
    return num;
  } catch (err) {
    console.log(err);
    return digitString;
  }
}

export const returnMerchantNameFromMerchantId = (
  merchantId: number,
  merchantList: any
) => {
  const merchant = merchantList?.find((merchant: any) => {
    return merchant?.id === merchantId;
  });
  return merchant?.name;
};
export const returnTopUpOrRequest = (status: string) => {
  if (status === "TOP_UP") {
    return "Top-up";
  } else {
    return "Request";
  }
};

export const formatDate = (date: string) => {
  try {
    return new Date(date).toISOString().slice(0, 10);
  } catch (error) {
    console.log(error);
  }
};

//transform date to yyyy-mm-dd
export const dateToString = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const downloadFileFromBlob = (blob: Blob, filename: string) => {
  try {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  } catch (err) {
    console.log(err);
  }
};
