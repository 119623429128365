import React from 'react'

const SenderMessageBubble = ({
  message,
  date
}: {
  message: string
  date: string
}) => {
  return (
    <div className='flex flex-col items-end'>
      <div className='float-end block min-w-[100px] max-w-[75%] rounded-2xl bg-black px-2 py-1 text-white shadow-md shadow-gray-400'>
        {message}
        <p className='text-right text-xs text-gray-400'>
          {getFormattedTime(date)}
        </p>
      </div>
    </div>
  )
}

export const getFormattedTime = (dateString: string) => {
  const date = new Date(dateString)

  // Get hours (0-23) and minutes (0-59)
  const hours = date.getHours()
  const minutes = date.getMinutes()

  // Format the output with leading zeros for single digits (optional)
  const formattedTime =
    hours.toString().padStart(2, '0') +
    ':' +
    minutes.toString().padStart(2, '0')

  return formattedTime
}

export default SenderMessageBubble
