import { downloadFileFromBlob } from "lib/utils";
import { REST, RESTMultipart } from "./axiosConfig";

// POST /withdrawal/uploadReceipt/{withdrawalId}
export const uploadReceipt = async (withdrawalId: number, file: any) => {
  try {
    const response = await RESTMultipart.post(
      `withdrawal/uploadReceipt/${withdrawalId}`,
      file
    );
    return response;
  } catch (error) {
   throw error
  }
};

export const putWithdrawal = async (
  withdrawalId: number,
  payload: {
    beneficiaryName: string;
    beneficiaryAddress: string;
    amount: number;
    currency: string;
    bankName: string;
    bankAddress: string;
    accountNumberOrIban: string;
    swiftOrBic: string;
    bankReferenceOrMemo: string;
    internalReferenceOrMemo: string;
    others: string;
    status: string;
    receiptFilename: string;
  }
) => {
  try {
    const response = await REST.put(
      `/withdrawal/${withdrawalId}/update`,
      payload
    );
    return response
  } catch (error) {
    throw error
  }
};

// GET /withdrawal/download/{withdrawalId}
export const getWithdrawalReceipt = async (withdrawal: any) => {
  try {
    const response = await REST.get(`/withdrawal/download/${withdrawal.id}`, {
      responseType: "arraybuffer",
    });
    const blob = new Blob([response.data], {
      type: "application/octet-stream",
    });
    const extension = withdrawal.receiptFilename.split(".").pop();
    downloadFileFromBlob(blob, `receipt.${extension}`);
  } catch (error) {
    throw error
  }
};


export const getDownloadWithdrawlReceipt = async (withdrawalId: number) => {
  try {
    const response = await REST.get(
      `/withdrawal/download/${withdrawalId}`,
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([response.data], {
      type: "application/octet-stream",
    });
    downloadFileFromBlob(blob, "topUpReceipt.pdf");

    return;
  } catch (error) {
    throw error
  }
};
