import { IconLoader } from "@tabler/icons-react";
import {
  TaskStatus,
  Transaction,
  TransactionType,
  getTransactions,
  updateTransactionStatus,
} from "api/transactions";
import stompManager from "hooks/stompManager";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserState } from "store/userStore";
import { useLocation, useNavigate } from "react-router-dom";
import { useHandleAxiosError } from "hooks/useAxiosHandlerError";
import ResponsiveTableWithPagination from "components/shared/customTableComponent/ResponsiveTableWithPagination";
import StatusSelect from "components/shared/statusSelect/statusSelect";
import { formatDate, formatNumberAsDigit } from "lib/utils";
import NavigationButtons from "components/shared/buttons/buttonsList/navigationButtonsList";
import { NavigationButtonsData } from "interfaces/navigationButtonsListInterface";
import { Roles } from "roles/roles";
import SelectMerchantId from "components/selectMerchantId/selectMerchantId";
import ExcelReport from "components/excelReport/excelReport";

const Transactions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state: UserState) => state.user.user);
  const destination = `/topic/merchant/${user?.merchantId}`;
  const audio = new Audio("../sounds/notification.wav");
  const { handleAxiosErrors } = useHandleAxiosError();
  // State variables
  const [data, setData] = useState<any>();
  const [transactions, setTransactions] = useState<Map<number, Transaction>>(
    new Map()
  );
  const queryParams = new URLSearchParams(location.search);

  const [merchantId, setMerchantId] = useState<number>(
    user?.role === Roles?.MASTER_ADMIN
      ? queryParams.get("merchantId")
      : user?.merchant?.id
  );

  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [transactionType, setTransactionType] =
    useState<TransactionType>("new");

    let buttonsList: NavigationButtonsData = [];

    if (user?.role === Roles?.MASTER_ADMIN) {
      buttonsList = [
        { buttonName: "New", buttonType: "secondary", buttonValue: "new" },
        { buttonName: "Confirmed", buttonType: "secondary", buttonValue: "processed" },
        { buttonName: "Canceled", buttonType: "secondary", buttonValue: "canceled" },
        { buttonName: "Pending", buttonType: "secondary", buttonValue: "pending" },
      ];
    } else {
      buttonsList = [
        { buttonName: "New", buttonType: "secondary", buttonValue: "new" },
        { buttonName: "Confirmed", buttonType: "secondary", buttonValue: "processed" },
        // Uncomment if needed
        // { buttonName: "Canceled", buttonType: "secondary", buttonValue: "canceled" },
        // { buttonName: "Pending", buttonType: "secondary", buttonValue: "pending" },
      ];
    }


  const columns: any[] = [
    {
      Header: "Date",
      accessor: "startDate",
      Cell: ({ value }: any) => formatDate(value),
    },
    {
      Header: "Client",
      accessor: "client",
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ value }: any) => formatNumberAsDigit(value),
    },
    {
      Header: "Memo",
      accessor: "memo",
    },
    {
      Header: "Operation",
      accessor: "operation",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }: any) => (
        <StatusSelect
          onChange={(value) => handleStatusChange(row.original.id, value)}
          currentStatus={row.original.status}
        />
      ),
    },
  ];

  const refreshPagination = () => {
    setPageSize(10);
    setCurrentPage(0);
  };

  const getTransactionsQuery = async () => {
    try {
      const response = await getTransactions(
        pageSize,
        currentPage,
        transactionType,
        merchantId
      );
      return response;
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  const initializeTransactions = (data: Transaction[]) => {
    const transactionMap = createTransactionMap(data);
    setTransactions(transactionMap);
  };

  const createTransactionMap = (data: any): Map<number, Transaction> => {
    return new Map(data?.content?.map((tx: Transaction) => [tx.id, tx]));
  };

  const handleNewTransaction = (response: any) => {
    if (response.body) {
      playNotificationSound();
      const newTransaction = JSON.parse(response.body);
      addNewTransaction(newTransaction);
    }
  };

  const addNewTransaction = (newTransaction: Transaction) => {
    setTransactions((prevData) => {
      const updatedTransactions: [number, Transaction][] = [
        [newTransaction.id, newTransaction],
        ...Array.from(prevData),
      ];
      return new Map(updatedTransactions);
    });
  };

  const playNotificationSound = () => {
    audio.play();
  };

  const handleTransactionTypeChange = (type: any) => {
    setTransactionType(type);
    refreshPagination();
    navigate(
      `/home/transactions?transactionType=${type}$merchantId=${merchantId}`
    );
  };

  const handleStatusChange = async (
    transactionId: number,
    status: TaskStatus
  ) => {
    try {
      await updateTransactionStatus(transactionId, status).then((res) => {
        if (res) {
          setRefreshData((prev) => !prev);
        }
      });
    } catch (error) {
      handleAxiosErrors({
        toastTitle: "Failed to change transaction status!",
        error: error,
      });
    }
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      setIsLoading(true);
      const response = await getTransactionsQuery();
      setData(response);
      setIsLoading(false);
    };

    fetchTransactions();
  }, [pageSize, currentPage, refreshData, transactionType, merchantId]);

  useEffect(() => {
    if (data) {
      initializeTransactions(data);
    }
  }, [data]);

  useEffect(() => {
    stompManager.subscribe(destination, handleNewTransaction);
  }, [destination]);

  const TransactionSection = () => (
    <div className="mt-4 flex flex-grow flex-col justify-between pb-8">
      {isLoading ? (
        <IconLoader size={72} className="m-auto mt-14 animate-spin" />
      ) : (
        <ResponsiveTableWithPagination
          columns={columns}
          data={data}
          handlePageChange={setCurrentPage}
        />
      )}
    </div>
  );

  const handleMerchantId = (value: number) => {
    setMerchantId(value);
    navigate(
      `/home/transactions?transactionType=${transactionType}&merchantId=${value}`
    );
  };

  return (
    <div className="ml-2 mr-2 mt-4 flex flex-grow dark:bg-black dark:text-white">
      <div className="mt-1 flex flex-grow flex-col rounded-lg bg-[#212632]">
        <div className="hidden md:flex items-center justify-between border-b border-b-slate-500 p-4">
          <h2 className="text-xl">
            View, edit, or delete your
            <span className="font-bold text-yellow-500"> transactions</span>
          </h2>
        </div>

        <div className="flex items-center flex-col justify-between p-3 md:flex-row justify-center gap-2">
          <ExcelReport reportType="ZELLE" handleMerchantId={handleMerchantId} />

          <div className="flex flex-row items-center md:flex-row">
            <div className="flex justify-center md:justify-end p-2 ">
              <NavigationButtons
                buttonsList={buttonsList}
                value={transactionType}
                setValue={handleTransactionTypeChange}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-grow flex-col p-4">
          {/* <SearchForm handlePageSizeChange={handlePageSizeChange} /> */}
          <TransactionSection />
        </div>
      </div>
    </div>
  );
};

export default Transactions;
