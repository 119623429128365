import {
  IconArrowBigUpLinesFilled,
  IconLoader,
  IconPigMoney,
  IconWindowMaximize,
} from "@tabler/icons-react";
import PrimaryButton from "components/shared/buttons/primaryButton/primaryButton";
import { Button } from "components/ui/button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../layout";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCryptoByStatus } from "api/cryptoController";
import {
  beautifyNumber,
  returnMerchantNameFromMerchantId,
  returnTopUpOrRequest,
} from "lib/utils";
import { getAllMerchants, getAllMerchantsPerPage } from "api/merchant";
import { stat } from "fs";
import TablePagination from "components/shared/tablePagination/tablePagination";
import { getCryptoBalanceForMerchant } from "api/dashboardController";
import { formatNumberAsDigit } from "lib/utils";
import { NavigationButtonsData } from "interfaces/navigationButtonsListInterface";
import NavigationButtons from "components/shared/buttons/buttonsList/navigationButtonsList";
import ResponsiveTableWithPagination from "components/shared/customTableComponent/ResponsiveTableWithPagination";
import ExcelReport from "components/excelReport/excelReport";
const CryptoBalanceMerchant = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const user = useSelector((state: any) => state.user.user);
  const navigate = useNavigate();

  const [transactionData, setTransactionData] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState(
    queryParams.get("status") ? queryParams.get("status") : "NEW"
  );
  const [cryptoBalance, setCryptoBalance] = useState<any>(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const buttonsList: NavigationButtonsData = [
    { buttonName: "New", buttonType: "secondary", buttonValue: "NEW" },
    {
      buttonName: "In Progress",
      buttonType: "secondary",
      buttonValue: "IN_PROGRESS",
    },
    {
      buttonName: "Pending",
      buttonType: "secondary",
      buttonValue: "PENDING",
    },
    {
      buttonName: "Complete",
      buttonType: "secondary",
      buttonValue: "COMPLETE",
    },
  ];

  const columns: any[] = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Transaction Type",
      accessor: "transactionType",
      Cell: ({ value }: any) => returnTopUpOrRequest(value),
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ value }: any) => formatNumberAsDigit(value),
    },
    {
      Header: "Currency",
      accessor: "currency",
    },
    {
      Header: "Exchange Rate",
      accessor: "exchangeRate",
      Cell: ({ value }: any) => formatNumberAsDigit(value),
    },
    {
      Header: "Fiat Amount",
      accessor: "fiatAmount",
      Cell: ({ value }: any) => formatNumberAsDigit(value),
    },
    {
      Header: "Provider Fees",
      accessor: "providerFees",
      Cell: ({ value }: any) => formatNumberAsDigit(value),
    },
    {
      Header: "Merchant Amount",
      accessor: "merchantAmountReceive",
      Cell: ({ value }: any) => formatNumberAsDigit(value),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }: any) => returnStatusAsString(value),
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }: any) => returnViewButton(row.original, navigate),
    },
  ];

  const handlePageChange = async (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleStatusTypeChange = (type: any) => {
    setSelectedStatus(type);
    navigate(`/home/crypto-balance-merchant?status=${type}`);
  };

  useEffect(() => {
    const fetchCryptoBalance = async () => {
      try {
        const response = await getCryptoBalanceForMerchant(user?.id);
        setCryptoBalance(response?.balance);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCryptoBalance();
  }, []);

  useEffect(() => {
    const fetchAllTransactionsByStatus = async (status: string | null) => {
      try {
        setIsLoading(true);
        const response = await getCryptoByStatus(status, currentPage);
        setTransactionData(response);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAllTransactionsByStatus(selectedStatus);
  }, [selectedStatus, currentPage]);

  const TransactionSection = () => (
    <div className="mt-4 flex flex-grow flex-col justify-between pb-8">
      {isLoading ? (
        <IconLoader size={72} className="m-auto mt-14 animate-spin" />
      ) : (
        <ResponsiveTableWithPagination
          columns={columns}
          data={transactionData}
          handlePageChange={setCurrentPage}
        />
      )}
    </div>
  );

  return (
    <div className="flex flex-grow dark:bg-black dark:text-white">
      <div className="flex flex-grow p-4 pb-0">
        <div className=" flex flex-grow flex-col rounded-lg bg-[#212632]">
          {/* TOPBAR  */}
          <div className="flex flex-col lg:flex-row gap-y-4 lg:items-center justify-between border-b border-b-slate-500 p-4">
            <div className="flex items-center justify-between p-4 hidden md:flex">
              <div className="flex items-center gap-4">
                <h2 className="py-[6px] text-xl">
                  See all your crypto transactions listed here
                </h2>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row gap-4">
              <PrimaryButton
                onClick={() =>
                  navigate("/home/crypto-balance/top-up-merchant/create")
                }
              >
                <IconArrowBigUpLinesFilled />
                Top-up
              </PrimaryButton>
            </div>
          </div>
          <div className="flex items-center flex-col justify-between p-3 md:flex-row justify-center gap-2">
            <ExcelReport reportType="CRYPTO" />
            <div className="flex justify-center md:justify-end p-2">
              <NavigationButtons
                buttonsList={buttonsList}
                value={selectedStatus}
                setValue={handleStatusTypeChange}
              />
            </div>
          </div>

          <TransactionSection />
        </div>
      </div>
    </div>
  );
};

const returnViewButton = (transaction: any, navigate: any) => {
  const returnViewLink = (transactionType: string) => {
    if (transactionType === "TOP_UP") {
      return "/home/crypto-balance/top-up-merchant/view";
    } else {
      return "/home/crypto-balance/payment-request-merchant/view";
    }
  };
  if (
    transaction.transactionType === "TOP_UP"
    // transaction.currency === "USD"
  ) {
    return (
      <div
        onClick={() =>
          navigate(returnViewLink(transaction?.transactionType), {
            state: { transaction },
          })
        }
      >
        <PrimaryButton>
          <IconPigMoney /> View
        </PrimaryButton>
      </div>
    );
  } else if (
    transaction?.transactionType === "WITHDRAWAL" &&
    transaction?.status === "COMPLETE"
  ) {
    return (
      <PrimaryButton
        onClick={() =>
          navigate(`/home/crypto-balance/payment-request-merchant/view`, {
            state: { transaction },
          })
        }
      >
        <IconWindowMaximize /> View
      </PrimaryButton>
    );
  } else {
    return null;
  }
};

const returnStatusAsString = (status: string) => {
  switch (status) {
    case "NEW":
      return "New";
    case "PENDING":
      return "Pending";
    case "IN_PROGRESS":
      return "In Progress";
    case "COMPLETE":
      return "Complete";
    default:
      return "N/A";
  }
};

export default CryptoBalanceMerchant;
