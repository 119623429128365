import { FormItem, FormLabel } from "components/ui/form";
import { Input } from "components/ui/input";
import { Separator } from "components/ui/separator";
import { beautifyNumber } from "lib/utils";
import Layout from "pages/(root)/home/layout";
import { useLocation } from "react-router-dom";

const TopUpMerchantView = () => {
  const location = useLocation();
  const transaction = location.state?.transaction;

  console.log(transaction);
  return (
    <div className="flex flex-grow dark:bg-black dark:text-white">
      <div className="flex flex-grow p-4 pb-0">
        <div className="flex-grow rounded-lg bg-[#212632]">
          {/* TOPBAR */}
          <div className="flex items-center justify-between border-b border-b-slate-500 p-4">
            <div className="mb-4 mt-2 flex items-center gap-4">
              <h2 className="py-[6px] text-xl">View your top-up</h2>
            </div>
          </div>

          <div className="mx-4 mb-3 mt-12">
            <div className="m-auto flex w-full max-w-5xl flex-col md:flex-row justify-between gap-5">
              <div className="w-full md:w-1/2 mb-8 md:mb-0">
                <h3>Amount</h3>
                <div className="mb-4 mt-2 flex items-center gap-4">
                  <Input
                    className="w-full text-right text-xl"
                    value={beautifyNumber(transaction.amount) + " BTC"}
                    readOnly
                  />
                </div>

                <h3>Exchange Rate</h3>
                <div className="mb-4 mt-2 flex items-center gap-4">
                  <Input
                    className="w-full text-right text-xl"
                    value={
                      beautifyNumber(transaction.exchangeRate) +
                      " " +
                      transaction.currency
                    }
                    readOnly
                  />
                </div>

                <div>
                  <h3>Fiat Amount</h3>
                  <div className="mb-4 mt-2 flex items-center gap-4">
                    <Input
                      className="w-full text-right text-xl"
                      value={beautifyNumber(transaction.fiatAmount) + " USD"}
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/2">
                <h3>Provider Fees</h3>
                <div className="mb-4 mt-2 flex items-center gap-4">
                  <Input
                    className="w-full text-right text-xl"
                    value={beautifyNumber(transaction.providerFees)}
                    readOnly
                  />
                </div>

                <h3>Merchant Amount Receive</h3>
                <div className="mb-4 mt-2 flex items-center gap-4">
                  <Input
                    className="w-full text-right text-xl"
                    value={
                      beautifyNumber(transaction.merchantAmountReceive) + " USD"
                    }
                    readOnly
                  />
                </div>

                <h3>Status</h3>
                <div className="mb-4 mt-2 flex items-center gap-4">
                  <Input
                    className="w-full text-right text-xl"
                    value={transaction.status}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopUpMerchantView;
