import { getUserProfile } from "api/agents";
import React from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/ui/accordion";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import { Button } from "components/ui/button";
import vaultLogo from "images/vaultLogo.png";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setUser } from "store/userSlice";
import { SidebarData, SidebarItem } from "interfaces/sidebarInterface";
import { Roles } from "roles/roles";

interface MasterAdminSidebarProps {
  sidebarElements: SidebarData;
}

const MasterAdminSidebar: React.FC<MasterAdminSidebarProps> = ({
  sidebarElements,
}) => {
  return (
    <div className="container max-w-full w-fit rounded-br-lg border-r border-r-slate-500 border-opacity-15 bg-[#212632] p-6 lg:block dark:text-white">
      <MasterAdminSidebarHeader />
      <MasterAdminSidebarAccordion sidebarElements={sidebarElements} />
    </div>
  );
};

const MasterAdminSidebarAccordion: React.FC<MasterAdminSidebarProps> = ({
  sidebarElements,
}) => {
  const user = useSelector((state: any) => state?.user?.user);

  const navigate = useNavigate();
  const location = useLocation();
  const entityType = location.pathname.split("/")[2];

  const [activeValue, setActiveValue] = useState<string | undefined>(undefined);

  const isActive = (targetEntityType: string) => {
    return targetEntityType === entityType;
  };

  useEffect(() => {
    const activeAccordion = sidebarElements.find((item) =>
      item.links.some((link) => isActive(link.path.split("/")[2]))
    );
    if (activeAccordion) {
      setActiveValue(activeAccordion.value);
    }
  }, [location.pathname]);

  const navigateToSingleLink = (link: string | null) => {
    if (link) {
      navigate(link);
    }
  };

  return (
    <div className="flex flex-col items-center gap-2 " >
      <Accordion
        className="w-full space-y-4"
        type="single"
        collapsible
        value={activeValue}
        onValueChange={setActiveValue}
      >
        {sidebarElements.map((item: SidebarItem, index) => (
          <div key={index}>
            {item.allowedRoles.includes(user?.role) && (
              <AccordionItem value={item.value}>
                {item?.singleLink ? (
                  <Button
                    variant={"secondary"}
                    className={`w-full justify-start gap-2 text-left ${
                      isActive(item.singleLink.split("/")[2])
                        ? "bg-yellow-500 text-black"
                        : "bg-gray-700 text-slate-300"
                    } hover:bg-yellow-500 hover:text-black`}
                    onClick={() => navigateToSingleLink(item?.singleLink)}
                  >
                    {item.icon} {item.label}
                  </Button>
                ) : (
                  <AccordionTrigger className="py-0">
                    <Button
                      variant={"secondary"}
                      className={`w-full justify-start gap-2 text-left ${
                        item?.links.some((link) =>
                          isActive(link.path.split("/")[2])
                        )
                          ? "bg-yellow-500 text-black"
                          : "bg-gray-700 text-slate-300"
                      } hover:bg-yellow-500 hover:text-black`}
                    >
                      {item.icon} {item.label}
                    </Button>
                  </AccordionTrigger>
                )}
                <AccordionContent className="mt-4 flex flex-col items-end pb-0">
                  {item?.links.map((subLink, index) => (
                    <React.Fragment key={index}>
                      {subLink.allowedRoles.includes(user?.role) && (
                        <Link
                          key={index}
                          className="mb-2 w-4/5"
                          to={subLink.path}
                        >
                          <Button
                            variant={"secondary"}
                            className={`w-full justify-start gap-2 text-left overflow-hidden text-ellipsis whitespace-nowrap ${
                              isActive(subLink.path.split("/")[2])
                                ? "bg-yellow-500 text-black"
                                : "bg-gray-700 text-slate-300"
                            } hover:bg-yellow-500 hover:text-black`}
                          >
                            {subLink.subIcon} 
                            {subLink.label}
                          </Button>
                        </Link>
                      )}
                    </React.Fragment>
                  ))}
                </AccordionContent>
              </AccordionItem>
            )}
          </div>
        ))}
      </Accordion>
    </div>
  );
};

const MasterAdminSidebarHeader = () => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      const fetchAndSetUser = async () => {
        try {
          const response = await getUserProfile();
          dispatch(setUser(response));
        } catch (error) {
          // Handle errors
        }
      };
      fetchAndSetUser();
    }
  }, [user, dispatch]);

  const handleProfileClick = () => {
    // Handle profile click
  };

  const handeleUserRole = (userRole: String) => {
    switch (userRole) {
      case Roles.MASTER_ADMIN:
        return "Master Admin";
      case Roles.MERCHANT_ADMIN:
        return "Merchant Admin";
      case Roles.MERCHANT_USER:
        return "Merchant User";
      default:
        return "Unknown user type";
    }
  };

  return (
    <div>
      <img
        src={vaultLogo}
        alt="Merchant Logo"
        className="m-auto h-auto w-[200px] cursor-pointer"
        onClick={() => navigate("/home")}
      />
      <div
        className="mb-8 mt-6 flex items-center gap-2"
        onClick={handleProfileClick}
      >
        <Avatar>
          <AvatarImage src="https://github.com/shadcn.png" />
          <AvatarFallback>CN</AvatarFallback>
        </Avatar>
        <p>{user?.firstName + " " + user?.lastName}</p>
      </div>
      <p className="mb-8 mt-2 text-center text-yellow-500">
        {handeleUserRole(user?.role)}
      </p>
    </div>
  );
};

export default MasterAdminSidebar;
