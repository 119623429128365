import React from 'react'
import { getFormattedTime } from './senderMessageBubble'

const RecipientMessageBubble = ({
  message,
  date
}: {
  message: string
  date: string
}) => {
  return (
    <div className='flex flex-col'>
      <div className='float-start max-w-[75%] rounded-2xl bg-white px-2 py-1 text-black shadow-md shadow-gray-400'>
        {message}
        <p className='text-right text-xs text-gray-400'>
          {getFormattedTime(date)}
        </p>
      </div>
    </div>
  )
}

export default RecipientMessageBubble
