'use client'
import CreateAgentForm from './createAgentForm'
import CreateMerchantForm from './createMerchantForm'
import CreateTransactionForm from './createTransactionForm'

const UpdateOrCreateEntityForm = ({
  entity,
  item
}: {
  entity: string
  item?: any
}) => {
  return <div className='container max-w-96'>{returnForm(entity, item)}</div>
}

const returnForm = (entity: string, item?: any) => {
  switch (entity) {
    case 'agents':
      return <CreateAgentForm item={item} />
    case 'merchants':
      return <CreateMerchantForm item={item} />
    case 'transactions':
      return <CreateTransactionForm item={item} />
    default:
      return <></>
  }
}

export default UpdateOrCreateEntityForm
