import { useState, useEffect } from "react";
import { Roles } from "roles/roles";
import { useForm } from "react-hook-form";
import { Button } from "../../ui/button";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";
import { Input } from "../../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { useToast } from "components/ui/use-toast";
import { useSelector } from "react-redux";
import { getAllMerchants, getMerchantById } from "api/merchant";
import { createInternalTransfer } from "api/balanceController";
import { useHandleAxiosError } from "hooks/useAxiosHandlerError";
import { Textarea } from "components/ui/textarea";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { formatStringDigitToNumber } from "lib/utils";
const CreateBalanceTransferForm = () => {
  const navigate = useNavigate();
  const [merchants, setMerchants] = useState<Object[]>([]);
  const { handleAxiosErrors } = useHandleAxiosError();
  const user = useSelector((state: any) => state?.user?.user);

  const balanceAccountsTypes = [
    "ZELLE",
    "CRYPTO",
    "ACH",
    "WIRE_TRANSFER",
    "UNKNOWN",
  ];

  useEffect(() => {
    const fetchMerchants = async () => {
      if (user?.role === Roles.MASTER_ADMIN) {
        const response = await getAllMerchants();
        setMerchants(response?.content);
      } else {
        const response = await getMerchantById(user?.merchantId);
        setMerchants([response]);
      }
    };
    fetchMerchants();
  }, []);

  const formSchema = z.object({
    sourceAccount: z.string().min(1).max(50),
    destinationAccount: z.string().min(1).max(50),
    amount: z.string().min(1),
    merchantId: z.string().min(1).max(50),
    description: z.string().optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      sourceAccount: "",
      destinationAccount: "",
      merchantId: "",
      amount: "",
      description: "",
    },
  });

  const onSubmit = async (values: any) => {
    const transferData = {
      sourceAccount: values.sourceAccount,
      destinationAccount: values?.destinationAccount,
      merchantId: +values?.merchantId,
      amount: +formatStringDigitToNumber(values?.amount),
      description: values?.description,
    };

    try {
      await createInternalTransfer(transferData).then((res) => {
        if (res) {
          navigate(
            `/home/balance?type=${values?.destinationAccount}&merchantId=${values?.merchantId}`,
            {
              state: { merchantId: +values?.merchantId },
            }
          );
        }
      });
    } catch (err) {
      handleAxiosErrors({
        toastTitle: "Failed to create balance transfer!",
        error: err,
      });
    }
  };

  return (
    <div className="p-5">
      <Form {...form}>
        <form
          className="space-y-3 m-auto max-w-[60rem]"
          onSubmit={form?.handleSubmit(onSubmit)}
        >
          <FormField
            control={form?.control}
            name="merchantId"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="dark:text-white">Merchant</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    value={field.value.toString()}
                  >
                    <SelectTrigger className="w-full bg-slate-500 text-white">
                      <SelectValue
                        style={{ textDecorationColor: "white" }}
                        placeholder={"Merchant"}
                      />
                    </SelectTrigger>
                    <SelectContent>
                      {merchants?.map((merchant: any) => (
                        <SelectItem
                          key={merchant?.id}
                          value={merchant?.id.toString()}
                        >
                          {merchant?.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="sourceAccount"
            render={({ field }) => (
              <FormItem>
                <FormLabel>From</FormLabel>
                <FormControl>
                  <div className="flex flex-col lg:flex-row items-center gap-4">
                    <Select
                      onValueChange={field.onChange}
                      value={field.value.toString()}
                    >
                      <SelectTrigger className="w-full bg-slate-500 text-white">
                        <SelectValue
                          style={{ textDecorationColor: "white" }}
                          placeholder={"From balance"}
                        />
                      </SelectTrigger>
                      <SelectContent>
                        {balanceAccountsTypes
                          ?.filter(
                            (type: string) =>
                              type !== form.watch("destinationAccount")
                          )
                          ?.map((type: string) => (
                            <SelectItem key={type} value={type}>
                              {type}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                  </div>
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="destinationAccount"
            render={({ field }) => (
              <FormItem>
                <FormLabel>To</FormLabel>
                <FormControl>
                  <div className="flex flex-col lg:flex-row items-center gap-4">
                    <Select
                      onValueChange={field.onChange}
                      value={field.value.toString()}
                    >
                      <SelectTrigger className="w-full bg-slate-500 text-white">
                        <SelectValue
                          style={{ textDecorationColor: "white" }}
                          placeholder={"To balance"}
                        />
                      </SelectTrigger>
                      <SelectContent>
                        {balanceAccountsTypes
                          ?.filter(
                            (type: string) =>
                              type !== form.watch("sourceAccount")
                          )
                          ?.map((type: string) => (
                            <SelectItem key={type} value={type}>
                              {type}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="amount"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="dark:text-white">Amount</FormLabel>
                <FormControl>
                  <NumericFormat
                    {...field}
                    customInput={Input}
                    thousandSeparator
                    decimalScale={3}
                    allowNegative={false}
                    className="dark:text-white"
                    onValueChange={(values) => {
                      field.onChange(+values.value);
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea className="dark:text-white" {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <div className="flex justify-center pt-2 pb-12">
            <Button
              variant={"secondary"}
              className="w-[280px] m-auto lg:w-[350px] border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white"
            >
              Create transfer
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default CreateBalanceTransferForm;
