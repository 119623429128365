"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { type } from "@testing-library/user-event/dist/type";
import { createTopUp, getConvert } from "api/cryptoController";
import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import { beautifyNumber } from "lib/utils";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

const formSchema = z.object({
  amount: z.string().min(1).max(50),
});

const CreateTopUpForm = ({ merchantId }: { merchantId: number }) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      amount: "",
    },
  });

  const navigate = useNavigate();

  const user = useSelector((state: any) => state.user.user);

  const [showFields, setShowFields] = useState(false);
  const [fieldValues, setFieldValues] = useState({
    exchangeRate: "",
    btcAmount: "",
    fiatAmount: "",
    exchangeFeePercentage: "",
    exchangeFeeAmount: "",
    bankFee: "",
    merchantAmountReceived: "",
  });

  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof formSchema>) {
    const response = await createTopUp({
      merchantId,
      amount: Number(values.amount),
    });
    navigate("/home/crypto-balance?status=processed");
  }

  return (
    <div className="m-auto mt-12 max-w-sm">
      <Form {...form}>
        <div className="flex justify-center">
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <div className="">
              <div className="space-y-4">
                <FormField
                  control={form.control}
                  name="amount"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Sending BTC</FormLabel>
                      <FormControl>
                        <div className="flex items-center flex-col lg:flex-row gap-4">
                          <Input
                            onInput={(e) => {
                              setShowFields(true);
                            }}
                            type="number"
                            className="w-[280px] lg:w-[350px] text-right text-xl"
                            placeholder="Amount"
                            {...field}
                          />
                          <span className="text-2xl font-bold text-gray-400">
                            BTC
                          </span>
                        </div>
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormItem className={`${showFields ? "block" : "hidden"}`}>
                  <FormLabel>Exchange rate</FormLabel>
                  <div className="flex items-center gap-4">
                    <Input
                      className="w-[280px] lg:w-[350px] text-right text-xl"
                      value={beautifyNumber(fieldValues?.exchangeRate)}
                      readOnly
                    />
                    <span className="text-2xl font-bold text-gray-400">$</span>
                  </div>
                </FormItem>
                <FormItem className={`${showFields ? "block" : "hidden"}`}>
                  <FormLabel>Amount in Fiat</FormLabel>
                  <div className="flex items-center gap-4">
                    <Input
                      className="w-[280px] lg:w-[350px] text-right text-xl"
                      value={beautifyNumber(fieldValues?.fiatAmount)}
                      readOnly
                    />
                    <span className="text-2xl font-bold text-gray-400">$</span>
                  </div>
                </FormItem>
              </div>
              <div className="space-y-4">
                <FormItem className={`${showFields ? "block" : "hidden"}`}>
                  <FormLabel>VT Exchane Rate Fee</FormLabel>
                  <div className="flex items-center gap-4">
                    <Input
                      className="w-[280px] lg:w-[350px] text-right text-xl"
                      value={beautifyNumber(fieldValues?.exchangeFeePercentage)}
                      readOnly
                    />
                    <span className="text-2xl font-bold text-gray-400">%</span>
                  </div>
                </FormItem>
                <FormItem className={`${showFields ? "block" : "hidden"}`}>
                  <FormLabel>Bank Fee</FormLabel>
                  <div className="flex items-center gap-4">
                    <Input
                      className="w-[280px] lg:w-[350px] text-right text-xl"
                      value={beautifyNumber(fieldValues?.bankFee)}
                      readOnly
                    />
                    <span className="text-2xl font-bold text-gray-400">$</span>
                  </div>
                </FormItem>
                <FormItem className={`${showFields ? "block" : "hidden"}`}>
                  <FormLabel>Receive</FormLabel>
                  <div className="flex items-center gap-4">
                    <Input
                      className="w-[280px] lg:w-[350px] text-right text-xl"
                      value={beautifyNumber(
                        fieldValues?.merchantAmountReceived
                      )}
                      readOnly
                    />
                    <span className="text-2xl font-bold text-gray-400">$</span>
                  </div>
                </FormItem>
              </div>
            </div>

            <div className="flex justify-center pb-12">
              <Button
                variant={"secondary"}
                className="w-[280px] lg:w-[350px] border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white"
              >
                Top Up{" "}
              </Button>
            </div>
          </form>
        </div>
      </Form>
    </div>
  );
};

export default CreateTopUpForm;
