"use client";
import { IconTrash, IconDownload } from "@tabler/icons-react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import { useState, useRef } from "react";
import SelectWithdrawalStatus from "components/selectFields/selectWithdrawalStatus/selectWithdrawalStatus";
import {
  putWithdrawal,
  uploadReceipt,
  getDownloadWithdrawlReceipt,
} from "api/withdrawalController";
import { useToast } from "components/ui/use-toast";
import { useHandleAxiosError } from "hooks/useAxiosHandlerError";

const formSchema = z.object({
  accountNumberOrIban: z.string().min(1).max(50),
  amount: z.string().min(1),
  bankAddress: z.string().min(1).max(100),
  bankName: z.string().min(1).max(50),
  bankReferenceOrMemo: z.string().min(1).max(50),
  beneficiaryAddress: z.string().min(1).max(100),
  beneficiaryName: z.string().min(1).max(50),
  currency: z.string().min(1).max(10),
  internalReferenceOrMemo: z.string().min(1).max(50),
  others: z.string().min(1).max(50),
  status: z.string().min(1).max(50),
  swiftOrBic: z.string().min(1).max(11),
});

const EditTransactionForm = ({ withdrawal }: any) => {
  const navigate = useNavigate();
  const { handleAxiosErrors } = useHandleAxiosError();
  const { toast } = useToast();
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      accountNumberOrIban: withdrawal?.accountNumberOrIban || "",
      amount: withdrawal?.amount?.toString() || "",
      bankAddress: withdrawal?.bankAddress || "",
      bankName: withdrawal?.bankName || "",
      bankReferenceOrMemo: withdrawal?.bankReferenceOrMemo || "",
      beneficiaryAddress: withdrawal?.beneficiaryAddress || "",
      beneficiaryName: withdrawal?.beneficiaryName || "",
      currency: withdrawal?.currency || "USD",
      internalReferenceOrMemo: withdrawal?.internalReferenceOrMemo || "",
      others: withdrawal?.others || "",
      status: withdrawal?.status || "NEW",
      swiftOrBic: withdrawal?.swiftOrBic || "",
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const payload = {
      ...values,
      receiptFilename: withdrawal?.receiptFilename,
      amount: Number(values.amount),
    };
    try {
      await putWithdrawal(withdrawal.id, payload).then((res) => {
        if (res) {
          if (file) {
            debugger;
            updateRecipient();
          } else {
            navigate("/home/withdrawals");
            toast({
              title: `Withdrawal updated!`,
            });
          }
        }
      });
    } catch (error) {
      handleAxiosErrors({
        toastTitle: "Failed to update withdrawal!",
        error: error,
      });
    }
  };

  const updateRecipient = async () => {
    try {
      const formData = new FormData();
      if (file) formData.append("file", file);
      await uploadReceipt(withdrawal.id, formData).then((res) => {
        if (res) {
          navigate("/home/withdrawals");
          toast({
            title: `Withdrawal updated!`,
          });
        }
      });
    } catch (err) {
      handleAxiosErrors({
        toastTitle: "Failed to update receipt!",
        error: err,
      });
    }
  };

  const handleDownload = async () => {
    try {
      await getDownloadWithdrawlReceipt(withdrawal?.id);
    } catch (error) {
      toast({
        title: `ERROR: Failed to download document!`,
      });
    }
  };
  const handleDeleteSelectedFile = () => {
    setFile(null);
    setFileName(null);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile?.name);
    }
  };

  const SavedFiles = () => (
    <div>
      {withdrawal?.receiptFilename && (
        <div
          className="mb-2 cursor-pointer flex flex-row items-center justify-between border p-4 border bg-gray-500 font-bold text-black"
          onClick={handleDownload}
        >
          <div>Saved receipt: {withdrawal.receiptFilename}</div>
          <IconDownload />
        </div>
      )}
      {withdrawal?.status !== "COMPLETE" && (
        <>
          <p>Update new document (PDF format):</p>

          <div className="flx flex row space-between">
            <Input
              type="file"
              accept=".pdf"
              className={
                file
                  ? "hidden"
                  : "w-[280px] lg:w-[350px] text-right text-xl cursor-pointer"
              }
              onChange={handleFileChange}
            />

            {file && (
              <div className="flex flex-row gap-3 p-2">
                {fileName}
                <button
                  className="ml-4 text-red-600 cursor-pointer flex items-center"
                  onClick={handleDeleteSelectedFile}
                >
                  <IconTrash size={20} className="mr-1" />
                  Delete
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );

  return (
    <Form {...form}>
      <div className="flex justify-center">
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="mb-12 space-y-2"
        >
          <div className="grid py-5 grid-cols-2 min-w-[700px] gap-y-4 gap-x-2">
            <FormField
              control={form.control}
              name="accountNumberOrIban"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Account Number / IBAN</FormLabel>
                  <FormControl>
                    <Input
                      readOnly
                      className="w-[280px] lg:w-[350px]"
                      placeholder="Account Number / IBAN"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Amount</FormLabel>
                  <FormControl>
                    <Input
                      readOnly
                      type="number"
                      className="w-[280px] lg:w-[350px]"
                      placeholder="Amount"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="bankAddress"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Bank Address</FormLabel>
                  <FormControl>
                    <Input
                      readOnly
                      className="w-[280px] lg:w-[350px]"
                      placeholder="Bank Address"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="bankName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Bank Name</FormLabel>
                  <FormControl>
                    <Input
                      readOnly
                      className="w-[280px] lg:w-[350px]"
                      placeholder="Bank Name"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="bankReferenceOrMemo"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Bank Reference / Memo</FormLabel>
                  <FormControl>
                    <Input
                      className="w-[280px] lg:w-[350px]"
                      placeholder="Bank Reference / Memo"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="beneficiaryAddress"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Beneficiary Address</FormLabel>
                  <FormControl>
                    <Input
                      readOnly
                      className="w-[280px] lg:w-[350px]"
                      placeholder="Beneficiary Address"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="beneficiaryName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Beneficiary Name</FormLabel>
                  <FormControl>
                    <Input
                      readOnly
                      className="w-[280px] lg:w-[350px]"
                      placeholder="Beneficiary Name"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="currency"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Currency</FormLabel>
                  <FormControl>
                    <Input
                      readOnly
                      className="w-[280px] lg:w-[350px]"
                      placeholder="Currency"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="internalReferenceOrMemo"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Internal Reference / Memo</FormLabel>
                  <FormControl>
                    <Input
                      className="w-[280px] lg:w-[350px]"
                      placeholder="Internal Reference / Memo"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="swiftOrBic"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>SWIFT / BIC</FormLabel>
                  <FormControl>
                    <Input
                      readOnly
                      className="w-[280px] lg:w-[350px]"
                      placeholder="SWIFT / BIC"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="others"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Others</FormLabel>
                  <FormControl>
                    <Input
                      readOnly
                      className="w-[280px] lg:w-[350px]"
                      placeholder="Others"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            {withdrawal?.status !== "COMPLETE" && (
              <FormField
                control={form.control}
                name="status"
                render={({ field }) => (
                  <FormItem className="">
                    <FormLabel>Status</FormLabel>
                    <SelectWithdrawalStatus
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    />
                  </FormItem>
                )}
              />
            )}
          </div>

          <SavedFiles />

          {withdrawal?.status !== "COMPLETE" && (
            <div className="flex justify-center mt-12 p-5">
              <Button
                variant={"secondary"}
                className="w-[280px] lg:w-[350px] border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white"
              >
                Submit
              </Button>
            </div>
          )}
        </form>
      </div>
    </Form>
  );
};

export default EditTransactionForm;
