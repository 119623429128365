import { IconEdit } from '@tabler/icons-react'
import UpdateOrCreateEntityForm from 'components/forms/createEntityForm/updateOrCreateEntityForm'
import { Button } from 'components/ui/button'
import { Dialog, DialogContent, DialogTrigger } from 'components/ui/dialog'

const UpdateEntityButton = ({
  item,
  entity
}: {
  item: string
  entity: string
}) => {
  return (
    <Dialog>
      <DialogTrigger>
        <Button className='h-fit w-fit bg-yellow-500 p-2'>
          <IconEdit size={14} />
        </Button>
      </DialogTrigger>
      <DialogContent className='dark max-h-[80vh] w-[80vw] max-w-3xl overflow-auto dark:text-white'>
        <UpdateOrCreateEntityForm item={item} entity={entity} />
      </DialogContent>
    </Dialog>
  )
}

export default UpdateEntityButton
