import { Checkbox } from "@radix-ui/react-checkbox";
import ReportCard from "components/cards/reportCard/reportCard";
import { DatePicker } from "components/datePicker/datePicker";
import { Button } from "components/ui/button";
import React, { useState } from "react";
import Layout from "../../layout";
import { generateCryptoReport } from "api/reportscontroller";
import { useHandleAxiosError } from "hooks/useAxiosHandlerError";
import { useToast } from "components/ui/use-toast";

const CryptoReports = () => {
  const { handleAxiosErrors } = useHandleAxiosError();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [transactions, setTransactions] = useState<any>(null);
  const [checkedTransactions, setCheckedTransactions] = useState<any>();
  const { toast } = useToast();
  const baseUrl = process.env.REACT_APP_API_URL;

  const generateReport = async () => {
    if (startDate <= endDate) {
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);
      try {
        await generateCryptoReport(formattedStartDate, formattedEndDate);
      } catch (error) {
        handleAxiosErrors({
          toastTitle: "Failed to generate report!",
          error: error,
        });
      }
    } else {
      toast({
        title: "Start date cannot be greater than end date!",
      });
    }
  };

  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const handleCheckboxChange = (transactionId: number) => {
    if (checkedTransactions?.includes(transactionId)) {
      setCheckedTransactions(
        checkedTransactions?.filter((id: number) => id !== transactionId)
      );
    } else {
      setCheckedTransactions([...(checkedTransactions || []), transactionId]);
    }
  };

  const GenerateCryptoButtons = () => (
    <div className="flex flex-col lg:flex-row items-center lg:items-stretch gap-4 mt-2 lg:mt-0">
      <DatePicker setDate={setStartDate} date={startDate} />
      <DatePicker setDate={setEndDate} date={endDate} />
      <Button
        variant={"secondary"}
        className="border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white"
        onClick={generateReport}
      >
        Generate Report
      </Button>
    </div>
  );
  

  return (
    <div className="flex flex-grow dark:bg-black dark:text-white">
      <div className="flex flex-grow p-4 pb-0">
        <div className="flex-grow rounded-lg bg-[#212632]">
          <div className="flex flex-col lg:flex-row items-center justify-between border-b border-b-slate-500 p-4">
            <h2 className="py-[6px] text-xl">
              Manage your
              <span className="font-bold text-yellow-500"> Crypto Reports</span>
            </h2>
            <GenerateCryptoButtons />
          </div>

          <div className="mt-4 p-4 pb-12">
            {!transactions ? (
              <h2 className="text-center text-4xl text-white">
                Start by generating a report
              </h2>
            ) : transactions.length === 0 ? (
              <h2 className="text-center text-4xl text-white">
                No transactions found
              </h2>
            ) : (
              <>
                {transactions?.map((transaction: any) => (
                  <div
                    key={transaction.id}
                    className="mb-5 flex items-center gap-4"
                  >
                    <Checkbox
                      onCheckedChange={() =>
                        handleCheckboxChange(transaction.id)
                      }
                    />
                    <ReportCard report={transaction} />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};


export default CryptoReports;
