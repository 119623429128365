import { useEffect, useState } from "react";
import { getPayments } from "api/paymentsController";
import PrimaryButton from "components/shared/buttons/primaryButton/primaryButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IconLoader, IconWindowMaximize } from "@tabler/icons-react";
import { beautifyNumber, formatDate } from "lib/utils";
import { useSelector } from "react-redux";
import UploadAchReceiptInput from "components/customInputs/uploadAchReceiptInput";
import { formatNumberAsDigit } from "lib/utils";
import { NavigationButtonsData } from "interfaces/navigationButtonsListInterface";
import NavigationButtons from "components/shared/buttons/buttonsList/navigationButtonsList";
import ResponsiveTableWithPagination from "components/shared/customTableComponent/ResponsiveTableWithPagination";
import SelectMerchantId from "components/selectMerchantId/selectMerchantId";
import { Roles } from "roles/roles";
import ExcelReport from "components/excelReport/excelReport";
const Payments = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [paymentsData, setPaymentsData] = useState<any>([]);
  const user = useSelector((state: any) => state.user.user);
  const [status, setStatus] = useState(
    queryParams.get("status") ? queryParams.get("status") : "NEW"
  );
  const [merchantId, setMerchantId] = useState<number>(
    user?.user?.role === Roles?.MASTER_ADMIN
      ? queryParams.get("merchantId")
      : user?.merchant?.id
  );
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);

  const navigate = useNavigate();

  const buttonsList: NavigationButtonsData = [
    { buttonName: "New", buttonType: "secondary", buttonValue: "NEW" },
    {
      buttonName: "In Progress",
      buttonType: "secondary",
      buttonValue: "IN_PROGRESS",
    },
    {
      buttonName: "Pending",
      buttonType: "secondary",
      buttonValue: "PENDING",
    },
    {
      buttonName: "Complete",
      buttonType: "secondary",
      buttonValue: "COMPLETE",
    },
  ];

  const columns: any[] = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Date",
      accessor: "createdAt",
      Cell: ({ value }: any) => {
        formatDate(value);
      },
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ row }: any) => (
        <>
          {formatNumberAsDigit(row.original?.amount)}{" "}
          {row.original?.amountCurrency}
        </>
      ),
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }: any) => (
        <div className="flex gap-2 items-center justify-center">
          <PrimaryButton
            onClick={() =>
              navigate("/home/payments-merchant/view", {
                state: { payment: row.original },
              })
            }
          >
            <IconWindowMaximize className="mr-2" /> View
          </PrimaryButton>
          <UploadAchReceiptInput payment={row.original} />
        </div>
      ),
    },
  ];

  const handleStatusTypeChange = (type: any) => {
    setStatus(type);
    navigate(`/home/payments?status=${type}&merchantId=${merchantId}`);
  };

  useEffect(() => {
    const fetchPaymentsData = async () => {
      try {
        setIsLoading(true);
        const response = await getPayments(status, currentPage);
        setPaymentsData(response);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchPaymentsData();
  }, [status, currentPage]);

  useEffect(() => {
    setCurrentPage(0);
  }, [status]);

  const handleMerchantId = (value: number) => {
    setMerchantId(value);
    navigate(`/home/payments?status=${status}&merchantId=${value}`);
  };

  const PaymentsSection = () => (
    <div className="mt-4 flex flex-grow flex-col justify-between pb-8">
      {isLoading ? (
        <IconLoader size={72} className="m-auto mt-14 animate-spin" />
      ) : (
        <ResponsiveTableWithPagination
          columns={columns}
          data={paymentsData}
          handlePageChange={setCurrentPage}
        />
      )}
    </div>
  );

  return (
    <div className="flex flex-grow dark:bg-black dark:text-white">
      <div className="flex flex-grow p-4 pb-0">
        <div className="flex-grow flex flex-col rounded-lg bg-[#212632]">
          {/* TOPBAR  */}
          <div className="flex items-center justify-between border-b border-b-slate-500 p-4 hidden md:flex">
            <div className="flex items-center gap-4">
              <h2 className="py-[6px] text-xl">
                See all your payments listed here
              </h2>
            </div>
          </div>

          <div className="flex items-center flex-col justify-between p-3 md:flex-row justify-center gap-2">
            <ExcelReport reportType="ACH" handleMerchantId={handleMerchantId} />

            <div className="flex flex-row items-center md:flex-row">
              <div className="flex justify-center md:justify-end p-2">
                <NavigationButtons
                  buttonsList={buttonsList}
                  value={status}
                  setValue={handleStatusTypeChange}
                />
              </div>
            </div>
          </div>

          <PaymentsSection />
        </div>
      </div>
    </div>
  );
};

export default Payments;
