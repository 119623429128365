import {
  IconEdit,
  IconLoader,
  IconMenu,
  IconMenu2,
  IconMessage,
  IconSend,
} from "@tabler/icons-react";
import { getAllMerchants, getMerchantById } from "api/merchant";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MerchantContactCard from "../merchantContactCard/merchantContactCard";
import { getMessagesByMerchant, sendMessage } from "api/chat";
import { Button } from "components/ui/button";
import SenderMessageBubble from "../messagesBubbles/senderMessageBubble";
import RecipientMessageBubble from "../messagesBubbles/recipientMessageBubble";
import { closeChat, openChat } from "store/utilsSlice";

const ChatBubble = () => {
  const [showContacts, setShowContacts] = useState(false);
  const [merchantList, setMerchantList] = useState<any>(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<any>([]);
  const [merchantRecipient, setMerchantRecipient] = useState<any>({
    name: "Payment Vault Admin",
    contactEmail: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const [showRecipientMenu, setShowRecipientMenu] = useState(false);

  const dispatch = useDispatch();
  const isChatOpen = useSelector((state: any) => state.utils.isChatOpen);
  const [refreshMessages, setRefreshMessages] = useState(false);
  // if user is master admin, hide contacts
  const user = useSelector((state: any) => state.user.user);
  const inputRef = useRef<HTMLInputElement>(null);
  const chatBodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    retrieveMerchantList();
  }, []);

  const retrieveMerchantList = async () => {
    try {
    
      if (user?.role !== "MASTER_ADMIN") {
        setMerchantRecipient({
          name: "Payment Vault Admin",
          contactEmail: "",
          id: user?.merchantId,
        });
      } else {
        const response = await getAllMerchants();
        setMerchantList(response?.content);
        setMerchantRecipient(
          merchantRecipient?.id ? merchantRecipient?.id : response?.content[0]
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages]);



  useEffect(() => {
    retrieveMessages(merchantRecipient?.id, true);
  }, [merchantRecipient?.id]);


  useEffect(() => {
    retrieveMessages(merchantRecipient?.id, false);
  }, [refreshMessages,isChatOpen]);





  const retrieveMessages = async (merchantId: number, useLoading:boolean) => {
    if (!merchantId) return;
    try {
      useLoading && setIsLoading(true);
      const response = await getMessagesByMerchant(merchantId);
      setMessages(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // const retrieveMessagesWithoutLoading = async () => {
  //   const response = await getMessagesByMerchant(merchantRecipient?.id);
  //   setMessages(response);
  // };

  const isCardActive = (merchant: any) => {
    if (merchantRecipient?.id === merchant?.id) return true;
    else return false;
  };

  const sendChatMessage = async (message: string, receiverId: number) => {
    if (user?.role === "MASTER_ADMIN") {
      try {
        const payload = {
          message,
          receiverId,
        };
        const response = await sendMessage(payload);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const payload = {
          message,
        };
        const response = await sendMessage(payload);
      } catch (error) {
        console.log(error);
      }
    }
    setRefreshMessages((prev) => !prev);
  };

  return (
    <>
      <div
        onClick={() => {
          if (isChatOpen) {
            dispatch(closeChat());
          } else dispatch(openChat());
        }}
        className="fixed bottom-10 right-0 lg:right-12 z-60 cursor-pointer rounded-full bg-yellow-500 p-2"
      >
        <IconMessage size={44} />
      </div>
      <div
        className={`${isChatOpen ? "mr-12 scale-100" : "scale-0"} fixed bottom-20 right-0 lg:right-16 lg:bottom-14 z-50 flex origin-bottom-right items-center rounded-xl duration-300`}
      >
        {/* Contact List */}
        {user?.role === "MASTER_ADMIN" && (
          <div
            onMouseOver={() => setShowContacts(true)}
            onMouseOut={() => setShowContacts(false)}
            className={`${showContacts ? "translate-x-0" : "translate-x-[80%]"} ${user?.role !== "MASTER_ADMIN" && "hidden"} relative z-40 w-72 hidden lg:block rounded-bl-2xl rounded-tl-2xl bg-white shadow-md shadow-black transition duration-500`}
          >
            <div className="flex items-center gap-6 px-4 py-2 text-2xl font-bold">
              <IconMenu2 /> Merchants
            </div>
            <div className="max-h-[300px] overflow-y-auto overflow-x-hidden">
              {/* Contact Card */}
              {merchantList?.map((merchant: any) => (
                <MerchantContactCard
                  className={`${isCardActive(merchant) ? "bg-gray-200" : ""} cursor-pointer rounded-b-2xl duration-300`}
                  onClick={() => setMerchantRecipient(merchant)}
                  key={merchant.id}
                  merchant={merchant}
                />
              ))}
            </div>
          </div>
        )}

        <div className="relative z-50 w-72">
          {/* Messenger Header  */}
          <div className="flex gap-4 rounded-t-2xl bg-white p-2">
            {" "}
            <Avatar>
              <AvatarImage src="https://github.com/shadcn.png" />
              <AvatarFallback>CN</AvatarFallback>
            </Avatar>
            <div>
              <p>{merchantRecipient?.name}</p>
              <p className="text-gray-500">{merchantRecipient?.contactEmail}</p>
              <p
                onClick={() => setShowRecipientMenu(!showRecipientMenu)}
                className="cursor-pointer flex"
              >
                <IconEdit /> change recipient
              </p>
            </div>
          </div>

          {/* Messenger Body  */}
          {showRecipientMenu ? (
            <div className="h-[27.5rem] space-y-4 overflow-auto rounded-b-2xl duration-300 bg-gray-200 px-2 pb-2">
              <ChangeRecipientMenu
                merchantList={merchantList}
                setMerchantRecipient={setMerchantRecipient}
                isCardActive={isCardActive}
                setShowRecipientMenu={setShowRecipientMenu}
              />
            </div>
          ) : isLoading ? (
            <div className="h-[27.5rem] flex justify-center items-center space-y-4 overflow-auto rounded-b-2xl duration-300 bg-gray-200 px-2 pb-2">
              <div className=" animate-spin">
                <IconLoader size={72} />
              </div>{" "}
            </div>
          ) : (
            <>
              <div
                ref={chatBodyRef}
                className="h-96 space-y-4 overflow-auto bg-gray-200 px-2 pb-2"
              >
                {messages?.map((message: any, index: any) =>
                  message?.role === user?.role ? (
                    <SenderMessageBubble
                      message={message?.message}
                      date={message?.messageDate}
                      key={index}
                    />
                  ) : (
                    <RecipientMessageBubble
                      message={message?.message}
                      date={message?.messageDate}
                      key={index}
                    />
                  )
                )}
              </div>

              <div className="flex rounded-b-2xl bg-white p-2">
                <form
                  className="flex w-full justify-center"
                  onSubmit={(e) => {
                    e.preventDefault();
                    sendChatMessage(message, merchantRecipient?.id);
                    //clear the input ref value
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                >
                  <input
                    ref={inputRef}
                    type="text"
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type your message here!"
                    className="w-full rounded-2xl bg-white px-2 py-1 text-black shadow-md shadow-gray-200"
                  />
                  <Button
                    type="submit"
                    className="ml-2 rounded-2xl bg-black px-2 py-1 text-white"
                  >
                    <IconSend />
                  </Button>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const ChangeRecipientMenu = ({
  merchantList,
  isCardActive,
  setMerchantRecipient,
  setShowRecipientMenu,
}: any) => (
  <div className=" overflow-y-auto  overflow-x-hidden rounded-b-2xl">
    {/* Contact Card */}
    {merchantList?.map((merchant: any) => (
      <MerchantContactCard
        className={`${isCardActive(merchant) ? "" : ""} cursor-pointer round rounded-b-2xl duration-300`}
        onClick={() => {
          setMerchantRecipient(merchant);
          setShowRecipientMenu(false);
        }}
        key={merchant.id}
        merchant={merchant}
      />
    ))}
  </div>
);

export default ChatBubble;
