import { zodResolver } from "@hookform/resolvers/zod";
import { getExchangeCompanies, getProcessingCompanies } from "api/management";
import { createMerchant } from "api/merchant";
import { Button } from "components/ui/button";
import { Checkbox } from "components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import { resetForm, selectForm, setForm } from "store/formSlice";
import { z } from "zod";

const formSchema = z.object({
  feeDescription: z.string().max(50).optional(),
  feePercentage: z.string().optional(),
  processCompanyId: z.string().optional(),
  exchangeId: z.string().optional(),
});

const StepThreeMerchantForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formState = useSelector((state: RootState) => selectForm(state));

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      feeDescription: undefined,
      feePercentage: undefined,
      processCompanyId: undefined,
      exchangeId: undefined,
    },
  });

  const [processingCompanies, setProcessingCompanies] = useState([]);
  const [exchangeCompanies, setExchangeCompanies] = useState([]);
  const [cryptoAccess, setCryptoAccess] = useState(false);

  useEffect(() => {
    const fetchProcessingCompanies = async () => {
      try {
        const response = await getProcessingCompanies();
        setProcessingCompanies(response);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchExchangeCompanies = async () => {
      try {
        const response = await getExchangeCompanies();
        setExchangeCompanies(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchProcessingCompanies();
    fetchExchangeCompanies();
  }, []);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const newValues = {
      ...formState,
      ...values,
      processCompanyId: values.processCompanyId
        ? parseInt(values.processCompanyId)
        : null,
      exchangeId: values.exchangeId ? parseInt(values.exchangeId) : null,
      cryptoAccess: cryptoAccess,
    };
    try {
      const response = await createMerchant(newValues);
      dispatch(resetForm());
      navigate("/home/merchants");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="m-auto mb-12 mt-1">
      <div className="my-12 flex items-center justify-start gap-4 pl-12">
        <Checkbox
          onCheckedChange={(isChecked: boolean) => {
            setCryptoAccess(isChecked);
          }}
        />{" "}
        <h2 className="text-xl">Crypto Access</h2>
      </div>{" "}
      <Form {...form}>
        <form className="flex-grow" onSubmit={form.handleSubmit(onSubmit)}>
          <div className={` ${cryptoAccess ? "" : "hidden"} `}>
            <h2 className="mb-4 text-center text-2xl font-bold">
              Payment Vault Fees
            </h2>
            <div className="flex flex-col lg:flex-row lg:justify-between">
              <div className="flex lg:w-1/2 flex-col items-center gap-y-2">
                <FormField
                  control={form.control}
                  name={`feeDescription`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Description</FormLabel>
                      <FormControl>
                        <Input
                          className="w-[280px] lg:w-[350px]"
                          placeholder="Enter description"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name={`feePercentage`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Percentage</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          className="w-[280px] lg:w-[350px]"
                          placeholder="Enter percentage"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex lg:w-1/2 flex-col items-center gap-y-2">
                <FormField
                  control={form.control}
                  name="processCompanyId"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Company Process</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger className="w-[280px] lg:w-[350px]">
                            <SelectValue placeholder="Processing company" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {processingCompanies.map((company: any) => (
                            <SelectItem
                              key={company.id}
                              value={company.id.toString()}
                            >
                              {company.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>

                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="exchangeId"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Exchange</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger className="w-[280px] lg:w-[350px]">
                            <SelectValue placeholder="Exchange company" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {exchangeCompanies.map((company: any) => (
                            <SelectItem
                              key={company.id}
                              value={company.id.toString()}
                            >
                              {company.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="mt-6 flex justify-center">
            <Button
              type="submit"
              variant={"secondary"}
              className="w-[280px] lg:w-[350px] border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white"
            >
              Submit
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default StepThreeMerchantForm;
