// src/store/index.ts

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./userSlice";
import formReducer from "./formSlice";
import utilsReducer from "./utilsSlice";

const rootReducer = combineReducers({
  user: userReducer,
  form: formReducer,
  utils: utilsReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "form"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export default store;
