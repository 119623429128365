import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import React from 'react'

const MerchantContactCard = ({ merchant, onClick, className }: any) => {
  return (
    <div onClick={onClick} className={`flex gap-4 p-2 ${className}`}>
      {' '}
      <Avatar className='h-14 w-14'>
        <AvatarImage src='https://github.com/shadcn.png' />
        <AvatarFallback>CN</AvatarFallback>
      </Avatar>
      <div>
        <p>{merchant?.name}</p>
        <p className='text-gray-500'>{merchant?.contactEmail}</p>
      </div>
    </div>
  )
}

export default MerchantContactCard
