"use client";
import React, { useEffect, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button } from "../../ui/button";
import axios, { AxiosError } from "axios";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";
import { Input } from "../../ui/input";
import { register } from "api/auth";
import { useToast } from "components/ui/use-toast";
import { useSelector } from "react-redux";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { getAllMerchants, getMerchantById } from "api/merchant";
import { updateUser } from "api/agents";
import { useNavigate } from "react-router-dom";
import { Roles } from "roles/roles";

const CreateAgentForm = ({ item = null }: { item?: any }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [merchants, setMerchants] = useState<Object[]>([]);

  const user = useSelector((state: any) => state?.user?.user);

  useEffect(() => {
    const fetchMerchants = async () => {
      if (user?.role === Roles.MASTER_ADMIN) {
        const response = await getAllMerchants();
        setMerchants(response?.content);
      } else {
        const response = await getMerchantById(user?.merchantId);
        setMerchants([response]);
      }
    };
    fetchMerchants();
  }, []);

  const formSchema = z.object({
    firstName: z
      .string()
      .min(2, "First name is required")
      .max(50, "First name must be at most 50 characters"),
    lastName: z
      .string()
      .min(2, "Last name is required")
      .max(50, "Last name must be at most 50 characters"),
    email: z.string().email("Invalid email address"),
    userName: z
      .string()
      .min(2, "Username is required")
      .max(50, "Username must be at most 50 characters"),
    phoneNumber: z.string().min(2, "Phone number is required"),
    password: item?.id
      ? z.string()
      : z
          .string()
          .min(1, "Password is required")
          .max(50, "Password must be at most 50 characters"),
    role: z
      .string()
      .min(1, "Role is required")
      .max(50, "Role must be at most 50 characters"),
    merchantId: z.string().min(1, "Merchant is required"),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: item?.firstName || "",
      lastName: item?.lastName || "",
      userName: item?.username || "",
      email: item?.email || "",
      phoneNumber: item?.phoneNo || "",
      password: "",
      role: item?.role || "",
      merchantId: item?.merchantId?.toString() || NaN,
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      const registerData = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        userName: values?.userName,
        phoneNo: values?.phoneNumber,
        password: values?.password,
        role: values?.role,
        merchantId: +values.merchantId,
      };

      if (item?.id) {
        const updateData = {
          id: item?.id,
          ...registerData,
        };
        await updateUser(updateData).then((res) => {
          if (res?.status === 200) {
            toast({
              title: `USER UPDATED!`,
            });
          }
        });
      } else {
        await register(registerData).then((res) => {
          navigate("/home/agents");
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorDetails = error.response?.data?.details;
        if (item) {
          toast({
            title: `ERROR: Failed to update agent!`,
            description: `REASON: ${errorDetails || error.message}`,
          });
        } else {
          toast({
            title: `ERROR: Failed to create new  agent!`,
            description: `REASON: ${errorDetails || error.message}`,
          });
        }
      } else {
        toast({
          title: "An unexpected error occurred. Please try again later.",
        });
      }
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Form {...form}>
        <form
          className="space-y-3 m-auto max-w-[60rem]"
          onSubmit={form?.handleSubmit(onSubmit)}
        >
          <div className="flex flex-col lg:flex-row mt-12 gap-7">
            <div className="space-y-3 flex-grow">
              <FormField
                control={form?.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="dark:text-white">
                      First Name
                    </FormLabel>
                    <FormControl>
                      <Input
                        className="dark:text-white"
                        placeholder="First Name"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form?.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="dark:text-white">Last Name</FormLabel>
                    <FormControl>
                      <Input
                        className="dark:text-white"
                        placeholder="Last Name"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form?.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="dark:text-white">Email</FormLabel>
                    <FormControl>
                      <Input
                        className="dark:text-white"
                        placeholder="Email"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form?.control}
                name="userName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="dark:text-white">Username</FormLabel>
                    <FormControl>
                      <Input
                        className="dark:text-white"
                        placeholder="Username"
                        autoComplete="off"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="space-y-3 flex-grow">
              <FormField
                control={form?.control}
                name="phoneNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="dark:text-white">
                      Phone Number
                    </FormLabel>
                    <FormControl>
                      <Input
                        className="dark:text-white"
                        placeholder="Phone Number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {!item && (
                <FormField
                  control={form?.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="dark:text-white">
                        Password
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="password"
                          className="dark:text-white"
                          autoComplete="off"
                          placeholder="password"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              <FormField
                control={form?.control}
                name="role"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="dark:text-white">Role</FormLabel>
                    <FormControl>
                      <Select
                        onValueChange={field.onChange}
                        value={field?.value}
                      >
                        <SelectTrigger className="w-full bg-slate-500 text-black">
                          <SelectValue
                            style={{ textDecorationColor: "black" }}
                            placeholder={"Role"}
                          />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="MERCHANT_ADMIN">
                            Merchant Admin
                          </SelectItem>
                          <SelectItem value="MERCHANT_USER">
                            Merchant User
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form?.control}
                name="merchantId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="dark:text-white">Merchant</FormLabel>
                    <FormControl>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value.toString()}
                      >
                        <SelectTrigger className="w-full bg-slate-500 text-black">
                          <SelectValue
                            style={{ textDecorationColor: "black" }}
                            placeholder={"Merchant"}
                          />
                        </SelectTrigger>
                        <SelectContent>
                          {merchants?.map((merchant: any) => (
                            <SelectItem
                              key={merchant?.id}
                              value={merchant?.id.toString()}
                            >
                              {merchant?.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="flex justify-center pt-2 pb-12">
            <Button
              variant={"secondary"}
              className="w-full lg:w-[350px] border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white"
            >
              Submit{" "}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default CreateAgentForm;
