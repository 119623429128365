import React from "react";
import Layout from "../../layout";
import { useLocation } from "react-router-dom";
import EditWithdrawalForm from "components/forms/editWithdrawalForm/editWithdrawalForm";

const EditWithdrawal = () => {
  const location = useLocation();
  const { withdrawal } = location?.state;
  return (
   
      <div className="flex flex-grow dark:bg-black dark:text-white">
        <div className="flex flex-grow p-4 pb-0">
          <div className="flex flex-col flex-grow rounded-lg bg-[#212632] pb-12">
            <div className="flex items-center justify-between border-b border-b-slate-500 p-4">
              <h2 className="py-[6px] text-xl">
                Edit your
                <span className="font-bold text-yellow-500"> withdrawal</span>
              </h2>
            </div>

            <EditWithdrawalForm withdrawal={withdrawal} />
          </div>
        </div>
      </div>
 
  );
};

export default EditWithdrawal;
