import { updatePaymentStatus } from "api/paymentsController";
import SelectPaymentStatus from "components/selectPaymentStatus/selectPaymentStatus";
import PrimaryButton from "components/shared/buttons/primaryButton/primaryButton";
import { Separator } from "components/ui/separator";
import { beautifyNumber } from "lib/utils";
import Layout from "pages/(root)/home/layout";
import { useLocation, useNavigate } from "react-router-dom";
import { formatNumberAsDigit } from "lib/utils";
import { IconBackground, IconDownload } from "@tabler/icons-react";
import { backIn } from "framer-motion";
import {
  getPayments,
  getPaymentsDownloadReceipt,
} from "api/paymentsController";
import { useHandleAxiosError } from "hooks/useAxiosHandlerError";
const styleClass =
  "xmb-4 flex h-10 w-[280px] lg:w-[350px] rounded-md border border-input bg-background px-3 py-2 text-right text-sm text-xl ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50";

const ViewPaymentMerchant = () => {
  const location = useLocation();
  const { payment } = location.state;
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_URL;
  const { handleAxiosErrors } = useHandleAxiosError();

  const handleDownload = async () => {
    try {
      await getPaymentsDownloadReceipt(payment?.id);
    } catch (error) {
      handleAxiosErrors({
        toastTitle: "Faild to download receipt!",
        error: error,
      });
    }
  };

  return (
    <div className="flex flex-grow dark:bg-black dark:text-white">
      <div className="flex flex-grow p-4 pb-0">
        <div className="flex-grow rounded-lg bg-[#212632]">
          {/* TOPBAR  */}
          <div className="flex items-center justify-between border-b border-b-slate-500 p-4">
            <div className="flex items-center gap-4">
              <h2 className="py-[6px] text-xl">View your payment</h2>
            </div>
          </div>
          {/* Content */}
          <div className="mx-4 mb-3 mt-5">
            <div className="flex items-center w-full justify-center">
              {payment?.receiptFileName && (
                <div
                  className="mb-2 cursor-pointer flex flex-row items-center justify-between border p-4 border-gray-300 bg-gray-500 font-bold text-black 
        max-w-full sm:max-w-xs md:max-w-md lg:max-w-lg
        text-xs sm:text-sm md:text-base"
                  onClick={handleDownload}
                >
                  <div className="truncate w-full">
                    Saved receipt: {payment?.receiptFileName}
                  </div>
                  <IconDownload className="w-6 h-6 sm:w-6 sm:h-6 md:w-8 md:h-8 ml-2" />
                </div>
              )}
            </div>

            <div className="flex flex-col lg:flex-row items-center pb-6 justify-center gap-12">
              <div>
                <p className="mb-2">Routing Number</p>
                <div className={styleClass}>{payment?.abartn}</div>
                <p className="mb-2">Account Number</p>
                <div className={styleClass}>{payment?.accountNumber}</div>
                <p className="mb-2">Account Type</p>
                <div className={styleClass}>{payment?.accountType}</div>
                <p className="mb-2">Address City</p>
                <div className={styleClass}>{payment?.addressCity}</div>
                <p className="mb-2">Country</p>
                <div className={styleClass}>US</div>
                <p className="mb-2">Address First Line</p>
                <div className={styleClass}>{payment?.addressFirstLine}</div>
                <p className="mb-2">Address Post Code</p>
                <div className={styleClass}>{payment?.addressPostCode}</div>
                <p className="mb-2">Address State</p>
                <div className={styleClass}>{payment?.addressState}</div>
                <p className="mb-2">Amount</p>
                <div className={styleClass}>
                  {formatNumberAsDigit(payment?.amount)}
                </div>
                <p className="mb-2">Amount Currency</p>
                <div className={styleClass}>
                  {/* {beautifyNumber(payment?.amountCurrency)} */}
                  SOURCE
                </div>
              </div>
              <div>
                <p className="mb-2">Date of Birth</p>
                <div className={styleClass}>{payment?.dob}</div>
                <p className="mb-2">ID</p>
                <div className={styleClass}>{payment?.id}</div>
                <p className="mb-2">Recipient Full Name</p>
                <div className={styleClass}>{payment?.name}</div>
                <p className="mb-2">Payment Reference</p>
                <div className={styleClass}>{payment?.paymentReference}</div>
                <p className="mb-2">Receiver Type</p>
                <div className={styleClass}>PERSON</div>
                <p className="mb-2">Recipient Email</p>
                <div className={styleClass}>{payment?.recipientEmail}</div>
                <p className="mb-2">Source Currency</p>
                <div className={styleClass}>USD</div>
                <p className="mb-2">Target Currency</p>
                <div className={styleClass}>USD</div>
                <p className="mb-2">Type</p>
                <div className={styleClass}></div>

                <p className="mb-2">Status</p>

                {payment?.status === "COMPLETE" ? (
                  <div className={styleClass}>{payment?.status}</div>
                ) : (
                  <SelectPaymentStatus
                    currentStatus={payment?.status}
                    onChange={(newStatus: string) => {
                      updatePaymentStatus(payment?.id, newStatus);
                      navigate("/home/payments");
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPaymentMerchant;
