import {
  IconCircleXFilled,
  IconMenu2,
  IconArrowBadgeLeft
} from "@tabler/icons-react";
import { Button } from "components/ui/button";
import { motion } from "framer-motion";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import store from "store";
import Sidebar from "../sidebar/sidebar";

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const state = store.getState() as any;
  const user = state.user?.user;

  const location = useLocation();
  const entityType = location.pathname.split("/")[2];
  const isActive = (targetEntityType: string) =>
    targetEntityType === entityType;

  const menuVariants = {
    open: {
      x: 0,
      transition: {
        type: "Tween",
        ease: "easeInOut",
      },
    },
    closed: {
      x: "-100%", 
      transition: {
        type: "Tween",
        ease: "easeInOut",
      },
    },
  };

  const overlayVariants = {
    open: {
      opacity: 1,
      display: "block",
      transition: { duration: 0.3 },
    },
    closed: {
      opacity: 0,
      transition: { duration: 0.3 },
      transitionEnd: {
        display: "none",
      },
    },
  };

  return (
    <>
      <Button
        variant={"outline"}
        onClick={() => {
          if (!isOpen) setIsOpen(true);
        }}
        className="relative z-[60] gap-2 border-yellow-700 lg:hidden"
      >
        <IconMenu2 color="rgb(234 179 8)" size={28} />
      </Button>
      <div
        className={` ${isOpen ? "" : "pointer-events-none"} fixed left-0 top-0 z-[70] h-screen w-full overflow-x-hidden`}
      >
        <motion.div
          initial={false}
          animate={isOpen ? "open" : "closed"}
          variants={menuVariants}
          className={`fixed left-0 top-0 z-[80] h-full w-3/4 shadow-md dark:bg-[#212632]`}
        >
          <div className="absolute right-4 top-4">
            <IconArrowBadgeLeft onClick={() => setIsOpen(false)} size={30} />
          </div>
          {/* Menu content here */}
          <div className="flex">{returnUserSidebar(user)}</div>
        </motion.div>
        <motion.div
          initial={false}
          animate={isOpen ? "open" : "closed"}
          variants={overlayVariants}
          className={`${isOpen ? "block" : "hidden"} fixed left-0 top-0 z-[60] h-full w-full bg-black bg-opacity-50`}
          onClick={() => setIsOpen(false)}
        />
      </div>
    </>
  );
};

const returnUserSidebar = (user: any) => {
  return <Sidebar />
};

export default HamburgerMenu;
