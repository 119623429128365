import { REST } from './axiosConfig'

export const getMessagesByMerchant = async (merchantId: number) => {
  try {
    const response = await REST.get(`/chat/messages/${merchantId}~`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const getMessagesWithAdmin = async () => {
  try {
    const response = await REST.get('/chat/messages/~')
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const sendMessage = async (payload: {
  message: string
  receiverId?: number
}) => {
  try {
    const response = await REST.post('/chat/sendMessage', payload)
    return
  } catch (error) {
    console.log(error)
  }
}
