import { REST } from './axiosConfig'

export const login = async (creditentials: {
  usernameOrEmail: string
  password: string
}) => {
  try {
    const response = await REST.post(`/auth/login`, creditentials)
    return response.data
  } catch (error) {
    throw error
  }
}

export const register = async (userData: {
  firstName: string
  lastName: string
  email: string
  userName: string
  phoneNo: string
  password: string
  role: string
  merchantId: number
}) => {
  try {
    const response = await REST.post(`/auth/register`, userData)
    return response.data
  } catch (error) {
    throw error
  }
}
