import ProcessingCompanyForm from "components/forms/processingCompanyForm/processingCompanyForm";
import Layout from "pages/(root)/home/layout";
import React from "react";
import { useLocation } from "react-router-dom";

const CreateProcessingCompany = () => {
  return (
   
      <div className="flex flex-grow dark:bg-black dark:text-white">
        <div className="flex flex-grow p-4 pb-0">
          <div className="flex-grow rounded-lg bg-[#212632]">
            {/* TOPBAR  */}
            <div className="flex items-center justify-between border-b border-b-slate-500 p-4">
              <div className="flex items-center gap-4">
                <h2 className="py-[6px] text-xl">
                  Create a new processing company{" "}
                </h2>
              </div>
            </div>

            {/* Content */}
            <ProcessingCompanyForm />
          </div>
        </div>
      </div>
   
  );
};

export default CreateProcessingCompany;
