import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import { useDispatch, useSelector } from "react-redux";
import { resetForm, selectForm, setForm } from "store/formSlice";
import { RootState } from "store";
import { useEffect } from "react";
import { createWithdrawal } from "api/paymentsController";
import SelectWithdrawalCurrency from "components/selectFields/selectWithdrawalCurrency/selectWithdrawalCurrency";
import { useHandleAxiosError } from "hooks/useAxiosHandlerError";
import { NumericFormat } from "react-number-format";
import { formatStringDigitToNumber } from "lib/utils";
const formSchema = z.object({
  beneficiaryName: z.string().min(1, "Beneficiary Name is required").max(100),
  beneficiaryAddress: z
    .string()
    .min(1, "Beneficiary Address is required")
    .max(200),
  amount: z.string().min(1, "Amount is required"),
  currency: z.enum(["BTC", "GBP", "USD", "EUR"]), // Assuming these are the allowed currencies
  bankName: z.string().min(1, "Bank Name is required").max(100),
  bankAddress: z.string().min(1, "Bank Address is required").max(200),
  accountNumberOrIban: z
    .string()
    .min(1, "Account Number or IBAN is required")
    .max(50),
  swiftOrBic: z.string().min(1, "SWIFT or BIC is required").max(20),
  bankReferenceOrMemo: z.string(),
  internalReferenceOrMemo: z.string(),
  others: z.string(),
});

const CreateWithdrawalForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formState = useSelector((state: RootState) => selectForm(state));
  const { handleAxiosErrors } = useHandleAxiosError();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      beneficiaryName: "",
      beneficiaryAddress: "",
      amount: "",
      currency: "BTC",
      bankName: "",
      bankAddress: "",
      accountNumberOrIban: "",
      swiftOrBic: "",
      bankReferenceOrMemo: "",
      internalReferenceOrMemo: "",
      others: "",
    },
  });

  useEffect(() => {
    dispatch(resetForm());
  }, []);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      await createWithdrawal({
        ...values,
        status: "NEW",
        amount: +formatStringDigitToNumber(values.amount),
      }).then((res) => {
        if (res) {
          navigate("/home/withdrawals");
        }
      });
    } catch (error) {
      handleAxiosErrors({
        toastTitle: "Failed to create new withdrawal!",
        error: error,
      });
    }
  };

  return (
    <div className="m-auto mt-12 max-w-full px-4 sm:px-6 lg:px-8">
  <Form {...form}>
    <div className="flex justify-center pb-12">
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8 w-full max-w-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4">
          <FormField
            control={form.control}
            name="beneficiaryName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Beneficiary Name</FormLabel>
                <FormControl>
                  <Input
                    className="w-full"
                    placeholder="Beneficiary Name"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="beneficiaryAddress"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Beneficiary Address</FormLabel>
                <FormControl>
                  <Input
                    className="w-full"
                    placeholder="Beneficiary Address"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="amount"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="dark:text-white">Amount</FormLabel>
                <FormControl>
                  <NumericFormat
                    {...field}
                    customInput={Input}
                    thousandSeparator
                    decimalScale={3}
                    allowNegative={false}
                    className="w-full"
                    placeholder="Amount"
                    onValueChange={(values) => {
                      field.onChange(+values.value);
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="currency"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Currency</FormLabel>
                <FormControl>
                  <SelectWithdrawalCurrency field={field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="bankName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Bank Name</FormLabel>
                <FormControl>
                  <Input
                    className="w-full"
                    placeholder="Bank Name"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="bankAddress"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Bank Address</FormLabel>
                <FormControl>
                  <Input
                    className="w-full"
                    placeholder="Bank Address"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="accountNumberOrIban"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Account Number / IBAN</FormLabel>
                <FormControl>
                  <Input
                    className="w-full"
                    placeholder="Account Number / IBAN"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="swiftOrBic"
            render={({ field }) => (
              <FormItem>
                <FormLabel>SWIFT / BIC</FormLabel>
                <FormControl>
                  <Input
                    className="w-full"
                    placeholder="SWIFT / BIC"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="bankReferenceOrMemo"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Bank Reference / Memo</FormLabel>
                <FormControl>
                  <Input
                    className="w-full"
                    placeholder="Bank Reference / Memo"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="internalReferenceOrMemo"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Internal Reference / Memo</FormLabel>
                <FormControl>
                  <Input
                    className="w-full"
                    placeholder="Internal Reference / Memo"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="others"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Others</FormLabel>
                <FormControl>
                  <Input
                    className="w-full"
                    placeholder="Other Details"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex justify-center">
          <Button
            variant={"secondary"}
            className="w-full border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white"
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  </Form>
</div>

  );
};

export default CreateWithdrawalForm;
