"use client";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button } from "components/ui/button";
import { useToast } from "components/ui/use-toast";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import { createProcessingCompany } from "api/platformManagement";

const formSchema = z.object({
  name: z.string().min(1).max(50),
  companyFee: z.string().min(1).max(50),
});

const ProcessingCompanyForm = () => {
  const { toast } = useToast();
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      companyFee: "",
    },
  });

  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      await createProcessingCompany({
        ...values,
        companyFee: Number(values.companyFee),
      }).then((res) => {
        if (res?.status === 200) {
          navigate("/home/processing-companies");
        }
      });
    } catch (error) {
      toast({
        title: "Failed to create new processing company!",
      });
    }
  }

  

  return (
    <div className="m-auto mt-12 max-w-sm">
      <Form {...form}>
        <div className="flex justify-center">
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="pb-12 space-y-8"
          >
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input
                      className="w-[280px] lg:w-[350px]"
                      placeholder="Name"
                      {...field}
                    />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="companyFee"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Company Fee (%)</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      className="w-[280px] lg:w-[350px]"
                      placeholder="Company Fee"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              variant={"secondary"}
              className="w-[280px] lg:w-[350px] border bg-yellow-500 font-bold  text-black hover:border-yellow-500 hover:text-white"
            >
              Submit{" "}
            </Button>
          </form>
        </div>
      </Form>
    </div>
  );
};

export default ProcessingCompanyForm;
