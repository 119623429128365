"use client";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import { useDispatch, useSelector } from "react-redux";
import { resetForm, selectForm, setForm } from "store/formSlice";
import { RootState } from "store";
import { useEffect } from "react";

const formSchema = z.object({
  name: z.string().min(1).max(50),
  address: z.string().min(1).max(50),
  registrationNumber: z.string().min(1).max(50),
  contactEmail: z.string().email(),
});

const StepOneMerchantForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formState = useSelector((state: RootState) => selectForm(state));

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      address: "",
      registrationNumber: "",
      contactEmail: "",
    },
  });
  useEffect(() => {
    dispatch(resetForm());
  }, []);

  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
    dispatch(setForm(values));
    navigate("/home/merchants/create?step=2");
  }

  return (
    <div className="m-auto mt-12 max-w-sm">
      <Form {...form}>
        <div className="flex justify-center pb-12">
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input
                      className="w-[280px] lg:w-[350px]"
                      placeholder="Name"
                      {...field}
                    />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Address</FormLabel>
                  <FormControl>
                    <Input
                      className="w-[280px] lg:w-[350px]"
                      placeholder="Address"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="registrationNumber"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Registration Number</FormLabel>
                  <FormControl>
                    <Input
                      className="w-[280px] lg:w-[350px]"
                      placeholder="Registration Number"
                      {...field}
                    />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="contactEmail"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Contact Email</FormLabel>
                  <FormControl>
                    <Input
                      className="w-[280px] lg:w-[350px]"
                      placeholder="Contact Email"
                      {...field}
                    />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              variant={"secondary"}
              className="w-[280px] lg:w-[350px] border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white"
            >
              Submit{" "}
            </Button>
          </form>
        </div>
      </Form>
    </div>
  );
};

export default StepOneMerchantForm;
