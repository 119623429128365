import axios from "axios";
import store from "store";
const baseUrl = process.env.REACT_APP_API_URL;

export const REST = axios.create({
  baseURL: baseUrl,
  timeout: 100000,
  headers: {
    accept: "*/*",
    "Content-Type": `application/json`,
  },
});

export const RESTMultipart = axios.create({
  baseURL: baseUrl,
  timeout: 100000,
  headers: {
    accept: "*/*",
    "Content-Type": `multipart/form-data`,
  },
});

export const RESTLinks = axios.create({
  baseURL: baseUrl,
  timeout: 100000,
  headers: {
    accept: "*/*",
    responseType: "arraybuffer",
  },
});

RESTLinks.interceptors.request.use(
  async (config) => {
    const state = store.getState() as any;
    const accessToken = state.user?.accessToken;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

REST.interceptors.request.use(
  async (config) => {
    const state = store.getState() as any;
    const accessToken = state.user?.accessToken;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

RESTMultipart.interceptors.request.use(
  async (config: any) => {
    const state = store.getState() as any;

    const accessToken = state.user?.accessToken;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);
