import { useState } from "react";
import { DatePicker } from "components/datePicker/datePicker";
import { Button } from "components/ui/button";
import { UserState } from "store/userStore";
import { useSelector } from "react-redux";
import SelectMerchantId from "components/selectMerchantId/selectMerchantId";
import { Roles } from "roles/roles";
import { generateDefaultReport } from "api/reportscontroller";
import { useHandleAxiosError } from "hooks/useAxiosHandlerError";
import { useToast } from "components/ui/use-toast";

const ExcelReport = ({ reportType, handleMerchantId }: any) => {
  const { handleAxiosErrors } = useHandleAxiosError();
  const { toast } = useToast();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());
  const [isVisible, setIsVisible] = useState(false);
  const user = useSelector((state: UserState) => state.user.user);
  const [merchantId, setMerchantId] = useState<number>(
    user?.role === Roles?.MASTER_ADMIN ? null : user?.merchantId
  );

  const handleGenerateReport = async () => {
    const payload = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      merchantId: +merchantId,
      accountType: reportType,
    };
    try {
      await generateDefaultReport(payload);
    } catch (error) {
      handleAxiosErrors({
        toastTitle:"Failed to generate raport!",
        error:error
      })
    }
  };

  const formatDate = (date: any) => {
    const year = date?.getFullYear();
    const month = String(date?.getMonth() + 1)?.padStart(2, "0");
    const day = String(date?.getDate())?.padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const handleToggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleMerchant = (value: number) => {
    setMerchantId(value);
    if (handleMerchantId) {
      handleMerchantId(value);
    }
  };



  return (
    <div className="flex flex-col md:flex-row items-center justify-center gap-2">
      <div className="md:hidden">
        <Button
          variant="ghost"
          className="border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white w-full"
          onClick={handleToggleVisibility}
        >
          {isVisible ? "Hide Report Options" : "Generate Your Report"}
        </Button>
      </div>

      <div
        className={`flex flex-col md:flex-row gap-2 justify-center items-center ${!isVisible && "hidden md:flex"}`}
      >
        {user?.role === Roles?.MASTER_ADMIN && (
          <SelectMerchantId
            onValueChange={(value: number) => {
              handleMerchant(value);
            }}
            defaultValue={merchantId}
          />
        )}
        <DatePicker setDate={setStartDate} date={startDate} maxDate={endDate} />
        TO
        <DatePicker
          setDate={setEndDate}
          date={endDate}
          maxDate={new Date()}
          minDate={startDate}
        />
        <Button
          variant="secondary"
          className="border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white md:w-auto w-full"
          onClick={handleGenerateReport}
        >
          Generate Report
        </Button>
      </div>
    </div>
  );
};

export default ExcelReport;
