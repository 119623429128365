"use client";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import { useDispatch, useSelector } from "react-redux";
import { resetForm, selectForm, setForm } from "store/formSlice";
import { RootState } from "store";
import { useEffect } from "react";
import { createPayment } from "api/paymentsController";
import { Select, SelectItem } from "components/ui/select";
import SelectRecipientAddressState from "components/selectRecipientAddressState/selectRecipientAddressState";
import { DatePicker } from "components/datePicker/datePicker";
import { dateToString } from "lib/utils";
import SelectPaymentAccountType from "components/selectFields/selectPaymentAccountType/selectPaymentAccountType";
import { useHandleAxiosError } from "hooks/useAxiosHandlerError";
import { NumericFormat } from "react-number-format";
import { formatStringDigitToNumber } from "lib/utils";

const formSchema = z.object({
  name: z.string().min(1).max(50),
  recipientEmail: z.string().email(),
  paymentReference: z.string().min(1),
  amount: z.string().min(1),
  abartn: z.string().min(1),
  accountNumber: z.string().min(1),
  accountType: z.string().min(1),
  addressCity: z.string().min(1),
  addressFirstLine: z.string().min(1),
  addressState: z.string().min(1),
  addressPostCode: z.string().min(1),
  dob: z.date(),
});

const CreatePaymentForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formState = useSelector((state: RootState) => selectForm(state));
  const { handleAxiosErrors } = useHandleAxiosError();
  const { user } = useSelector((state: any) => state.user);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      recipientEmail: "",
      paymentReference: "",
      amount: "",
      abartn: "",
      accountNumber: "",
      accountType: "",
      addressCity: "",
      addressFirstLine: "",
      addressState: "",
      addressPostCode: "",
    },
  });

  useEffect(() => {
    dispatch(resetForm());
  }, []);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      await createPayment({
        ...values,
        amount: +formatStringDigitToNumber(values?.amount),
        merchantId: user?.merchantId,
        dob: dateToString(values.dob),
      }).then((res) => {
        if (res) {
          navigate("/home/payments-merchant");
        }
      });
    } catch (error) {
      handleAxiosErrors({
        toastTitle: "Faild to create new payment!",
        error: error,
      });
    }
  }

  return (
    <div className="m-auto mt-5 pb-5">
      <Form {...form}>
        <div className="flex justify-center">
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <div className="grid lg:grid-cols-3 gap-x-8 gap-y-2">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Recipient Full Name</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        className="w-full"
                        placeholder="Recipient Full Name"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="recipientEmail"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Recipient Email</FormLabel>
                    <FormControl>
                      <Input
                        type="email"
                        className="w-full"
                        placeholder="Recipient Email"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="paymentReference"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Payment Reference</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        className="w-full"
                        placeholder="Payment Reference"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormItem>
                <FormLabel>Type</FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    className="w-full"
                    placeholder="Type"
                    readOnly
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
              <FormItem>
                <FormLabel>Amount Currency</FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    className="w-full"
                    placeholder="Amount Currency"
                    value={"SOURCE"}
                    readOnly
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
              <FormField
                control={form.control}
                name="amount"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="dark:text-white">Amount</FormLabel>
                    <FormControl>
                      <NumericFormat
                        {...field}
                        customInput={Input}
                        thousandSeparator
                        decimalScale={3}
                        allowNegative={false}
                        className="w-full"
                        placeholder="Amount"
                        onValueChange={(values) => {
                          field.onChange(+values.value);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormItem>
                <FormLabel>Source Currency</FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    className="w-full"
                    placeholder="Source Currency"
                    readOnly
                    value={"USD"}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
              <FormItem>
                <FormLabel>Target Currency</FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    className="w-full"
                    placeholder="Target Currency"
                    readOnly
                    value={"USD"}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
              <FormItem>
                <FormLabel>Receiver Type</FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    className="w-full"
                    placeholder="Receiver Type"
                    readOnly
                    value={"PERSON"}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
              <FormField
                control={form.control}
                name="abartn"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Routing Number</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        className="w-full"
                        placeholder="Routing Number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="accountNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Account Number</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        className="w-full"
                        placeholder="Account Number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="accountType"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Account Type</FormLabel>
                    <FormControl>
                      <SelectPaymentAccountType field={field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormItem>
                <FormLabel>Country</FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    className="w-full"
                    placeholder="Country"
                    value={"US"}
                    readOnly
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
              <FormField
                control={form.control}
                name="addressCity"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Recipient City</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        className="w-full"
                        placeholder="Recipient City"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="addressFirstLine"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Recipient Full Address</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        className="w-full"
                        placeholder="Recipient Full Address"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="addressState"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Recipient State</FormLabel>
                    <FormControl>
                      <SelectRecipientAddressState field={field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="addressPostCode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Recipient Postal Code</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        className="w-full"
                        placeholder="Recipient Postal Code"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="dob"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Date of Birth</FormLabel>
                    <FormControl>
                      <DatePicker
                        className="w-full flex hover:bg-black hover:text-unset"
                        setDate={field.onChange}
                        date={field.value}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex justify-center">
              <Button
                type="submit"
                variant={"secondary"}
                className="w-full lg:w-[350px] border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Form>
    </div>
  );
};

function getFormattedDate() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  const milliseconds = String(now.getMilliseconds()).padStart(3, "0");

  // JavaScript's Date object doesn't provide microseconds, so we'll add trailing zeros
  const microseconds = "000";

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${microseconds}`;
}

export default CreatePaymentForm;
