import { Button } from "components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "components/ui/dialog";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import axios from "axios";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "components/ui/input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { getExchangeCompanies } from "api/management";
import { createCryptoConversion } from "api/cryptoController";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { NumericFormat } from "react-number-format";
import { formatStringDigitToNumber } from "lib/utils";

interface CreateNewCryptoConversionDialogProps {
  transactionId: number;
  refreshSettlements: Dispatch<SetStateAction<boolean>>;
  refreshCoversion: Dispatch<SetStateAction<boolean>>;
}

const CreateNewCryptoConversionDialog: React.FC<
  CreateNewCryptoConversionDialogProps
> = ({ transactionId, refreshSettlements, refreshCoversion }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [exchangeProviders, setExchangeProviders] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const closeModal = () => {
    setIsOpen(false);
    reset();
    setErrorMessage("");
  };

  const formSchema = z.object({
    exchangeRate: z.string().min(1).max(100),
    fiatAmount: z.string().min(1).max(100),
    merchantAmountReceive: z.string().min(1).max(100),
    providerFee: z.string().min(1).max(100),
    providerId: z.string().min(1).max(100),
    adjustment: z.string().min(1).max(100),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      exchangeRate: "",
      fiatAmount: "",
      merchantAmountReceive: "",
      providerFee: "",
      providerId: "",
      adjustment: "",
    },
  });
  const { reset } = form;

  const getExchanceProviders = async () => {
    try {
      await getExchangeCompanies().then((res) => {
        setExchangeProviders(res);
      });
    } catch (Err) {
      console.log(Err);
    }
  };

  useEffect(() => {
    getExchanceProviders();
  }, []);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const cryptoTransaction = {
      cryptoTransactionId: transactionId,
      fiatAmount: +formatStringDigitToNumber(values?.fiatAmount),
      merchantAmountReceive: +formatStringDigitToNumber(
        values?.merchantAmountReceive
      ),
      providerFee: +formatStringDigitToNumber(values?.providerFee),
      exchangeRate: +formatStringDigitToNumber(values?.exchangeRate),
      providerId: +values?.providerId,
      adjustment: +formatStringDigitToNumber(values?.adjustment),
    };

    try {
      await createCryptoConversion(cryptoTransaction).then((res) => {
        if (res) {
          closeModal();
          refreshCoversion((prev) => !prev);
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorDetails = error.response?.data?.details;
        setErrorMessage(
          `ERROR: Failed to create conversion!  ${errorDetails || error.message}`
        );
      } else {
        setErrorMessage(
          "An unexpected error occurred. Please try again later."
        );
      }
    }
  }

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(isOpen) => {
        setIsOpen(isOpen);
        if (!isOpen) {
          closeModal();
        }
      }}
    >
      <DialogTrigger>
        <Button
          variant={"secondary"}
          className="w-[280px] lg:w-[350px] border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white"
          onClick={() => setIsOpen(true)}
        >
          Exchange details
        </Button>
      </DialogTrigger>
      <DialogContent className="dark max-h-[80vh] w-full overflow-auto dark:text-white">
        <div>
          <Form {...form}>
            <div className="flex justify-center">
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-8"
              >
                <div className="flex flex-col justify-between gap-6">
                  <FormField
                    control={form.control}
                    name="fiatAmount"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="flex items-center gap-4">
                          Amount in Fiat
                        </FormLabel>
                        <FormControl>
                          <NumericFormat
                            {...field}
                            customInput={Input}
                            thousandSeparator
                            decimalScale={3}
                            allowNegative={false}
                            className="w-[280px] lg:w-[350px] text-right text-xl"
                            placeholder="Amount in Fiat"
                            onValueChange={(values) => {
                              field.onChange(+values.value);
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="exchangeRate"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="flex items-center gap-4">
                          Exchange Rate
                        </FormLabel>
                        <FormControl>
                          <NumericFormat
                            {...field}
                            customInput={Input}
                            thousandSeparator
                            decimalScale={3}
                            allowNegative={false}
                            className="w-[280px] lg:w-[350px] text-right text-xl"
                            placeholder="Exchange Rate"
                            onValueChange={(values) => {
                              field.onChange(+values.value);
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="merchantAmountReceive"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="flex items-center gap-4">
                          Merchant Amount Receive
                        </FormLabel>
                        <FormControl>
                          <NumericFormat
                            {...field}
                            customInput={Input}
                            thousandSeparator
                            decimalScale={3}
                            allowNegative={false}
                            className="w-[280px] lg:w-[350px] text-right text-xl"
                            placeholder="Merchant Amount Receive"
                            onValueChange={(values) => {
                              field.onChange(+values.value);
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="providerId"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Exchange Provider</FormLabel>
                        <FormControl>
                          <div className="flex flex-col lg:flex-row items-center gap-4">
                            <Select
                              onValueChange={field.onChange}
                              value={field.value.toString()}
                            >
                              <SelectTrigger className="w-full bg-slate-500 text-white">
                                <SelectValue
                                  style={{ textDecorationColor: "black" }}
                                  placeholder={"Provider"}
                                />
                              </SelectTrigger>
                              <SelectContent>
                                {exchangeProviders?.map((provider: any) => (
                                  <SelectItem
                                    key={provider?.id}
                                    value={provider?.id.toString()}
                                  >
                                    {provider?.name}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </div>
                        </FormControl>

                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="providerFee"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="flex items-center gap-4">
                          Provider Fee (%)
                        </FormLabel>
                        <FormControl>
                          <NumericFormat
                            {...field}
                            customInput={Input}
                            thousandSeparator
                            decimalScale={3}
                            allowNegative={false}
                            className="w-[280px] lg:w-[350px] text-right text-xl"
                            placeholder="Provider Fee "
                            onValueChange={(values) => {
                              field.onChange(+values.value);
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="adjustment"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="flex items-center gap-4">
                          Adjustment
                        </FormLabel>
                        <FormControl>
                          <NumericFormat
                            {...field}
                            customInput={Input}
                            thousandSeparator
                            decimalScale={3}
                            allowNegative={false}
                            className="w-[280px] lg:w-[350px] text-right text-xl"
                            placeholder="Adjustment"
                            onValueChange={(values) => {
                              field.onChange(+values.value);
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="text-red-500">{errorMessage}</div>
                <div className={`flex pb-12`}>
                  <Button
                    variant={"secondary"}
                    className="w-[280px] lg:w-[350px] border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white"
                  >
                    Save conversion{" "}
                  </Button>
                </div>
              </form>
            </div>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreateNewCryptoConversionDialog;
