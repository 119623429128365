import { Button } from 'components/ui/button';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

const PrimaryButton = ({
  variant = 'primary',
  onClick = () => {},
  children,
  className = ''
}: any) => {

  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Button
      onClick={onClick}
      variant={variant}
      className={`bg-yellow-500 font-bold text-black hover:text-white ${className}`}
      size={isMobile ? 'sm' : 'default'} 
    >
      {children}
    </Button>
  );
}

export default PrimaryButton;
