// formSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface FormState {
  [key: string]: any
}

const initialState: FormState = {}

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setForm: (state, action: PayloadAction<FormState>) => {
      return { ...state, ...action.payload }
    },
    resetForm: () => initialState
  }
})

export const { setForm, resetForm } = formSlice.actions

export const selectForm = (state: any) => state.form

export default formSlice.reducer
