import { zodResolver } from "@hookform/resolvers/zod";
import { getExchangeCompanies, getProcessingCompanies } from "api/management";
import { updateMerchant } from "api/merchant";
import StepFourMerchantForm from "components/forms/merchantForms/stepFourMerchantForm";
import StepOneMerchantForm from "components/forms/merchantForms/stepOneMerchantForm";
import StepThreeMerchantForm from "components/forms/merchantForms/stepThreeMerchantForm";
import StepTwoMerchantForm from "components/forms/merchantForms/stepTwoMerchantForm";
import { Button } from "components/ui/button";
import { Checkbox } from "components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { toast, useToast } from "components/ui/use-toast";
import { useHandleAxiosError } from "hooks/useAxiosHandlerError";

const formSchema = z.object({
  name: z.string().min(1).max(50),
  address: z.string().min(1).max(50),
  registrationNumber: z.string().min(1).max(50),
  contactEmail: z.string().optional(),
  zelleMailbox: z.string().optional(),
  host: z.string(),
  port: z
    .string()
    .optional()
    .refine((val) => val === undefined || !isNaN(Number(val))),
  protocol: z.string(),
  username: z.string(),
  password: z.string(),
  processCompanyId: z
    .string()
    .optional()
    .refine((val) => val === undefined || !isNaN(Number(val))),
  exchangeId: z
    .string()
    .optional()
    .refine((val) => val === undefined || !isNaN(Number(val))),
  feeDescription: z.string().max(50),
  feePercentage: z
    .string()
    .optional()
    .refine((val) => val === undefined || !isNaN(Number(val))),
});

const EditMerchant = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {toast} = useToast();
  const { handleAxiosErrors } = useHandleAxiosError();
  const { merchant } = location?.state;
  const [exchangeOptions, setExchangeOptions] = useState([]);
  const [processingOptions, setProcessingOptions] = useState([]);
  const [zelleAccess, setZelleAccess] = useState(
    merchant?.zelleAccess || false
  );
  const [achAccess, setAchAccess] = useState(merchant?.achAccess || false);
  const [cryptoAccess, setCryptoAccess] = useState(
    merchant?.cryptoAccess || false
  );

  useEffect(() => {
    const getExchangeOptions = async () => {
      try {
        const response = await getExchangeCompanies();

        setExchangeOptions(response);
      } catch (error) {
        console.log(error);
      }
    };

    const getProcessingOptions = async () => {
      try {
        const response = await getProcessingCompanies();
        setProcessingOptions(response);
      } catch (error) {
        console.log(error);
      }
    };

    getProcessingOptions();
    getExchangeOptions();
  }, []);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: merchant?.name || "",
      address: merchant?.address || "",
      registrationNumber: merchant?.registrationNumber || "",
      contactEmail: merchant?.contactEmail || undefined,
      zelleMailbox: merchant?.zelleMailbox || undefined,
      host: merchant?.host || "",
      port: merchant?.port?.toString() || undefined,
      protocol: merchant?.protocol || "",
      username: merchant?.username || "",
      password: merchant?.password || "",
      processCompanyId: merchant?.processCompany || "",
      exchangeId: merchant?.exchange || "",
      feeDescription: merchant?.feeDescription || "",
      feePercentage: merchant?.feePercentage?.toString() || undefined,
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      const payload = {
        id: merchant?.id,
        ...values,
        zelleAccess,
        achAccess,
        cryptoAccess,
        processCompanyId: Number(values.processCompanyId),
        exchangeId: Number(values.exchangeId),
        feePercentage: Number(values.feePercentage),
        port: Number(values.port),
      };
      await updateMerchant(payload).then((res) => {
        if (res) {
          toast({
            title: "Merchant updated!",
          });
        }
      });
      // navigate("/home/merchants");
    } catch (error) {
      handleAxiosErrors({
        toastTitle: "Failed to update merchant!",
        error: error,
      });
    }
  };

  return (
    <div className="flex flex-grow dark:bg-black dark:text-white">
      <div className="flex flex-grow p-4 pb-0">
        <div className="flex-grow rounded-lg bg-[#212632]">
          {/* TOPBAR  */}
          <div className="flex items-center justify-between border-b border-b-slate-500 p-4">
            <div className="flex items-center gap-4">
              <h2 className="py-[6px] text-xl">Edit merchant</h2>
            </div>
          </div>

          {/* Content */}
          {merchant !== null && (
            <Form {...form}>
              <div className="flex justify-center">
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="mb-12 space-y-8"
                >
                  <div className="lg:grid lg:grid-cols-2 gap-x-4 gap-y-2 pt-7">
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Name</FormLabel>
                          <FormControl>
                            <Input
                              className="w-[280px] lg:w-[350px]"
                              placeholder="Name"
                              {...field}
                            />
                          </FormControl>

                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="address"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Address</FormLabel>
                          <FormControl>
                            <Input
                              className="w-[280px] lg:w-[350px]"
                              placeholder="Address"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="registrationNumber"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Registration Number</FormLabel>
                          <FormControl>
                            <Input
                              className="w-[280px] lg:w-[350px]"
                              placeholder="Registration Number"
                              {...field}
                            />
                          </FormControl>

                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="contactEmail"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Contact Email</FormLabel>
                          <FormControl>
                            <Input
                              type="email"
                              className="w-[280px] lg:w-[350px]"
                              placeholder="Contact Email"
                              {...field}
                            />
                          </FormControl>

                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    {/* zelle fields */}
                    <div className="col-span-2 my-4 flex items-center justify-start gap-4 place-self-center pl-12">
                      <Checkbox
                        defaultChecked={merchant?.zelleAccess}
                        onCheckedChange={(isChecked: boolean) => {
                          setZelleAccess(isChecked);
                        }}
                      />{" "}
                      <h2 className="text-xl">Zelle Access</h2>
                    </div>
                    {zelleAccess && <ZelleFields form={form} />}

                    {/* crypto fields */}
                    <div className="col-span-2 my-4 flex items-center justify-start gap-4 place-self-center pl-12">
                      <Checkbox
                        defaultChecked={merchant?.cryptoAccess}
                        onCheckedChange={(isChecked: boolean) => {
                          setCryptoAccess(isChecked);
                        }}
                      />{" "}
                      <h2 className="text-xl">Crypto Access</h2>
                    </div>
                    {cryptoAccess && (
                      <CryptoFields
                        processingOptions={processingOptions}
                        exchangeOptions={exchangeOptions}
                        form={form}
                      />
                    )}

                    {/* ach fields */}
                    <div className="col-span-2 my-4 flex items-center justify-start gap-4 place-self-center pl-12">
                      <Checkbox
                        defaultChecked={merchant?.achAccess}
                        onCheckedChange={(isChecked: boolean) => {
                          setAchAccess(isChecked);
                        }}
                      />{" "}
                      <h2 className="text-xl">Ach Access</h2>
                    </div>
                  </div>

                  <div className="flex justify-center">
                    <Button
                      type="submit"
                      variant={"secondary"}
                      className="w-[280px] lg:w-[350px] border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white"
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
};
const returnMerchantForm = (step: string) => {
  switch (step) {
    case "1":
      return <StepOneMerchantForm />;
    case "2":
      return <StepTwoMerchantForm />;
    case "3":
      return <StepThreeMerchantForm />;
    case "4":
      return <StepFourMerchantForm />;
    default:
      return <StepOneMerchantForm />;
  }
};

const ZelleFields = ({ form }: any) => {
  return (
    <>
      <FormField
        control={form.control}
        name="zelleMailbox"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Email</FormLabel>
            <FormControl>
              <Input
                type="email"
                placeholder="Email"
                className="w-[280px] lg:w-[350px]"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="host"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Host</FormLabel>
            <FormControl>
              <Input
                placeholder="Host"
                className="w-[280px] lg:w-[350px]"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="port"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Port</FormLabel>
            <FormControl>
              <Input
                type="number"
                placeholder="Port"
                className="w-[280px] lg:w-[350px]"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="protocol"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Protocol</FormLabel>
            <FormControl>
              <Input
                placeholder="Protocol"
                className="w-[280px] lg:w-[350px]"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="username"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Username</FormLabel>
            <FormControl>
              <Input
                placeholder="Username"
                className="w-[280px] lg:w-[350px]"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="password"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Password</FormLabel>
            <FormControl>
              <Input
                placeholder="**********"
                className="w-[280px] lg:w-[350px]"
                type="password"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
};
const CryptoFields = ({ form, processingOptions, exchangeOptions }: any) => {
  return (
    <>
      <FormField
        control={form.control}
        name={`feeDescription`}
        render={({ field }) => (
          <FormItem>
            <FormLabel>Description</FormLabel>
            <FormControl>
              <Input
                className="w-[280px] lg:w-[350px]"
                placeholder="Enter description"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name={`feePercentage`}
        render={({ field }) => (
          <FormItem>
            <FormLabel>Percentage</FormLabel>
            <FormControl>
              <Input
                type="number"
                className="w-[280px] lg:w-[350px]"
                placeholder="Enter percentage"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="companyProcess"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Company Process</FormLabel>
            <Select onValueChange={field.onChange} defaultValue={field.value}>
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Select a verified email to display" />
                </SelectTrigger>
              </FormControl>
              <SelectContent defaultValue={field?.value}>
                {processingOptions?.map((company: any) => (
                  <SelectItem key={company.id} value={company.id.toString()}>
                    {company.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="exchangeId"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Exchange</FormLabel>
            <Select onValueChange={field.onChange} defaultValue={field.value}>
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Select an exchange company" />
                </SelectTrigger>
              </FormControl>
              <SelectContent defaultValue={field?.value}>
                {exchangeOptions?.map((company: any) => (
                  <SelectItem key={company.id} value={company.id.toString()}>
                    {company.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
};
export default EditMerchant;
