import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isChatOpen: false,
};

const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    openChat(state) {
      state.isChatOpen = true;
    },
    closeChat(state) {
      state.isChatOpen = false;
    },
  },
});

export const { openChat, closeChat } = utilsSlice.actions;

export default utilsSlice.reducer;
