import MasterPaymentRequestForm from 'components/forms/paymentRequestForms/masterPaymentRequestForm'
import { Separator } from 'components/ui/separator'
import { beautifyNumber } from 'lib/utils'
import Layout from 'pages/(root)/home/layout'
import { useLocation } from 'react-router-dom'
const ViewPaymentRequest = () => {
  const location = useLocation()
  const { transaction } = location?.state
  return (
    
      <div className='flex flex-grow dark:bg-black dark:text-white'>
        <div className='flex flex-grow p-4 pb-0'>
          <div className='flex-grow rounded-lg bg-[#212632]'>
            {/* TOPBAR  */}
            <div className='flex items-center justify-between border-b border-b-slate-500 p-4'>
              <div className='flex items-center gap-4'>
                <h2 className='py-[6px] text-xl'>
                  View and edit your payment request
                </h2>
              </div>
            </div>
            {/* Content */}
            <div className='mx-4 mb-3 mt-12'>
              <h2 className='mb-4 text-2xl'>
                Payment request
                <span className='font-bold text-yellow-500'>
                  {' '}
                  #{transaction.id}
                </span>
              </h2>
              <Separator className='my-4 bg-slate-700' />
              <div className='flex max-w-3xl justify-between'>
                <div className='space-y-8'>
                  <h3 className=''>Bank: {transaction?.bank}</h3>
                  <h3 className=''>
                    Amount: {beautifyNumber(transaction?.amount)} USD
                  </h3>
                  <h3 className=''>IBAN: {transaction?.iban}</h3>
                  <h3 className=''>Swift: {transaction?.swift}</h3>
                </div>
                <div className='w-px bg-slate-700'></div>
                <div>
                  <MasterPaymentRequestForm transaction={transaction} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
  )
}

export default ViewPaymentRequest
