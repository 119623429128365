import PrimaryButton from "components/shared/buttons/primaryButton/primaryButton";
import { Separator } from "components/ui/separator";
import Layout from "pages/(root)/home/layout";
import { useLocation } from "react-router-dom";
const ViewPaymentRequestMerchant = () => {
  const location = useLocation();
  const transaction = location.state?.transaction;

  const baseUrl = process.env.REACT_APP_API_URL;

  return (
  
      <div className="flex flex-grow dark:bg-black dark:text-white">
        <div className="flex flex-grow p-4 pb-0">
          <div className="flex-grow rounded-lg bg-[#212632]">
            {/* TOPBAR  */}
            <div className="flex items-center justify-between border-b border-b-slate-500 p-4">
              <div className="flex items-center gap-4">
                <h2 className="py-[6px] text-xl">
                  View and edit your payment request
                </h2>
              </div>
            </div>
            {/* Content */}
            <div className="mx-4 mb-3 mt-12">
              <h2 className="mb-4 text-2xl">Payment request #1</h2>
              <Separator className="my-4 bg-slate-700" />
              <div className="flex justify-center gap-12">
                <div>
                  <p className="mb-2">Bank</p>
                  <div className="mb-4 flex h-10 w-[280px] lg:w-[350px] rounded-md border border-input bg-background px-3 py-2 text-right text-sm text-xl ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
                    {transaction?.bank}
                  </div>{" "}
                  <p>Amount</p>
                  <div className="mb-4 flex h-10 w-[280px] lg:w-[350px] rounded-md border border-input bg-background px-3 py-2 text-right text-sm text-xl ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
                    {transaction?.amount} {transaction?.currency}
                  </div>{" "}
                  <p className="mb-2">Received Ammount</p>
                  <div className="mb-4 flex h-10 w-[280px] lg:w-[350px] rounded-md border border-input bg-background px-3 py-2 text-right text-sm text-xl ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
                    {transaction?.merchantAmountReceive}
                  </div>{" "}
                </div>
                <div>
                  <p className="mb-2">IBAN</p>
                  <div className="mb-4 flex h-10 w-[280px] lg:w-[350px] rounded-md border border-input bg-background px-3 py-2 text-right text-sm text-xl ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
                    {transaction?.iban}
                  </div>{" "}
                  <p>Swift</p>
                  <div className="flex h-10 w-[280px] lg:w-[350px] rounded-md border border-input bg-background px-3 py-2 text-right text-sm text-xl ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
                    {transaction?.swift}
                  </div>{" "}
                </div>
              </div>
              <a
                target="_blank"
                href={`${baseUrl}/crypto/download/paymentRequestRecipe/${transaction?.id}`}
                rel="noreferrer"
                className=""
              >
                <div className="mb-12 flex justify-center">
                  <PrimaryButton>Download receipt</PrimaryButton>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
   
  );
};

export default ViewPaymentRequestMerchant;
