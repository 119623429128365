import ProcessTopUpForm from 'components/forms/processTopUpForm/processTopUpForm'
import TopUpDetails from 'components/topUpDetails/topUpDetails'
import Layout from 'pages/(root)/home/layout'
import React from 'react'
import { useLocation } from 'react-router-dom'

const TopUpView = () => {
  const location = useLocation()
  const { transaction } = location.state
  console.log(transaction)
  return (
 
      <div className='flex flex-grow dark:bg-black dark:text-white'>
        <div className='flex flex-grow p-4 pb-0'>
          <div className='flex-grow rounded-lg bg-[#212632]'>
            {/* TOPBAR  */}
            <div className='flex items-center justify-between border-b border-b-slate-500 p-4'>
              <div className='flex items-center gap-4'>
                <h2 className='py-[6px] text-xl'>See Your Top Up</h2>
                <span className='text-xl font-bold text-yellow-500'></span>
              </div>
            </div>
            {/* Content */}

            <TopUpDetails transaction={transaction} />
            {/* <MerchantCreateTopUpForm merchantId={user.merchantId} /> */}
          </div>
        </div>
      </div>

  )
}

export default TopUpView
