import { Separator } from 'components/ui/separator'
import { beautifyNumber } from 'lib/utils'
import React from 'react'

interface ReportProps {
  report: {
    id: number
    merchantEmail: string
    startDate: string
    endDate: string | null
    player: string
    status: string
    amount: string
    memo: string
    operation: string
  }
}

const ReportCard: React.FC<ReportProps> = ({ report }) => {
  const {
    id,
    merchantEmail,
    startDate,
    endDate,
    player,
    status,
    amount,
    memo,
    operation
  } = report

  return (
    <div className='rounded-lg'>
      <h3 className='mb-2 text-lg font-medium'>Transaction Details</h3>
      <ul className='list-none space-y-2'>
        <li className='flex gap-4'>
          <span className='font-bold text-yellow-500'>ID:</span>
          <span>{id}</span>
        </li>
        <li className='flex gap-4'>
          <span className='font-bold text-yellow-500'>Merchant Email:</span>
          <span>{merchantEmail}</span>
        </li>
        <li className='flex gap-4'>
          <span className='font-bold text-yellow-500'>Start Date:</span>
          <span>{startDate}</span>
        </li>
        <li className='flex gap-4'>
          <span className='font-bold text-yellow-500'>End Date:</span>
          <span>{endDate ? endDate : 'N/A'}</span>
        </li>
        <li className='flex gap-4'>
          <span className='font-bold text-yellow-500'>Player:</span>
          <span>{player}</span>
        </li>
        <li className='flex gap-4'>
          <span className='font-bold text-yellow-500'>Status:</span>
          <span>{status}</span>
        </li>
        <li className='flex gap-4'>
          <span className='font-bold text-yellow-500'>Amount:</span>
          <span>{beautifyNumber(amount)}</span>
        </li>
        <li className='flex gap-4'>
          <span className='font-bold text-yellow-500'>Memo:</span>
          <span>{memo}</span>
        </li>
        <li className='flex gap-4'>
          <span className='font-bold text-yellow-500'>Operation:</span>
          <span>{operation}</span>
        </li>
      </ul>
      <Separator className='border-2 bg-white' />
    </div>
  )
}

export default ReportCard
