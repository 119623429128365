import axios from 'axios';
import { useToast } from 'components/ui/use-toast';

export const useHandleAxiosError = () => {
  const { toast } = useToast();

  const handleAxiosErrors = async ({
    toastTitle,
    error,
  }: {
    toastTitle: string;
    error: any;
  }) => {
    console.log("Axiso Err")
    console.log(error)
    if (axios.isAxiosError(error)) {
       
      const errorDetails = error.response?.data?.details;
      toast({
        title: `ERROR: ${toastTitle}`,
        description: `REASON: ${errorDetails || error.message}`,
      });
    } else {
      toast({
        title: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  return { handleAxiosErrors };
};
