import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "components/ui/pagination";

const TablePagination = ({
  paginationData,
  handlePageChange,
}: {
  paginationData: any;
  handlePageChange: any;
}) => {

  if (!paginationData || !paginationData?.pageable) return null;

  const {
    totalPages,
    pageable: { pageNumber },
    last,
    first,
  } = paginationData;
  return (
    <Pagination>
      <PaginationContent>
        {pageNumber > 0 && (
          <PaginationItem>
            <PaginationPrevious
              className="cursor-pointer "
              onClick={() => handlePageChange(pageNumber - 1)}
            />
          </PaginationItem>
        )}

        {pageNumber > 0 && (
          <>
            <PaginationItem>
              <PaginationLink
                className="cursor-pointer  border"
                onClick={() => handlePageChange(0)}
              >
                1
              </PaginationLink>
            </PaginationItem>
          </>
        )}
        {pageNumber - 2 > 0 && (
          <PaginationItem>
            <PaginationEllipsis />
          </PaginationItem>
        )}
        {pageNumber - 1 > 0 && (
          <PaginationItem>
            <PaginationLink
              className="cursor-pointer  border "
              onClick={() => handlePageChange(pageNumber - 1)}
            >
              {pageNumber}
            </PaginationLink>
          </PaginationItem>
        )}

        <PaginationItem>
          <PaginationLink className="cursor-pointer border-yellow-500  border">
            {pageNumber + 1}
          </PaginationLink>
        </PaginationItem>

        {pageNumber < totalPages - 1 && (
          <PaginationItem>
            <PaginationLink
              className="cursor-pointer  border "
              onClick={() => handlePageChange(pageNumber + 1)}
            >
              {pageNumber + 2}
            </PaginationLink>
          </PaginationItem>
        )}

        {pageNumber + 2 < totalPages - 1 && (
          <PaginationItem>
            <PaginationEllipsis />
          </PaginationItem>
        )}
        {pageNumber < totalPages - 2 && (
          <PaginationItem>
            <PaginationLink
              className="cursor-pointer  border"
              onClick={() => handlePageChange(totalPages - 1)}
            >
              {totalPages}
            </PaginationLink>
          </PaginationItem>
        )}

        {pageNumber + 1 < totalPages && (
          <PaginationItem>
            <PaginationNext
              className="cursor-pointer"
              onClick={() => handlePageChange(pageNumber + 1)}
            />
          </PaginationItem>
        )}
      </PaginationContent>
    </Pagination>
  );
};

export default TablePagination;
