import { SetStateAction, Dispatch } from "react";
import CustomTable from "./CustomTable";
import ExternalTablePaginationProps from "./ExternalPagination";

interface ResponsiveTableWithPaginationProps {
  data: any;
  columns: any[];
  handlePageChange: Dispatch<SetStateAction<number>>;
}

const ResponsiveTableWithPagination = ({
  data,
  columns,
  handlePageChange,
}: ResponsiveTableWithPaginationProps) => {
  return (
    <div className="flex flex-col justify-between h-full max-w-full">
      <CustomTable columns={columns} data={data?.content || []} />
      <ExternalTablePaginationProps
        pageNumber={data?.pageable?.pageNumber}
        totalPages={data?.totalPages}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default ResponsiveTableWithPagination;
