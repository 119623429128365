import { useEffect, useState } from "react";
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"; // Use useSearchParams
import Layout from "../layout";
import CustomTable from "components/shared/customTableComponent/CustomTable";
import { TransactionType, getTransactions } from "api/transactions";
import TablePagination from "components/shared/tablePagination/tablePagination";
import SelectMerchantId from "components/selectMerchantId/selectMerchantId";
import NavigationButtons from "components/shared/buttons/buttonsList/navigationButtonsList";
import { NavigationButtonsData } from "interfaces/navigationButtonsListInterface";
import PrimaryButton from "components/shared/buttons/primaryButton/primaryButton";
import { Link } from "react-router-dom";
import { IconLoader } from "@tabler/icons-react";
import { getBalanceByMerchantAndType } from "api/balanceController";
import { useSelector } from "react-redux";
import { Roles } from "roles/roles";
import { serialize } from "v8";
import { formatNumberAsDigit } from "lib/utils";
import ResponsiveTableWithPagination from "components/shared/customTableComponent/ResponsiveTableWithPagination";

type BalanceType = "ZELLE" | "CRYPTO" | "ACH" | "WIRE_TRANSFER";

const columns: any[] = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Credit",
    accessor: "credit",
    Cell: ({ value }: { value: number }) => formatNumberAsDigit(value),
  },
  {
    Header: "Debit",
    accessor: "debit",
    Cell: ({ value }: { value: number }) => formatNumberAsDigit(value),
  },
  {
    Header: "Balance",
    accessor: "balance",
    Cell: ({ value }: { value: number }) => formatNumberAsDigit(value),
  },
];

const buttonsList: NavigationButtonsData = [
  { buttonName: "Zelle", buttonType: "secondary", buttonValue: "ZELLE" },
  { buttonName: "Ach", buttonType: "secondary", buttonValue: "ACH" },
  { buttonName: "Crypto", buttonType: "secondary", buttonValue: "CRYPTO" },
  {
    buttonName: "Withdrawals",
    buttonType: "secondary",
    buttonValue: "WIRE_TRANSFER",
  },
];

const Balance = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector((state: any) => state?.user);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [tableNavigationButtonValue, setTableNavigationButtonValue] =
    useState<BalanceType>((queryParams.get("type") as BalanceType) || "ZELLE");

  const [merchantId, setMerchantId] = useState<any>(
    user?.user?.role === Roles?.MASTER_ADMIN
      ? queryParams.get("merchantId")
      : user?.merchant?.id
  );

  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const getTransactionsQuery = async () => {
    try {
      setIsLoading(true);
      await getBalanceByMerchantAndType(
        merchantId,
        tableNavigationButtonValue,
        pageSize,
        currentPage
      ).then((res) => {
        if (res) {
          setData(res?.data);
        }
      });
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  useEffect(() => {
    if (tableNavigationButtonValue && merchantId) {
      getTransactionsQuery();
    }
  }, [pageSize, currentPage, tableNavigationButtonValue, merchantId]);

  const handleNavButtonClick = (value: BalanceType) => {
    setTableNavigationButtonValue(value);
    setCurrentPage(0);
    setSearchParams({ type: value, merchantId: merchantId?.toString() });
  };

  const BalanceSection = () => (
    <>
      {!merchantId ? (
        <>
          <div className="m-auto mt-10 flex items-center justify-between text-xl w-full">
            Please select a merchant
          </div>
        </>
      ) : (
        <div>
          {isLoading ? (
            <IconLoader size={72} className="m-auto mt-14 animate-spin" />
          ) : (
            <ResponsiveTableWithPagination
              columns={columns}
              data={data}
              handlePageChange={setCurrentPage}
            />
          )}
        </div>
      )}
    </>
  );

  return (
    <div className="flex flex-grow dark:bg-black dark:text-white">
      <div className="flex flex-grow p-4 pb-0">
        <div className="flex-grow flex flex-col rounded-lg bg-[#212632]">
          <div className="p-2">
            {user?.user?.role === Roles?.MASTER_ADMIN && (
              <div className="flex flex-items-center justify-end w-full">
                <PrimaryButton
                  onClick={() => navigate("/home/balance/create-transfer")}
                >
                  Add Transfer
                </PrimaryButton>
              </div>
            )}

            <div className="flex items-center justify-between">
              {user?.user?.role === Roles?.MASTER_ADMIN && (
                <SelectMerchantId
                  onValueChange={(value: number) => {
                    setMerchantId(value);
                    setSearchParams({
                      type: tableNavigationButtonValue,
                      merchantId: value?.toString(),
                    });
                  }}
                  defaultValue={merchantId}
                />
              )}

              <div className="flex justify-center md:justify-end p-2">
                <NavigationButtons
                  setValue={handleNavButtonClick}
                  buttonsList={buttonsList}
                  value={tableNavigationButtonValue}
                />
              </div>
            </div>

            <BalanceSection />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Balance;
