import { useState, useEffect, Dispatch, SetStateAction } from "react";
import {
  getSettlementsByCryptoTransactionId,
  getCryptoConversionDetailsById,
} from "api/cryptoController";
import CreateNewCryproConversionDialog from "./createNewConversionDialog";
import CreateNewSettlementDialog from "./createNewSettlementDialog";
import { formatNumberAsDigit } from "lib/utils";

const ProcessTopUpConversion = ({
  transactionId,
  setAllowToFinishTopUp,
}: {
  transactionId: number;
  setAllowToFinishTopUp: Dispatch<SetStateAction<boolean>>;
}) => {
  const [allowToCreateConversion, setAllowToCreateConversion] =
    useState<boolean>(false);
  const [conversionDetails, setConversionDetails] = useState<any>({});
  const [settlementsDetails, setSettlementsDetails] = useState<any>([]);
  const [refreshSettelments, setRefreshSettlements] = useState<boolean>(false);
  const [refreshConversionData, setRefreshConversionData] =
    useState<boolean>(false);

  const getConversiondetails = async () => {
    try {
      await getCryptoConversionDetailsById(transactionId).then((res) => {
        if (res?.data) {
          setConversionDetails(res);
          setAllowToCreateConversion(false);
        } else {
          setAllowToCreateConversion(true);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const totalSettlementsAmount = () => {
    let totalAmount = 0;

    for (let i = 0; i < settlementsDetails?.length; i++) {
      totalAmount += settlementsDetails[i]?.amount || 0;
    }
    const errorMargin = 0.05;
    const fiatAmount = conversionDetails?.data?.fiatAmount || 0;
    const lowerBound = fiatAmount * (1 - errorMargin);
    const upperBound = fiatAmount * (1 + errorMargin);

    if (totalAmount >= lowerBound && totalAmount <= upperBound) {
      setAllowToFinishTopUp(true);
    }

    return formatNumberAsDigit(totalAmount);
  };

  const getSettlementsDetails = async (conversionId: any) => {
    try {
      await getSettlementsByCryptoTransactionId(conversionId).then((res) => {
        if (res) {
          setSettlementsDetails(res?.data);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (conversionDetails?.data?.id) {
      getSettlementsDetails(conversionDetails?.data?.id);
    }
  }, [refreshSettelments, conversionDetails?.data?.id]);

  useEffect(() => {
    getConversiondetails();
  }, [refreshConversionData]);

  function ConversionDetails() {
    return (
      <div className="flex flex-col gap-2 w-full">
        {allowToCreateConversion ? (
          <div className="flex justify-end w-full">
            <CreateNewCryproConversionDialog
              transactionId={transactionId}
              refreshSettlements={setRefreshSettlements}
              refreshCoversion={setRefreshConversionData}
            />
          </div>
        ) : (
          <div className="w-full max-w-full shadow-none rounded-lg overflow-hidden my-4 mt-5">
            <div className="px-4 py-2 sm:px-6 sm:py-4">
              <div className="font-bold text-2xl sm:text-3xl text-white mb-2">
                Conversion Details
              </div>
              <div className="text-white text-base sm:text-lg">
                <p className="mb-2">
                  <span className="font-semibold">
                    Merchant amount receive:
                  </span>{" "}
                  {formatNumberAsDigit(conversionDetails?.data?.merchantAmountReceive)}
                </p>
                <p className="mb-2">
                  <span className="font-semibold">Fiat Amount:</span>{" "}
                  {formatNumberAsDigit(conversionDetails?.data?.fiatAmount)}
                </p>
                <p className="mb-2">
                  <span className="font-semibold">Exchange Rate:</span>{" "}
                  {formatNumberAsDigit(conversionDetails?.data?.exchangeRate)}
                </p>
                <p className="mb-2">
                  <span className="font-semibold">Provider Fee(%):</span>{" "}
                  {formatNumberAsDigit(conversionDetails?.data?.providerFee)}
                </p>
                <p className="mb-2">
                  <span className="font-semibold">Provider:</span>{" "}
                  {conversionDetails?.data?.providerName}
                </p>
                <p className="mb-2">
                  <span className="font-semibold">Adjustment:</span>{" "}
                  {formatNumberAsDigit(conversionDetails?.data?.adjustment)}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  function SettlementsDetails() {
    return (
      <div className="w-full mt-12 max-w-full">
        {conversionDetails?.data?.id && (
          <>
            <div className="flex flex-col sm:flex-row justify-between items-center w-full mb-4">
              <div className="text-lg">Total: {totalSettlementsAmount()}</div>
              <CreateNewSettlementDialog
                conversionId={conversionDetails?.data?.id}
                refreshSettlements={setRefreshSettlements}
              />
            </div>
            <div className="overflow-x-auto overflow-y-auto max-h-[300px] relative">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="sticky top-0 bg-gray-800">
                  <tr>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-200 uppercase tracking-wider">
                      Amount
                    </th>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-200 uppercase tracking-wider">
                      Date
                    </th>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-200 uppercase tracking-wider">
                      Description
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {settlementsDetails.map((item: any) => (
                    <tr key={item?.id}>
                      <td className="px-4 py-2 text-sm text-lightgray-500">
                        {formatNumberAsDigit(item?.amount)}
                      </td>
                      <td className="px-4 py-2 text-sm text-lightgray-500">
                        {item?.date}
                      </td>
                      <td className="px-4 py-2 text-sm text-lightgray-500">
                        {item?.description}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col justify-between h-full mx-auto mt-12 max-w-full px-4 sm:px-6">
      <ConversionDetails />
      <SettlementsDetails />
    </div>
  );
};

export default ProcessTopUpConversion;
