import { IconDownload } from "@tabler/icons-react";

import PrimaryButton from "components/shared/buttons/primaryButton/primaryButton";

const DownloadByPath = ({
  path,
  documentName,
  buttonName,
}: {
  path: string;
  documentName: string;
  buttonName: string;
}) => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = path;
    link.download = documentName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <PrimaryButton onClick={handleDownload}>
      <>
        <IconDownload /> {buttonName}
      </>
    </PrimaryButton>
  );
};

export default DownloadByPath;
