import { getProcessingCompanies } from "api/management";
import PrimaryButton from "components/shared/buttons/primaryButton/primaryButton";
import { Button } from "components/ui/button";
import Layout from "pages/(root)/home/layout";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { formatNumberAsDigit } from "lib/utils";

const ProcessingCompanies = () => {
  const [processingCompanies, setProcessingCompanies] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchProcessingCompanies = async () => {
      try {
        const response = await getProcessingCompanies();
        setProcessingCompanies(response);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProcessingCompanies();
  }, []);

  const ProcessingCompaniesCards = () => (
    <div className="max-h-screen overflow-y-scroll p-5 flex gap-4 flex-wrap ">
      {processingCompanies?.map((processingCompany: any) => (
        <div
          key={processingCompany?.id}
          className="flex-none w-72 min-w-[200px] flex-shrink-0 items-center justify-between rounded-lg border border-yellow-500 bg-gray-700 px-4 py-2 shadow-sm"
        >
          <div className="w-full">
            <h2 className="py-[6px] text-xl md:text-lg">
              Name: {processingCompany?.name}
            </h2>
            <h2 className="text-xl md:text-lg">
              Fee: {formatNumberAsDigit(processingCompany?.companyFee)}%
            </h2>
          </div>
        </div>
      ))}
    </div>
  );


  return (
    <div className="flex flex-grow dark:bg-black dark:text-white">
      <div className="flex flex-grow p-4 pb-0">
        <div className="mt-2 flex-grow rounded-lg bg-[#212632]">
          {/* TOPBAR  */}
          <div className="flex items-center justify-between border-b border-b-slate-500 p-4">
            <div className="flex items-center gap-4">
              <h2 className="py-[6px] text-xl">Processing Companies</h2>
            </div>

            <PrimaryButton
              onClick={() =>
                navigate("/home/processing-companies/create?step=1")
              }
            >
              Create
            </PrimaryButton>
          </div>
          <ProcessingCompaniesCards />
        </div>
      </div>
    </div>
  );
};

export default ProcessingCompanies;
